import * as React from "react";
import "./ContentOwnerItem.css";
import imageHolder from "assets/images/imageHolder.png";
import { useHistory } from "react-router-dom";
import { formatDateTime } from "views/site/helpers";

type Props = {
  c_owner: any;
  removeCart: (c_owner: ICart) => void;
  selected?: boolean;
  handleShow?: () => void;
};

export const ContentOwnerItem: React.FC<Props> = ({ c_owner }) => {
  const history = useHistory();


  return(<div>
    <a
      
      className="ring-0 ring-slate-900/5 shadow-sm relative bg-white block overflow-hidden rounded-lg border border-gray-100 p-4 sm:p-6 lg:p-8 "
    >
      <span className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600"></span>

      <div className="sm:flex sm:justify-between sm:gap-4">
        <div>
          <h3 
          
          onClick={() => {
            localStorage.setItem(
              "contentOwner",
              JSON.stringify({ id: c_owner?.id })
            );
            history.push(`/site/cnt_owner_page?id=${c_owner?.id}&zIx=890`);
          }}
          
          className="text-lg font-bold text-gray-900 sm:text-xl hover:underline ">
           {!c_owner.isCompany
              ? c_owner.contentOwnershipName
                ? c_owner.contentOwnershipName
                : c_owner.username
              : c_owner.companyName}
          </h3>

          <p className="mt-1 text-xs font-medium text-gray-600">
            {c_owner?.industry}
          </p>
        </div>

        <div className="hidden sm:block sm:shrink-0">
          <img
            alt=""
            src={c_owner?.coverImage ? c_owner?.coverImage : imageHolder}
            //"https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"
            className="size-16 rounded-lg object-cover shadow-sm"
          />
        </div>
      </div>

      <div className="mt-4">
        <p className="text-pretty text-sm text-gray-500">
          {c_owner?.region} {c_owner?.district}
          {""}
          {c_owner?.ward}
          {""}
          {c_owner?.mtaa}
        </p>
      </div>

      <dl className="mt-6 flex gap-4 sm:gap-6">
        <div className="flex flex-col-reverse">
          <dt className="text-sm font-medium text-gray-600">Joined</dt>
          <dd className="text-xs text-gray-500">
            {formatDateTime(c_owner?.createdAt)}
          </dd>
        </div>

        <div className="flex flex-col-reverse">
          <dt className="text-sm font-medium text-gray-600">Funded</dt>
          <dd className="text-xs text-gray-500">2 events</dd>
        </div>
      </dl>
    </a>
  </div>)
  return (
    <div className="bg-white  p-3 border border-gray-200 rounded-xl p-4">
      <div
        onClick={() => {
          localStorage.setItem(
            "contentOwner",
            JSON.stringify({ id: c_owner?.id })
          );
          history.push(`/site/cnt_owner_page?id=${c_owner?.id}&zIx=890`);
        }}
        className="flex items-start gap-4"
      >
        <img
          src={c_owner?.coverImage ? c_owner?.coverImage : imageHolder}
          alt=""
          className="size-20 rounded-lg object-cover"
        />

        <div>
          <h3 className="text-lg/tight font-medium text-gray-900">
            {!c_owner.isCompany
              ? c_owner.contentOwnershipName
                ? c_owner.contentOwnershipName
                : c_owner.username
              : c_owner.companyName}
          </h3>

          <p className="mt-0.5 text-gray-700">
            since {formatDateTime(c_owner?.createdAt)}
          </p>
        </div>
      </div>
    </div>
  );
};
