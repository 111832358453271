// Chakra imports
import { Box, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";

export default function Information(props: {
  title: string;
  value: number | string;
  [x: string]: any;
}) {
  const { title, value, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const bg = useColorModeValue("white", "navy.700");
  return (
    <div className="ring-0 ring-slate-900/5 shadow-sm  flex flex-row font-bold px-1 rounded-lg bg-fuchsia-200 text-fuchsia-900 p-2  ">
      <div className=" p-1 h-full  rounded-2xl bg-fuchsia-950 " />
      <div className="ml-1">
        <p className="text-sm text-fuchsia-950 ">{title}</p>
        <p className="text-xs text-fuchsia-800 ">{value}</p>
      </div>
    </div>
  );
}
