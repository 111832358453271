import React, { useEffect, useState } from "react";
import "./assets/css/App.css";
import "./index.css";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import {  useDispatch, useSelector } from "react-redux";

import "./assets/fonts/Lexend/Lexend-VariableFont_wght.ttf";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { createHttpLink } from "@apollo/client";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { jwtDecode } from "jwt-decode";
import { getAccessToken, logOUTServ, setAccessToken } from "accessToken";
import AppRoot from "AppRouting";
import { Dispatch } from "redux";
import { loginUser, logoutUser } from "redux.store/user/actionCreators";
import { useHistory } from "react-router-dom";

// const apiLink = "http://127.0.0.1:3020"; // Ensure correct URL
const apiLink = "https://octopus-app-zb8ri.ondigitalocean.app"

export const getFromStorage = (key: string) => {
  try {
    const data = localStorage.getItem(key);
    console.log(`[Storage] Fetching ${key}:`, data);
    return data;
  } catch (error) {
    console.error(`[Storage] Error fetching ${key}:`, error);
    return null;
  }
};




export default function App() {
  const dispatch: Dispatch<any> = useDispatch();
  const history = useHistory();

   const [logU, setLogU] = useState(false)
   const [logN, setLogN] = useState(false)
   const [accssTkn, setAccssTkn] = useState("")


  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const logoutService = React.useCallback(
    () => dispatch(logoutUser()),
    [dispatch]
  );
  
  const loginService = React.useCallback(
    (user: IUser) => dispatch(loginUser(user)),
    [dispatch]
  );

  const logout = () => {
    logoutService();
    localStorage.setItem("accessToken", "");

    // history.push("/landing/landing");

  };


 
  const httpLink = createHttpLink({
    uri: `${apiLink}/graphql`,
  });
  
  const handleTokenRefresh = async () => {
    const currentRefreshToken = getFromStorage("accessToken");
    // console.log("[Token Refresh] Current refresh token:", currentRefreshToken);
  
    // if (!currentRefreshToken || currentRefreshToken === undefined || currentRefreshToken.trim() === "") {
    //   console.log("[Token Refresh] No refresh token found in storage");
    //   setLogU(true)
    //   return null;
    // }
  
    try {
      const response = await fetch(`${apiLink}/rfr/mobile`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentRefreshToken}`,
        },
        body: JSON.stringify({ refreshToken: currentRefreshToken }),
      });


      const data = await response.json();

  
      if (!data?.ok) {
        console.error("[Token Refresh] Failed to refresh token, status:", response.status);
        setLogU(true)

        
        return null


      }else{
  
      console.log(data);
      console.log("[Token Refresh] New access token:", data.tknw.token.accessToken);
      console.log("[Token Refresh] New refresh token:", data.tknw.token.refreshToken);
      localStorage.setItem("accessToken", data.tknw.token.accessToken);
      // localStorage.setItem("refreshToken", data.tknw.token.refreshToken);
    //  setAccssTkn(data.tknw.token.accessToken)

    if(!data.ok || data.tknw.token.accessToken.trim()===""){
      // setLogU(false)

    }


      // 

      return data.tknw.token.accessToken;
    }
    } catch (error) {
      console.error("[Token Refresh] Error refreshing token:", error);
      setLogU(true)

      return


    }
  };
  
  const tokenRefreshLink = new TokenRefreshLink({
    accessTokenField: "accessToken",
    isTokenValidOrUndefined: async () => {
      const accessToken = getAccessToken();      
      console.log("[Token Validation] Checking validity for access token:", accessToken);
  
      if (!accessToken) {
        console.log("[Token Validation] No access token found, triggering refresh.");
        setLogU(true)
        return false
      }
  
      try {
        const { exp }: any = jwtDecode(accessToken);
        const isValid = Date.now() < exp * 1000;
        // console.log(Date.now() > exp * 1000);
        console.log(`[Token Validation] Token expiration: ${exp}, isValid: ${isValid}`);
        return false;
        return isValid;
      } catch (error) {
        console.error("[Token Validation] Error decoding token:", error);
        setLogU(true)
        return false

      }
    },
    fetchAccessToken: handleTokenRefresh,

    handleFetch: (accessToken: string,operation) => {
      console.log("[Token Fetch] New access token fetched:", accessToken);
      const { dispatch } = operation.getContext();
      // setLogU(false)
      // setLogN(false)
      // setAccssTkn(accessToken)




     

  
    },
    handleError: (err: Error) => {
      console.error("[Token Error] Error during token fetch:", err);
      // setLogU(true)

      },
  });
  
  const client = new ApolloClient({
    link: tokenRefreshLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
  
  

console.log(user);


useEffect(()=>{
  if(logU){
    logout()
  }
},[logU])








  return(
    <ApolloProvider client={client}>
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <AppRoot />
      </React.StrictMode>
    </ChakraProvider>
</ApolloProvider>
  )
}
