import { Box, Grid, SimpleGrid } from "@chakra-ui/react";
import { useMyPaidCartQuery } from "generated/graphql";
import { useState } from "react";
import { useSelector } from "react-redux";
import ComplexTable from "./components/ComplexTable";
import { Banner } from "components_app/tangazo/Banner";

export default function Datatable() {
  // Chakra Color Mode

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Grid
        mb={10}
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          //  "2xl": "1.34fr 1.62fr 1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
      >
        <Banner
          title={"Kamata Fursa Twenzetu"}
          description={"Hakimiliki yako hailiki"}
        />
        <Banner
          title={"Ruge Mutahaba Foundation"}
          description={"Valuable sponsors"}
        />
      </Grid>
      <ComplexTable />
    </Box>
  );
}
