// Define push operation function
export const pushOperation = (array: any, element: string) => {
  try {
    let newArray = JSON.parse(array);
    newArray.push(element);
    return JSON.stringify(newArray);
  } catch (e) {
    let newArray = [];
    newArray.push(element);
    return JSON.stringify(newArray);
  }
};

// Define remove operation function
export const removeOperation = (array: any, item: string) => {
  try {
    let newArray = JSON.parse(array);
    const index = newArray.indexOf(item);
    if (index !== -1) {
      newArray.splice(index, 1);
    }
    return JSON.stringify(newArray);
  } catch (e) {
    return   JSON.stringify([]);
  }
};

// Define function to check if item is in the list
export const isInList = (array: any, item: string) => {
  try {
    let parsedArray = JSON.parse(array);
    return parsedArray.includes(item);
  } catch (e) {
    return false;
  }
};

export function findCurrentAccountStateByKeyOLD(array: any, keyToFind: string) {
  return array.reduce((latestItem: any, currentItem: any) => {
    if (currentItem.key === keyToFind) {
      let currentItemDate = new Date(currentItem.date);
      let latestItemDate = latestItem ? new Date(latestItem.date) : new Date(0); // Initialize to a very old date if latestItem is null

      return currentItemDate > latestItemDate ? currentItem : latestItem;
    }
    return latestItem;
  }, null);
}

export function findCurrentAccountStateByKey(array: any[], keyToFind: string) {
  let latestItem = null;

  for (let currentItem of array) {
    if (currentItem.key === keyToFind) {
      if (
        !latestItem ||
        new Date(currentItem.date) > new Date(latestItem.date)
      ) {
        latestItem = currentItem;
      }
    }
  }

  return latestItem;
}

export function findCurrentBandaStatus(array: any[], keyToFind: string) {
  let bandaState = null;

  for (let currentItem of array) {
    if (currentItem.key === keyToFind) {
      if (
        currentItem.status === "PENDING" ||
        currentItem.status === "SUSPENDED" ||
        currentItem.status === "REJECTED"
      ) {
        if (
          !bandaState ||
          new Date(currentItem.date) > new Date(bandaState.date)
        ) {
          bandaState = currentItem;
        }
      }
    }
  }

  return bandaState;
}



export function formatDateTime(dateString:any) {
  const date = new Date(dateString);
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  });
}

export function formatDateTimeShort(dateString: string): string {
  // Parse the input date string into a Date object
  const date = new Date(dateString);

  // Format the date in a shorter format, such as "MM-DD"
  const formattedDate = date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit'
  });

  // Format the time without seconds, using 24-hour time format
  const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
  });

  // Combine the formatted date and time
  return `${formattedDate} ${formattedTime}`;
}


export function timeAgo(dateIso: string): string {
  const currentTime = new Date();
  const pastTime = new Date(dateIso);

  const differenceInSeconds = Math.floor((currentTime.getTime() - pastTime.getTime()) / 1000);

  // Time units in seconds
  const units = [
    { label: "second", value: 60 },
    { label: "minute", value: 60 },
    { label: "hour", value: 24 },
    { label: "day", value: 30 },
    { label: "month", value: 12 },
    { label: "year", value: Infinity },
  ];

  let unit = units[0];
  let time = differenceInSeconds;

  // Determine the largest time unit
  for (let i = 0; i < units.length; i++) {
    if (time < units[i].value) {
      unit = units[i];
      break;
    }
    time = Math.floor(time / units[i].value);
  }

  const amount = time;
  const label = amount === 1 ? unit.label : `${unit.label}s`; // Pluralize if needed

  // Format the time difference
  return `${amount} ${label} ago`;
}

export function calculateHourDifference(dateString1: string, dateString2: string): number {
  // Parse the date strings into Date objects
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);

  // Check if the dates are valid
  if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {
      throw new Error("Invalid date string provided.");
  }

  // Calculate the difference in time (in milliseconds)
  const timeDifference = date1.getTime() - date2.getTime(); // Subtract date2 from date1

  // Convert the difference from milliseconds to hours
  const hourDifference = timeDifference / (1000 * 60 * 60); // 1 hour = 60 minutes * 60 seconds * 1000 milliseconds

  return hourDifference;
}

export function sumAndWeightPrices(items:any,
  // { title: string;  start: string; end: string ,id:string,}[],
  cost:number
): number {
  const weightingFactor = cost; // The weighting factor
  // console.log(JSON.parse(items));
  console.log(JSON.parse(items));
  console.log(JSON.parse(items).length);
  // console.log(JSON.parse(items));



// return 0


  // Calculate the weighted sum of prices

  const weightedSum = JSON.parse(items)?.reduce((total:number, item:any) => {
      // Access the price property from the object
      const price = calculateHourDifference(item.end,item.start) 
      // Add the weighted price to the total
      return total + (price * weightingFactor);
  }, 0);

  return weightedSum;
}

