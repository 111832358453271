import React from "react";
// Chakra imports
import { Box } from "@chakra-ui/react";
import Home from "./Home";
import NavBarHeaderSite from "components_app/NavBarHeaderWebsite/NavBarHeaderSite";
import Footer from "components_app/Footer/Footer";
import Landing from "./LandingSite";
// Custom components
// Assets

function LandingView() {
  // Chakra color mode
  return (
      <Home />
  );
}

export default LandingView;
