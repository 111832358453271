import { Box, Grid, SimpleGrid } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/react";
// Custom components
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import "./content.css";
import { useHistory, useLocation } from "react-router-dom";
// import { navigateThePage } from "redux.store/navigation/actionCreators";
import imageHolder from "assets/images/imageHolder.png";

import {
  useGetContentQuery,
  useMyContentEventsListQuery,
} from "generated/graphql";
import Hosting from "./components/Hosting";
import { formatDateTime } from "../helpers";
import Information from "./components/Information";
import Banner from "../marketplace/components/Banner";
import BannerSide from "../marketplace/components/BannerSide";
// Custom components

export default function Content() {
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const history = useHistory();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // console.log(location.state);
  const contentIDd = queryParams.get("id");
  // Convert the string back to an object

  const { carts }: readonly ICart[] | any = useSelector(
    (state: CartsState) => state.carts,
    shallowEqual
  );
  //#Check remove any
  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const contentData = useGetContentQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      getContentInput: {
        contentId: contentIDd,
      },
    },
  });

  const activeOn = useMyContentEventsListQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      getMyBandaEventsInput: {
        contentId: contentIDd,
        page: 1,
        pageSize: 10,
        // filterQuery,
      },
    },
  });

  console.log("activeOn");
  console.log(activeOn);
  console.log("activeOn");
  //console.log(contentData);

  const idExists = carts.some(
    (item: any) => item.id === contentData?.data?.getContent?.id
  );
  const foundCart = carts.find(
    (item: any) => item.id === contentData?.data?.getContent?.id
  );
  useEffect(() => {
    window.scrollTo(0, 0);  
  }, [contentData]);




  
  return (
    <div className="w-full flex  justify-center flex-col items-center ">
      <div className="w-full flex max-w-screen-xl flex-col items-center ">
        <div className="w-full  max-w-screen-xl">
          {/*  */}

          {!contentData.loading ? (
            <>
              <article className="flex rounded-xl border-1 border-fichsia-200 mt-12 sm:mt-12 bg-white transition hover:shadow-xl">
                <div className="rotate-180 p-2 [writing-mode:_vertical-lr]">
                  <time
                    // datetime="2022-10-10"
                    className="flex items-center justify-between gap-4 text-sm font-bold uppercase text-green-600"
                  >
                    <span>Verified</span>
                    <span className="w-px flex-1 bg-gray-900/10"></span>
                    <span>{contentData?.data?.getContent?.verifiedBy}</span>
                  </time>
                </div>

                <div className="hidden sm:block sm:basis-[300px]  ">
                  <img
                    alt=""
                    src={
                      contentData?.data?.getContent?.coverImage
                        ? contentData?.data?.getContent?.coverImage
                        : imageHolder
                    }
                    // src="https://images.unsplash.com/photo-1609557927087-f9cf8e88de18?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
                    className="aspect-square h-full w-full object-cover"
                  />
                </div>

                <div className="flex bg-gray-50 sm:max-h-[500px] flex-1 flex-col justify-between">
                  <div className="border-s border-gray-900/10 p-4 sm:border-l-transparent sm:p-6">
                    <h3 className="text-3xl font-extrabold uppercase text-gray-900">
                      {contentData?.data?.getContent?.title}
                    </h3>

                    <p
                      onClick={() => {
                        history.push(
                          `/site/cnt_owner_page?id=${contentData?.data?.getContent?.author?.id}&zIx=890`
                        );
                      }}
                      className="w-min  whitespace-nowrap text-xl mt-3 font-bold   text-gray-800  "
                    >
                      content by{" "}
                      {contentData?.data?.getContent?.author?.username}
                    </p>
                    <div className="flex items-center gap-2 mt-2 mt-0">
                      <div className="flex mt-2 items-center gap-1">
                        <svg
                          className="w-5 h-5 text-green-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20.285 6.709a1 1 0 0 1 0 1.414l-11 11a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 1.414-1.414L9 16.586l10.293-10.293a1 1 0 0 1 1.414 0z" />
                        </svg>
                      </div>

                      <p className="text-sm font-medium leading-none text-gray-800  hover:text-md ">
                        34 Reservations
                      </p>
                      <a
                        href={contentData?.data?.getContent?.link}
                        target="_blank"
                        className="text-sm font-medium leading-none text-gray-800 underline hover:no-underline hover:text-md "
                      >
                        Link
                      </a>
                    </div>

                    <hr className="my-6 md:my-8 border-gray-200 " />

                    <p className="mt-2 text-xl line-clamp-9 text-sm/relaxed text-gray-700">
                      {contentData?.data?.getContent?.description}
                    </p>

                    <SimpleGrid
                      style={{
                        marginTop: 20,
                        marginBottom: 30,
                      }}
                      columns={3}
                      gap="20px"
                    >
                      {contentData?.data?.getContent?.verifiedBy !== "None" && (
                        <Information
                          boxShadow={cardShadow}
                          // title="Do you want to BusketFund  or cancel busketfund  bellow input to add it"
                          title="Verifier"
                          value={contentData?.data?.getContent?.verifiedBy}
                        />
                      )}
                      <Information
                        boxShadow={cardShadow}
                        // title="Do you want to BusketFund  or cancel busketfund  bellow input to add it"
                        title="Uploaded"
                        value={formatDateTime(
                          contentData?.data?.getContent?.createdAt
                        )}
                      />
                      <Information
                        boxShadow={cardShadow}
                        title="Language"
                        value={contentData?.data?.getContent?.languages}
                      />
                    </SimpleGrid>
                  </div>


                  <a
                      onClick={() => {
                        // addToCart();
                        if (!user.loginStatus) {
                          history.push({
                            pathname: "/auth/sign-in",
                          });

                          return;
                        }
                        if (idExists) {
                          localStorage.setItem(
                            "reservationDataContent",
                            JSON.stringify({
                              reservationData: foundCart,
                              update: true,
                            })
                          );

                          localStorage.setItem(
                            "contentItem",
                            JSON.stringify({
                              content: contentData?.data?.getContent,
                              update: true,
                            })
                          );
                        } else {
                          localStorage.setItem(
                            "contentItem",
                            JSON.stringify({
                              content: contentData?.data?.getContent,
                              update: false,
                            })
                          );
                        }

                        history.push(
                          `/site/reserve_order?id=${contentData?.data?.getContent?.id}&update=${idExists}`
                        );
                      }}
                      className="block my-2 self-center w-1/3 bg-fuchsia-900 px-5 py-5 rounded-xl text-center text-xl font-bold uppercase text-gray-100 transition hover:bg-fuchsia-950"
                   
                   >
                      Reserve{" "}
                    </a>

                  {false &&<div className="sm:flex sm:items-center sm:justify-end">
                    <a
                      onClick={() => {
                        // addToCart();
                        if (!user.loginStatus) {
                          history.push({
                            pathname: "/auth/sign-in",
                          });

                          return;
                        }
                        if (idExists) {
                          localStorage.setItem(
                            "reservationDataContent",
                            JSON.stringify({
                              reservationData: foundCart,
                              update: true,
                            })
                          );

                          localStorage.setItem(
                            "contentItem",
                            JSON.stringify({
                              content: contentData?.data?.getContent,
                              update: true,
                            })
                          );
                        } else {
                          localStorage.setItem(
                            "contentItem",
                            JSON.stringify({
                              content: contentData?.data?.getContent,
                              update: false,
                            })
                          );
                        }

                        history.push(
                          `/site/reserve_order?id=${contentData?.data?.getContent?.id}&update=${idExists}`
                        );
                      }}
                      className="block bg-yellow-300 px-5 py-3 text-center text-xs font-bold uppercase text-gray-900 transition hover:bg-yellow-400"
                    >
                      Reserve{" "}
                    </a>
                  </div>}
                </div>
              </article>

              {/* reservations */}
              
              {activeOn?.data?.myContentEventsList?.length > 0 && (
                <section
                  className="
 bg-white md:py-0  antialiased mt-5"
                  style={{
                    //backgroundColor:"#10002b",
                    borderRadius: 10,
                    height: "fit-content",
                  }}
                >
                  <div className="max-w-screen-xl ">
                    <div className="grid grid-cols-1 lg:grid-cols-1 gap-8 xl:gap-16">
                      <div
                        className="shrink-0 mb-5 rounded-xl "
                       
                      >
                        <ul role="list" className="divide-y divide-gray-200">
                          {activeOn?.data?.myContentEventsList?.map(
                            (item: any) => {
                              return (
                                <Hosting
                                  boxShadow={cardShadow}
                                  mb="20px"
                                  image={imageHolder}
                                  ranking="1"
                                  item={item.banda}
                                  dateStart={item?.startDateTime}
                                  dateEnd={item?.endDateTime}
                                  id={item.id}
                                  sponsorImage={item?.sponsor?.coverImage}
                                  link="#"
                                  title={item?.banda?.bandaCode}
                                  sponsorId={item?.sponsor?.id}
                                  category={
                                    item?.sponsor?.anonymous
                                      ? "anonymous"
                                      : item?.sponsor?.isCompany
                                      ? item?.sponsor?.companyName.trim() !== ""
                                        ? item?.sponsor?.companyName
                                        : "anonymous"
                                      : item?.sponsor?.sponsorName.trim() !== ""
                                      ? item?.sponsor?.sponsorName
                                      : "anonymous"
                                  }
                                  sponsorName={
                                    item?.sponsor?.anonymous === true
                                      ? "anonymous"
                                      : item?.sponsor?.isCompany
                                      ? item?.sponsor?.companyName.trim() !== ""
                                        ? item?.sponsor?.companyName
                                        : "anonymous"
                                      : item?.sponsor?.sponsorName.trim() !== ""
                                      ? item?.sponsor?.sponsorName
                                      : "anonymous"
                                  }
                                  home={"Barcelona"}
                                  away={"Napoli"}
                                />
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              {false && activeOn?.data?.myContentEventsList?.length > 0 && (
                <section
                  className="
 bg-white md:py-0  antialiased mt-5"
                  style={{
                    //backgroundColor:"#10002b",
                    borderRadius: 10,
                    height: "fit-content",
                  }}
                >
                  <div className="max-w-screen-xl mx-auto 2xl:px-0">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 xl:gap-16">
                      <div
                        className="shrink-0 "
                        style={{
                          backgroundColor: "#fff",
                          borderRadius: 10,
                          marginBottom: 20,
                        }}
                      >
                        {/* <Text
            // color={"white"}
            fontWeight="bold"
            fontSize="24px"
            mt="10px"
            mb="4px"
          >Reservations</Text> */}
                        <ul role="list" className="divide-y divide-gray-200">
                          {activeOn?.data?.myContentEventsList?.map(
                            (item: any) => {
                              return (
                                <Hosting
                                  boxShadow={cardShadow}
                                  mb="20px"
                                  image={imageHolder}
                                  ranking="1"
                                  item={item.banda}
                                  dateStart={item?.startDateTime}
                                  dateEnd={item?.endDateTime}
                                  id={item.id}
                                  sponsorImage={item?.sponsor?.coverImage}
                                  link="#"
                                  title={item?.banda?.bandaCode}
                                  sponsorId={item?.sponsor?.id}
                                  category={
                                    item?.sponsor?.anonymous
                                      ? "anonymous"
                                      : item?.sponsor?.isCompany
                                      ? item?.sponsor?.companyName.trim() !== ""
                                        ? item?.sponsor?.companyName
                                        : "anonymous"
                                      : item?.sponsor?.sponsorName.trim() !== ""
                                      ? item?.sponsor?.sponsorName
                                      : "anonymous"
                                  }
                                  sponsorName={
                                    item?.sponsor?.anonymous === true
                                      ? "anonymous"
                                      : item?.sponsor?.isCompany
                                      ? item?.sponsor?.companyName.trim() !== ""
                                        ? item?.sponsor?.companyName
                                        : "anonymous"
                                      : item?.sponsor?.sponsorName.trim() !== ""
                                      ? item?.sponsor?.sponsorName
                                      : "anonymous"
                                  }
                                  home={"Barcelona"}
                                  away={"Napoli"}
                                />
                              );
                            }
                          )}
                        </ul>
                      </div>

                      <div className="mt-6 sm:mt-8 lg:mt-0">
                        <Grid
                          mb={3}
                          templateColumns={{
                            base: "1fr",
                            lg: "repeat(2, 1fr)",
                            //  "2xl": "1.34fr 1.62fr 1fr",
                          }}
                          gap={{ base: "10px", xl: "10px" }}
                        >
                          <Banner />
                          <BannerSide />
                          <Banner />
                          <BannerSide />
                        </Grid>
                        <Banner />
                        <div className="mt-2" />
                        <BannerSide />
                        <div className="mt-2" />
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </>
          ) : (
            <div className="flex justify-center mt-20 h-screen">
              <div
                className="animate-spin inline-block w-16 h-16 border-8 border-current border-t-transparent text-violet-700 rounded-full"
                role="status"
                aria-label="loading"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
