import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Select,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import "views/site/content_reserve/styles/styles.scss";
import { Input } from "@chakra-ui/react";
import Card from "components/card/Card";
import { Button, Link } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import ReservationArea from "components_app/ReservationArea/ReservationArea";
import { useHistory, useLocation } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  addCart,
  removeCart,
  updateCart,
} from "redux.store/cart/actionCreators";
import { SelectedBandaUmiza } from "components_app/SelectedBandaUmiza/SelectedBandaUmiza";
import regionData from "utils/geo_data.json";

import {
  Banda,
  Event,
  useFilterAreasQuery,
  useGetBandaByCodeQuery,
  useGetContentQuery,
} from "generated/graphql";

let areaQuestionsInit = [
  {
    label: "Municipals",
    choices: [
      { name: "Temeke", checked: false },
      { name: "Ubungo", checked: false },
      { name: "Ilala", checked: false },
      { name: "Kinondoni", checked: false },
      { name: "Kigamboni", checked: false },
    ],
  },
  {
    label: "Size",
    choices: [
      { name: "1 - 50 ", checked: false },
      { name: "51 - 100", checked: false },
      { name: "101 - 200", checked: false },
      { name: "201 - 1000", checked: false },
      { name: "1000+", checked: false },
    ],
  },
  // Add more questions as needed
];
const areaFiltersLabels = [
  { mode: "REGION", label: "REGION" },
  { mode: "DISTRICT", label: "DISTRICT" },
  { mode: "WARD", label: "WARD" },
  { mode: "MTAA", label: "MTAA" },
];

export interface BandaFormData {
  region?: string;
  district?: string;
  ward?: string;
  searchCode?: string;

  mtaaVillage: string;
  districtPostcode: string;
  wardPostcode: string;
}

export default function ContentReserve() {
  const textColorPrimary = "gray";
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const contentIDd = queryParams.get("id");

  const dispatch: Dispatch<any> = useDispatch();

  const saveToTheCart = React.useCallback(
    (cart: ICart) => dispatch(addCart(cart)),
    [dispatch]
  );

  const updateCartState = React.useCallback(
    (cart: ICart) => dispatch(updateCart(cart)),
    [dispatch]
  );

  const { carts }: readonly ICart[] | any = useSelector(
    (state: CartsState) => state.carts,
    shallowEqual
  );
  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [step, setStep] = useState(1);
  const [defaultFrequency, setDefaultFrequency] = useState(1);

  const [selectedAreaModeState, setSelectedAreaModState] = useState("REGION");
  const [formData, setFormData] = useState<BandaFormData>({
    region: "",
    district: "",
    ward: "",
    mtaaVillage: "",
    districtPostcode: "",
    searchCode: "",
    wardPostcode: "",
  });

  const contentData = useGetContentQuery({
    fetchPolicy: "cache-first",
    variables: {
      getContentInput: {
        contentId: contentIDd,
      },
    },
  });

  const { loading, error, data, refetch } = useGetBandaByCodeQuery({
    fetchPolicy: "network-only",
    variables: {
      feedInputData: {
        userId: user.id,
        page,
        pageSize,
        category: formData.searchCode,
      },
    },
  });
  const areaDataFetched = useFilterAreasQuery({
    fetchPolicy: "network-only",
    variables: {
      feedInputData: {
        userId: user.id,
        page,
        pageSize,
        category: "REGION",
      },
    },
  });
  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;

    const newValue = type === "checkbox" ? checked : value;

    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const isIdAlreadyExists = (data: string) => {
    return carts
      .find((item: ICart) => item.id === contentIDd)
      ?.bandaumizaList?.some((item: any) => item.id === data);
  };
  const selectedAreaMode = (data: string) => {
    // console.log(data);
    setSelectedAreaModState(data);
  };

  const calendarRef = useRef(null);

  const [calendarEvents, setCalendarEvents] = useState([]);
  const [isBusketInCart, setIsBusketInCart] = useState(false);
  const [isBusketFund, setIsBusketFund] = useState(false);

  //default price of a bandaperHour
  const [defaultPrice, setDefaultPrice] = useState(20000);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [banda, setBanda] = useState<Banda>();
  const [defaultPriceBusket, setDefaultPriceBusket] = useState(0);
  const history = useHistory();
  // Change handler for the default price field
  const handleDefaultPriceChangeBusket = (e: any) => {
    setDefaultPriceBusket(e.target.value);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCallenderModal, setIsOpenCallenderModal] = useState(false);

  const toggleModal = () => setIsOpen(!isOpen);

  const [selectedDate, setSelectedDate] = useState([]);
  // const [selectedDateFound, setSelectedDateFound] = useState([]);

  const handleSelect = (arg: any) => {
    if (
      true
      // window.confirm(
      //   `Do you want to add an event at ${arg.startStr} to ${arg.endStr}?`
      // )
    ) {
      setSelectedDate([
        ...selectedDate,
        {
          title: "",
          start: arg.startStr,
          end: arg.endStr,
          id: arg.startStr + arg.endStr,
        },
      ]);
    }
    calendarRef.current.getApi().unselect(); // Clears selection after adding event
  };

  const handleEventClick = (arg: any) => {
    console.log("CLICKED");
    console.log("CLICKED");
    console.log("CLICKED");
    console.log(selectedDate);
    // Get the ID of the clicked event arg.startStr+arg.endStr
    const exists = selectedDate.some(
      (event) => event?.id === arg?.event?.startStr + arg?.event?.endStr
      // event?.startStr === arg?.event?.startStr && event?.endStr === arg?.event?.endStr
    );

    if (exists) {
      const filterNow = selectedDate.filter(
        (event) => event?.id !== arg?.event?.startStr + arg?.event?.endStr // && event?.endStr !== arg?.event?.endStr
        // event?.startStr !== arg?.event?.startStr && event?.endStr !== arg?.event?.endStr
      );
      setSelectedDate(filterNow);

      // updateDateReservations(
      //   JSON.stringify(filterNow),
      //   bandaInList
      // );
      return;
    }

    // Clears selection after adding event
    calendarRef.current.getApi().unselect();
  };

  const addToContentBanda = (data: any, typeIn: string) => {
    //data is banda or area
    console.log(data);
    // console.log(data.frequency);
    // console.log(data.frequency);
    // console.log(data.price);
    // console.log(selectedDateData);
    console.log(selectedDate);
    // console.log(typeIn);
    //  return

    let newBanda: IBanda = {
      // id: typeIn === "ward" ? `${data.region}_${data.district}_${data.ward}_${data.name}` : data.id,
      id: data.id,
      dates: data.dates,
      name: data.bandaCode ? data.bandaCode : data.name,
      title: contentData?.data?.getContent?.title,
      type: typeIn,
      price: data?.price
        ? data?.price
        : typeIn === "banda"
        ? data.priceHour
          ? data.priceHour * 1
          : defaultPrice * 1
        : defaultPrice *
          (contentData?.data?.getContent?.duration
            ? contentData?.data?.getContent?.duration
            : 1),

      // price per hour times hours
      frequency: typeIn === "banda" ? 0 : data.frequency ? data.frequency : 1, //defaultFrequency,
      startDateTime: "",
      endDateTime: "",
      body: data.body,
      capacity: data.capacity,
      amount: data?.price
        ? data?.price
        : typeIn === "banda"
        ? data?.priceHour
          ? data?.priceHour * 1
          : defaultPrice * 1
        : defaultPrice *
          (contentData?.data?.getContent?.duration
            ? contentData?.data?.getContent?.duration
            : 1),
      currency: "Tsh",
      image: data.image,
      district: data.district,
      region: data.region,
      ward: data.ward,
      isPromoted: data.isPromoted,
    };

    let newBusket: IBanda = {
      id: data.id,
      name: data.name,
      dates: JSON.stringify([]),
      title: contentData?.data?.getContent?.title,
      // startDateTime:"",
      // endDateTime:"",
      type: typeIn,
      price: data?.price ? data?.price : 1000,
      amount: data?.price ? data?.price : "1000",
      currency: "Tsh",
      frequency: data.frequency ? data.frequency : 1, //defaultFrequency,
      body: data.name,

      // isPromoted:false,

      capacity: 0,
      image: "",
    };

    // setAddedCartId(contentIDd);

    // Check if the item with the same id already exists in bandaumizaList
    const isCartItemAdded = carts
      .find((item: ICart) => item.id === contentIDd)
      ?.bandaumizaList?.find((bandaUmiza: IBanda) => bandaUmiza.id === data.id);

    const cartExists = carts?.find((item: any) => item.id === contentIDd);

    const isCartItemAddedX = cartExists?.bandaumizaList?.find(
      (banda: IBanda) =>
        banda.id === data.id ||
        (data.region === banda.region &&
          data.district === banda.district &&
          data.ward === banda.ward &&
          data.name === banda.name)
    );

    if (!cartExists && !isCartItemAdded) {
      console.log("NOT ADDED BEFORE , ADDING NOW");
      // console.log("run 3 times ???");
      // console.log(carts.length);
      // console.log(carts.length);
      // console.log(carts.length);
      // return
      // Compute the updated list first
      const updatedBandaumizaList = Array.isArray(
        carts?.find((item: ICart) => item.id === contentIDd)?.bandaumizaList
      )
        ? [
            ...carts?.find((item: ICart) => item.id === contentIDd)
              ?.bandaumizaList,
            typeIn === "busket" ? newBusket : newBanda,
          ]
        : [typeIn === "busket" ? newBusket : newBanda];

      // Create updated reservation data object
      const updatedData = {
        ...carts?.find((item: ICart) => item.id === contentIDd),
        bandaumizaList: updatedBandaumizaList,
      };

      // Update state with the updated data
      //@ts-ignore
      // setReservationData(updatedData);
      addToCart(updatedData);
      // setCalendarEvents([]);
      // setSelectedDate([]);
      setOpenCalendar(false);
      // console.log("Banda added to the content:", newBanda);
      return;
    }
    if (
      cartExists &&
      !isCartItemAddedX
      //  ||
      // !isCartItemAdded
    ) {
      console.log("Banda already exists in the content. Not adding it again.");
      console.log("run yettt times ???");
      console.log(carts.length);
      console.log(carts.length);
      console.log(carts.length);
      const isCartItemAdded = carts
        .find((item: ICart) => item.id === contentIDd)
        ?.bandaumizaList?.find(
          (bandaUmiza: IBanda) => bandaUmiza.id === data.id
        );

      //return
      if (isCartItemAdded) {
        return;
      }
      //@ts-ignore
      let updatedData;

      if (typeIn !== "busket") {
        updatedData = newBanda;
      } else {
        updatedData = newBusket;
      }
      // console.log("updatedData!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      // console.log("updatedData!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      // console.log(updatedData);

      let tempList = [
        ...carts?.find((item: ICart) => item.id === contentIDd).bandaumizaList,
        updatedData,
      ];

      console.log(tempList);

      const updatedDataNew = {
        ...carts?.find((item: ICart) => item.id === contentIDd),
        bandaumizaList: tempList, // updatedDataNewList
      };

      console.log("updatedDataNew##################################");
      console.log("updatedDataNew#################################");
      console.log(updatedDataNew.bandaumizaList);
      console.log("updatedDataNew#################################");
      console.log("updatedDataNew#################################");

      // setReservationData(updatedDataNew);

      addToCart(updatedDataNew);
      // setCalendarEvents([]);
      // setSelectedDate([]);
      setOpenCalendar(false);
      return;
    }

    if (
      cartExists &&
      isCartItemAddedX
      //  ||
      // !isCartItemAdded
    ) {
      // console.log("Item exists. edit the individual part it now");

      //return

      //@ts-ignore
      let updatedData: IBanda;

      if (typeIn !== "busket") {
        updatedData = newBanda;
      } else {
        updatedData = newBusket;
      }
      // console.log("updatedData!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      // console.log("updatedData!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      // console.log(updatedData);

      const updatedDataNewList = carts
        .find((item: ICart) => item.id === contentIDd)
        ?.bandaumizaList?.map((item: IBanda) => {
          if (item.id === data.id) {
            return updatedData;
          } else {
            return item;
          }
        });

      console.log(updatedDataNewList);

      // if (isCartItemAdded) {
      //   return;
      // }

      const updatedDataNew = {
        ...carts?.find((item: ICart) => item.id === contentIDd),
        bandaumizaList: updatedDataNewList,
      };

      // console.log("updatedDataNew##################################");
      //console.log("updatedDataNew#################################");
      // console.log(updatedDataNew.bandaumizaList);
      // console.log("updatedDataNew#################################");
      // console.log("updatedDataNew#################################");

      // setReservationData(updatedDataNew);
      // if (isCartItemAdded) {
      //   return;
      // }
      addToCart(updatedDataNew);
      // setCalendarEvents([]);
      // setSelectedDate([]);
      setOpenCalendar(false);
      return;
    }
  };

  const addToCart = async (dataIn?: ICart) => {
    console.log("################################");
    console.log("################################");
    let inData: ICart = {
      ...dataIn,
      id: contentIDd,
      title: contentData?.data?.getContent?.title,
      price: contentData?.data?.getContent?.amount,
      currency: "Tsh",
      duration: contentData?.data?.getContent?.duration,
      frequency: defaultFrequency,
      userId: user.id,
      contentCost: contentData?.data?.getContent?.amount,
      coverImage: contentData?.data?.getContent?.coverImage,
      activeCart: true,
      categories: contentData?.data?.getContent?.categories.map(category => category.name),
      promoId: "",
      isPromo: false,
    };

    const notSavedBefore =
      carts?.find((item: ICart) => item.id === contentIDd) === undefined;

    console.log(notSavedBefore);
    console.log(inData);
    console.log(inData.activeCart);

    if (notSavedBefore && inData.activeCart) {
      console.log("save");
      console.log("save");
      console.log("save");
      console.log("save");
      console.log("save");
      setFirst(true);

      saveToTheCart(inData);

      localStorage.setItem(
        "activeCart",
        JSON.stringify({ item: inData, isPromo: false })
      );
    } else {
      //update it
      console.log("update");
      console.log("update");
      console.log("update");
      console.log("update");
      // return
      if (
        //activeCartReservation.update ||
        true
      ) {
        updateCartState(inData);

        localStorage.setItem(
          "activeCart",
          JSON.stringify({ item: inData, isPromo: false })
        );
      }
    }
  };

  const selectedPriceSevice = (price: number, data: any) => {
    const updatedData = {
      ...data,
      price: price,
      amount: price,
    };
    addToContentBanda(updatedData, updatedData.type);
  };

  const getFrequency = (frequencyIn: number, data: IBanda) => {
    // console.log(frequencyIn);
    // console.log(frequencyIn);
    console.log(frequencyIn);
    const updatedData = {
      ...data,
      frequency: frequencyIn,
    };
    console.log(updatedData);

    addToContentBanda(updatedData, updatedData.type);
    // updateBandaItem(data.id, updatedData);
  };

  const removeFromList = (data: any) => {
    // Check if the item with the specified id exists in bandaumizaList
    const isBandaFound = carts
      .find((item: ICart) => item.id === contentIDd)
      .bandaumizaList?.some(
        //@ts-ignore
        (banda) => banda.id === data.id
      );

    // console.log(isBandaFound);
    // console.log(isBandaFound);
    // console.log(isBandaFound);
    // console.log(isBandaFound);

    if (isBandaFound) {
      // If the item exists, create a new array without the item to be removed
      const updatedBandaumizaList = carts
        ?.find((item: ICart) => item.id === contentIDd)
        ?.bandaumizaList?.filter(
          //@ts-ignore
          (banda) => banda.id !== data.id
        );

      console.log(updatedBandaumizaList);

      // setReservationData((prevReservationData) => ({
      //   ...prevReservationData,
      //   bandaumizaList: updatedBandaumizaList,
      // }));

      console.log(`Banda with id ${data.id} removed from the content.`);

      const updatedData = {
        ...carts?.find((item: ICart) => item.id === contentIDd),
        bandaumizaList: updatedBandaumizaList,
      };

      // Update state with the updated data
      //@ts-ignore
      // setReservationData(updatedData);
      addToCart(updatedData);
    } else {
      console.log(`Banda with id ${data.id} not found in the content.`);
      console.log(carts?.find((item: ICart) => item.id === contentIDd));
      deleteCart(carts?.find((item: ICart) => item.id === contentIDd));
    }
  };

  const isPromoted = (value: boolean, data: IBanda) => {
    console.log("isPromoted");
    console.log(value);
    console.log(value);
    console.log("isPromoted");

    const updatedData = {
      ...data,
      isPromoted: value,
    };
    addToContentBanda(updatedData, updatedData.type);
  };

  const updateDateReservations = (value: string, bandaInList: IBanda) => {
    const updatedData = {
      ...bandaInList,
      dates: value,
    };

    console.log(updatedData);

    addToContentBanda(updatedData, updatedData.type);
  };

  const deleteCart = React.useCallback(
    (cart: ICart) => dispatch(removeCart(cart)),
    [dispatch, removeCart]
  );

  const [first, setFirst] = useState(true);

  useEffect(() => {
    setCalendarEvents([]);
    setSelectedDate([]);

    // console.log(contentIDd);
    const activeCart = JSON.parse(localStorage.getItem("activeCart"));
    // console.log(activeCart);

    const idExists = carts.some((item: any) => item.id === contentIDd);

    const foundCart = activeCart?.item?.bandaumizaList?.find(
      (item: any) =>
        item?.name === banda?.bandaCode || item?.bandaCode === banda?.bandaCode
    );

    // console.log(idExists);

    if (!idExists) {
      console.log("DONT EXIST");
      console.log("DONT EXIST");
      console.log("DONT EXIST");

      setCalendarEvents([]);
      setSelectedDate([]);
      return;
    }

    //if (openCalendar) {

    if (banda?.eventsFunds?.length > 0) {
      console.log("Banda:", banda);
      console.log("Events Funds:", banda?.eventsFunds);

      const allScheduledData = banda?.eventsFunds?.map((item: Event) => {
        // console.log("Events :", item);
        if (!(item?.startDateTime === "") && !(item?.endDateTime === "")) {
          return {
            title: item.title,
            // eventType: item.eventType,
            start: item.startDateTime,
            end: item.endDateTime,
            color:
              item.eventType === "FUNDED"
                ? "#2d6a4f"
                : item.eventType === "PRIVATE"
                ? "#468faf"
                : "",
          };
        }
      });

      const initialEventsDB = allScheduledData?.filter(
        (item) => item !== undefined
      );

      //seting events from the db
      setCalendarEvents([...initialEventsDB]);
    }
    const foundCartDt = JSON.parse(
      foundCart?.dates ? foundCart?.dates : JSON.stringify([])
    );
    console.log("RUNNNING");
    console.log("RUNNNING");
    console.log("RUNNNING");
    console.log("RUNNNING");
    console.log("RUNNNING");
    console.log(foundCartDt);
    const xx = selectedDate;
    const joinedArray = xx.concat(foundCartDt); //[...selectedDate, ...foundCartDt];

    console.log(joinedArray);

    setSelectedDate(foundCartDt);
    // setSelectedDate([...selectedDate, ...foundCartDt]);
  }, [openCalendar]);
  //delete

  useEffect(() => {
    if (
      !(
        carts?.find((item: ICart) => item.id === contentIDd)?.bandaumizaList
          ?.length > 0
      ) &&
      first
    ) {
      deleteCart(carts?.find((item: ICart) => item.id === contentIDd));
      setFirst(false);
    }
  }, [carts]);

  console.log("carts");
  console.log(carts);
  console.log("carts");

  return (
    <div className="w-full flex bg-gray-50 justify-center flex-col items-center ">
      <div className="w-full flex max-w-screen-xl flex-col items-center ">
        <div className="w-full  max-w-screen-xl">
          <div className="container mx-auto mb-5">
            {true && (
              <div
                className={"rounded-xl h-min "}
                style={{
                  backgroundColor: "#10002b",
                  height: "fit-content",
                  padding: 0,
                }}
              >
                <div className=" p-4 mb-5">
                  <Text
                    color={"white"}
                    fontWeight="bold"
                    fontSize="16px"
                    mt="10px"
                    mb="4px"
                  >
                    {contentData?.data?.getContent?.title?.toUpperCase()}
                  </Text>
                  <Text
                    color={"white"}
                    fontWeight="bold"
                    fontSize="12px"
                    mt="10px"
                    mb="4px"
                  >
                    {contentData?.data?.getContent?.author?.username}
                  </Text>

                  <Text
                    color={textColorPrimary}
                    fontWeight="bold"
                    fontSize="12px"
                    mt="10px"
                    mb="4px"
                  >
                    Content Amount{" : "}
                    {contentData?.data?.getContent?.amount}{" "}
                    {contentData?.data?.getContent?.currency}
                  </Text>
                </div>

                <ol className="flex   sm:px-3 sm:p-4 space-x-0 sm:space-x-4   text-sm font-medium text-center text-gray-500 bg-white border border-gray-100 rounded-xl shadow-lg sm:text-base ">
                  <li
                    onClick={() => setStep(1)}
                    className={` ${
                      step === 1 ? "text-blue-600 dark:text-blue-500" : ""
                    }`}
                  >
                    <button
                      onClick={() => setStep(1)}
                      className=" flex items-center"
                    >
                      <span
                        className={`  flex items-center justify-center w-5 h-5  text-xs border rounded-full shrink-0 ${
                          step === 1
                            ? "border-blue-600 dark:border-blue-500"
                            : "border-gray-500 dark:border-gray-400"
                        }`}
                      >
                        {" "}
                      </span>
                      <span className="hidden sm:visible  sm:inline-flex  sm:ms-2">
                        Banda
                      </span>
                      {/* <span className="collapse sm:visible  m-0 ml-2 p-0 text-start">
                        Banda
                      </span> */}
                      {/* <svg
                        className="collapse sm:visible w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 12 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m7 9 4-4-4-4M1 9l4-4-4-4"
                        />
                      </svg> */}
                    </button>
                  </li>
                  <li
                    onClick={() => setStep(2)}
                    className={`-mr-5 sm:mr-auto ${
                      step === 2 ? "text-blue-600 dark:text-blue-500" : ""
                    }`}
                  >
                    <button
                      onClick={() => setStep(2)}
                      className="flex items-center"
                    >
                      <span
                        className={`flex items-center justify-center w-5 h-5  text-xs border rounded-full shrink-0 ${
                          step === 2
                            ? "border-blue-600 dark:border-blue-500"
                            : "border-gray-500 dark:border-gray-400"
                        }`}
                      >
                        {" "}
                      </span>
                      <span className="hidden sm:inline-flex sm:ms-2">
                        Area{" "}
                      </span>

                      <svg
                        className="hidden sm:visible w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 12 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m7 9 4-4-4-4M1 9l4-4-4-4"
                        />
                      </svg>
                    </button>
                  </li>

                  <svg
                        className="collapse sm:visible w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 12 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m7 9 4-4-4-4M1 9l4-4-4-4"
                        />
                      </svg>

                  <li
                    onClick={() => setStep(3)}
                    className={`-mr-5 sm:mr-auto  ${
                      step === 3 ? "text-blue-600 dark:text-blue-500" : ""
                    }`}
                  >
                    <button
                      onClick={() => setStep(3)}
                      className="flex items-center"
                    >
                      <span
                        className={`flex items-center justify-center w-5 h-5 me-2 text-xs border rounded-full shrink-0 ${
                          step === 3
                            ? "border-blue-600 dark:border-blue-500"
                            : "border-gray-500 dark:border-gray-400"
                        }`}
                      >
                        {" "}
                      </span>
                      <span className="hidden sm:inline-flex sm:ms-2">
                        Fund
                      </span>

                      <svg
                        className="hidden sm:visible w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 12 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m7 9 4-4-4-4M1 9l4-4-4-4"
                        />
                      </svg>
                    </button>
                  </li>
  
                  <li
                    onClick={() => setStep(4)}
                    className={`-mr-5 sm:mr-auto  ${
                      step === 4 ? "text-blue-600 dark:text-blue-500" : ""
                    }`}
                  >
                    <button
                      onClick={() => setStep(4)}
                      className="flex items-center"
                      //       disabled={!carts?.find(
                      //   (item: ICart) => item.id === contentIDd
                      // )}
                    >
                      <span
                        className={`flex items-center justify-center w-5 h-5 me-2 text-xs border rounded-full shrink-0 ${
                          step === 4
                            ? "border-blue-600 dark:border-blue-500"
                            : "border-gray-500 dark:border-gray-400"
                        }`}
                      >
                        {" "}
                      </span>
                      Review
                    </button>
                  </li>
                </ol>
              </div>
            )}
          </div>

          <div className="container mx-auto p-4 rounded-xl">
            <div className="mt-4">
              {step === 1 && (
                <div className=" p-4 rounded-lg shadow-lg  space-y-4 bg-white my-5 ">
                  <div className="flex justify-between">
                  <p className="font-black text-2xl">Choose Banda by Code</p>

                    <Link
                      onClick={(e) => {
                        setStep(step + 1);
                      }}
                      color="green"
                      variant={"link"}
                      _hover={{ bg: "#f1f1f1" }}
                      _active={{ bg: "white" }}
                      _focus={{ bg: "white" }}
                      fontWeight="500"
                      fontSize="14px"
                      // position={"absolute"}
                      right={60}
                    >
                      Next
                    </Link>
                  </div>
<Input
                      type="text"
                      value={formData.searchCode}
                      onChange={handleChange}
                      variant={"auth"}
                      placeholder="Search banda by code"
                      marginTop={"30px"}
                      height={"40px"}
                      name="searchCode"
                      style={{
                        // borderColor:"#eef0f2",
                        border: "1px solid #eef0f2",
                        // position:"relative",
                        color: "#333",
                        width: "100%",
                      }}
                    />

                 
                  <div
                    style={{
                      // position: "sticky",
                      marginTop: 10,
                      right: 0,
                      // maxHeight: 800, // Use viewport height for full height
                      width: "100%",
                      // marginLeft: "2%",
                      // backgroundColor: "#6b9080",
                      // overflowY: "auto",
                      // padding: "20px",
                    }}
                  >
                    <p className="my-3 font-black text-2xl">
                      {
                        carts
                          ?.find((item: ICart) => item?.id === contentIDd)
                          ?.bandaumizaList?.filter(
                            (item: any) => item.type === "banda"
                          ).length
                      }{" "}
                      Banda selected
                    </p>

                    {carts
                      ?.find((item: ICart) => item.id === contentIDd)
                      ?.bandaumizaList?.filter(
                        (item: any) => item.type === "banda"
                      )
                      ?.map((cart: any) => (
                        <SelectedBandaUmiza
                          key={cart.id}
                          cart={cart}
                          removeCart={removeCart}
                          removeFromList={removeFromList}
                          selectedFrequency={getFrequency}
                          isPromoted={isPromoted}
                          defSelectedPrice={cart?.price}
                          selectedPrice={selectedPriceSevice}
                        />
                      ))}

                   
                    <Flex
                      mt={"20px"}
                      // maxHeight={"800px"}
                      flexDirection={"column"}
                      width={"100%"}
                      // overflowX={{ sm: "scroll", lg: "hidden" }}
                    >
                      {formData?.searchCode?.length >= 3 &&
                        data?.getBandaByCode?.map((item: any) => {
                          const itemExists = isIdAlreadyExists(item.id);

                          return (
                            <div
                              style={{
                                height: 125,
                                width: "100%",

                                backgroundColor: "#284b63",
                                marginBottom: 5,
                                borderRadius: 5,
                                display: "flex",
                                flexDirection: "row",
                              }}
                              key={item.id}
                            >
                              <div
                                style={{
                                  width: "75%",
                                  paddingLeft: 10,
                                  // backgroundColor:"red",
                                  paddingTop: 2,
                                }}
                              >
                                {" "}
                                <p
                                  style={{
                                    padding: 0,
                                    fontSize: 16,
                                    color: "#d6d6d6",
                                  }}
                                >
                                  {item.title}
                                </p>
                                <p
                                  style={{
                                    padding: 0,
                                    color: "#d6d6d6",
                                  }}
                                >
                                  {" "}
                                  {item.bandaName}
                                </p>
                                <p
                                  style={{
                                    padding: 0,
                                    color: "#d6d6d6",
                                  }}
                                >
                                  {item.bandaCode}
                                </p>
                                <p
                                  style={{
                                    padding: 0,
                                    color: "#d6d6d6",
                                    fontSize: 13,
                                  }}
                                >
                                  {item.region} {item.district} {item.ward}
                                </p>
                                <p
                                  style={{
                                    padding: 0,
                                    fontSize: 12,
                                    color: "#d6d6d6",
                                  }}
                                >
                                  {" "}
                                  {item.physicalResidency}
                                </p>
                                <p
                                  style={{
                                    padding: 0,
                                    fontWeight: "700",
                                    color: "gray",
                                  }}
                                >
                                  {item.currency}
                                  {item.priceHour} per hr
                                </p>
                              </div>

                              <div
                                style={{
                                  // marginLeft: "60%",
                                  // backgroundColor:"green",
                                  width: "25%",
                                  // justifyContent:"center",
                                  // alignContent:"center",
                                  // display:"flex",
                                }}
                              >
                                {itemExists ? (
                                  <button
                                    onClick={() => {
                                      setCalendarEvents([]);
                                      setSelectedDate([]);
                                      setOpenCalendar(true);
                                      setIsOpenCallenderModal(true);
                                      setIsOpen(false);
                                      setBanda(item);
                                    }}
                                    style={{
                                      backgroundColor: "#6b9080",
                                      width: "80px",
                                      height: 30,
                                      padding: 5,
                                      color: "black",
                                      // marginRight: "3%",
                                      marginTop: "30%",
                                      marginLeft: "6%",
                                      fontSize: 12,
                                      // fontWeight: "bold",
                                      borderRadius: 5,
                                    }}
                                  >
                                    Edit
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => {
                                      setCalendarEvents([]);
                                      setSelectedDate([]);
                                      setOpenCalendar(true);
                                      setIsOpenCallenderModal(true);
                                      setIsOpen(false);
                                      setBanda(item);
                                    }}
                                    style={{
                                      backgroundColor: "#04a777",
                                      width: "80px",
                                      marginTop: "30%",
                                      height: 30,
                                      color: "black",
                                      // marginRight: "3%",
                                      // fontWeight: "bold",
                                      marginLeft: "10%",
                                      fontSize: 12,
                                      borderRadius: 5,
                                    }}
                                  >
                                    Add this
                                  </button>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </Flex>
                  </div>
                </div>
              )}

              {step === 2 && (
                <div className=" p-4 rounded-lg shadow-lg md:p-5 space-y-4 bg-white my-5 overflow-auto">
                 
                  <div className="p-4 md:p-5 space-y-4  overflow-auto">
                    <div className="flex justify-between">
                    <p className="font-black text-2xl">Choose area level</p>
                    <Link
                        onClick={(e) => {
                          setStep(step + 1);
                        }}
                        color="green"
                        variant={"link"}
                        _hover={{ bg: "#f1f1f1" }}
                        _active={{ bg: "white" }}
                        _focus={{ bg: "white" }}
                        fontWeight="500"
                        fontSize="14px"
                        // position={"absolute"}
                        right={60}
                      >
                        Next
                      </Link>
                    </div>

                    {
                      <div
                      //flexDirection="column" w="100%" px="0px"
                      // bg={"red"}
                      //borderRadius={"5px"}
                      // marginTop={"20px"}
                      //  height={"400px"}
                      //  overflowY={"auto"}
                      // bg={"red"}
                      >
                        {false && (
                          <Flex
                            flexDirection={"row"}
                            // marginLeft={"20px"}
                            justifyContent={"space-between"}
                            // borderBottomWidth={"1px"}
                            pb={"5px"}
                            // borderColor={"#333"}
                          >
                            {/* <Button
                            variant={"link"}
                            color={
                              specifyArea && false ? "green.300" : "gray.500"
                            }
                            fontSize={specifyBanda && false ? "16px" : "14px"}
                            mb="0px"
                            fontWeight={specifyBanda && false ? "600" : "500"}
                            lineHeight="100%"
                            style={{
                              marginTop: 2,
                            }}
                            onClick={() => {
                              // setSpecifyArea(true);
                              // setSpecifyBanda(false);
                            }}
                          >
                            AREA
                          </Button> */}
                          </Flex>
                        )}

                        <div className="flex flex-col sm:flex-row">
                          {
                            // specifyArea ||
                            true && (
                              <Flex
                                flexDirection={"column"}
                                // maxHeight={"300px"}
                                width={"100%"}
                                // height: 100, // Use viewport height for full height
                                // width: "100%",
                                // marginLeft: "2%",
                                // backgroundColor={"#6b9080"}
                                // overflowX={"auto"}
                              >
                                <Flex
                                  flexDirection={"column"}
                                  maxHeight={"300px"}
                                  width={"100%"}
                                  // height: 100, // Use viewport height for full height
                                  // width: "100%",
                                  // marginLeft: "2%",
                                  // backgroundColor={"#6b9080"}
                                  // overflowX={"auto"}
                                >
                                  <ReservationArea
                                    onFilterChange={() => {}}
                                    headerText={" "}
                                    buttonText={"Submit"}
                                    theSelectedMode={selectedAreaMode}
                                    optionsLabels={areaFiltersLabels}
                                    optionsQnz={areaQuestionsInit}
                                  />
                                </Flex>

                                {/* AREA LOCATION SELECTION */}
                                <p className="text-white m-5 ">
                                  {selectedAreaModeState}
                                </p>

                                {[
                                  "REGION",
                                  "MTAA",
                                  "DISTRICT",
                                  "WARD",
                                ].includes(selectedAreaModeState) && (
                                  <div>
                                    {/* {errors.region && (
                                    <Text style={{ color: "red" }}>{errors.region}</Text>
                                  )} */}

                                    <Select
                                      name="region"
                                      value={formData.region}
                                      onChange={handleChange}
                                      placeholder="Select region"
                                    >
                                      {regionData?.map((cat: any) => (
                                        <option
                                          key={cat.region}
                                          value={cat.region}
                                        >
                                          {cat.region}
                                        </option>
                                      ))}
                                    </Select>
                                  </div>
                                )}

                                {["MTAA", "DISTRICT", "WARD"].includes(
                                  selectedAreaModeState
                                ) && (
                                  <div>
                                    <Select
                                      name="district"
                                      value={formData.district}
                                      onChange={handleChange}
                                      placeholder="Select district"
                                    >
                                      {regionData
                                        ?.find(
                                          (cat: any) =>
                                            cat.region === formData.region
                                        )
                                        ?.data?.map((cat_district: any) => (
                                          <option
                                            key={
                                              formData.region +
                                              "_" +
                                              cat_district.district
                                            }
                                            // value={cat_district.district}
                                            value={
                                              cat_district.district +
                                              "_" +
                                              cat_district.postCode
                                            }
                                          >
                                            {cat_district.district}
                                          </option>
                                        ))}
                                    </Select>
                                  </div>
                                )}

                                {["MTAA", "WARD"].includes(
                                  selectedAreaModeState
                                ) && (
                                  <div>
                                    <Select
                                      name="ward"
                                      value={formData.ward}
                                      onChange={handleChange}
                                      placeholder="Select ward"
                                    >
                                      {regionData
                                        ?.find(
                                          (cat: any) =>
                                            cat.region === formData.region
                                        )
                                        // @ts-ignore

                                        ?.data?.find(
                                          (cat_district: any) =>
                                            cat_district.district +
                                              "_" +
                                              cat_district.postCode ===
                                            formData.district
                                        )
                                        ?.data?.map((cat_ward: any) => {
                                          // console.log("cat_ward");
                                          // console.log(cat_ward);
                                          // console.log("cat_ward");

                                          return (
                                            <option
                                              key={
                                                formData.district +
                                                "_" +
                                                cat_ward.ward
                                              }
                                              // value={cat_ward.ward}
                                              value={
                                                cat_ward.ward +
                                                "_" +
                                                cat_ward.postCode
                                              }
                                            >
                                              {cat_ward.ward}
                                            </option>
                                          );
                                        })}
                                    </Select>
                                  </div>
                                )}
                                {selectedAreaModeState === "MTAA" && (
                                  <div>
                                    <Select
                                      name="mtaaVillage"
                                      value={formData.mtaaVillage}
                                      onChange={handleChange}
                                      placeholder="Select mtaa"
                                    >
                                      {regionData
                                        // @ts-ignore
                                        ?.find(
                                          (cat: any) =>
                                            cat?.region === formData.region
                                        )
                                        // @ts-ignore
                                        ?.data?.find(
                                          (cat_district: any) =>
                                            `${cat_district?.district}_${cat_district?.postCode}` ===
                                            formData.district
                                        )
                                        ?.data?.find(
                                          (cat_ward: any) =>
                                            `${cat_ward?.ward}_${cat_ward?.postCode}` ===
                                            formData.ward
                                        )
                                        ?.data?.map((mtaaVillage: any) => (
                                          <option
                                            key={
                                              formData.district +
                                              "_" +
                                              mtaaVillage.mtaaVillage
                                            }
                                            value={
                                              mtaaVillage.mtaaVillage
                                                ? `${mtaaVillage.mtaaVillage}`
                                                : //@ts-ignore
                                                  `${mtaaVillage.mtaaVillage}`
                                            }
                                          >
                                            <span className="text-blue-300">
                                              {mtaaVillage.mtaaVillage &&
                                                `${
                                                  "Mtaa/Kijiji  " +
                                                  mtaaVillage.mtaaVillage
                                                }`}
                                            </span>

                                            {mtaaVillage.kitongoji &&
                                              //@ts-ignore

                                              `${
                                                "   Kitongoji cha   " +
                                                mtaaVillage.kitongoji
                                              }`}
                                          </option>
                                        ))}
                                    </Select>
                                  </div>
                                )}

                                {/*  */}

                                {/* Button  */}
                                <Button
                                  type="submit"
                                  onClick={() => {
                                    // handleSubmit();

                                    if (formData.region.trim() === "") {
                                      return;
                                    }
                                    addToContentBanda(
                                      {
                                        title: "",
                                        district: formData.district,
                                        id:
                                          formData.mtaaVillage +
                                          "_" +
                                          formData.ward +
                                          "_" +
                                          formData.district +
                                          "_" +
                                          formData.region,
                                        region: formData.region,
                                        ward: formData.ward,
                                        name: formData.mtaaVillage,
                                        dates: JSON.stringify([]),
                                        isPromoted: false,
                                      },
                                      "ward"
                                    );

                                    setFormData({
                                      region: "",
                                      district: "",
                                      ward: "",
                                      mtaaVillage: "",
                                      districtPostcode: "",
                                      wardPostcode: "",
                                    });
                                  }}
                                  colorScheme="green"
                                  marginTop="50px"
                                  width={"300px"}
                                >
                                  Add
                                </Button>

                                <p className="my-3 font-black text-2xl">
                                  {
                                    carts
                                      ?.find(
                                        (item: ICart) => item.id === contentIDd
                                      )
                                      ?.bandaumizaList?.filter(
                                        (item: any) => item.type === "ward"
                                      )?.length
                                  }{" "}
                                  Area selected
                                </p>

                                {carts
                                  ?.find(
                                    (item: ICart) => item.id === contentIDd
                                  )
                                  ?.bandaumizaList?.filter(
                                    (item: any) => item.type === "ward"
                                  )
                                  ?.map((cart: any) => (
                                    <SelectedBandaUmiza
                                      key={cart.id}
                                      cart={cart}
                                      removeCart={removeCart}
                                      removeFromList={removeFromList}
                                      selectedFrequency={getFrequency}
                                      isPromoted={isPromoted}
                                      defSelectedPrice={cart?.price}
                                      selectedPrice={selectedPriceSevice}
                                    />
                                  ))}
                              </Flex>
                            )
                          }
                        </div>
                      </div>
                    }
                  </div>
                </div>
              )}

              {step === 3 && (
                <div className=" p-4 rounded-lg shadow-lg md:p-5 space-y-4 bg-white my-5 overflow-auto">
                  <p className="font-black text-2xl">
                    {carts
                      ?.find((item: ICart) => item.id === contentIDd)
                      ?.bandaumizaList?.filter(
                        (item: any) => item.type === "busket"
                      ).length > 0
                      ? "Your donation amount "
                      : "Do you want to donate to this content"}{" "}
                  </p>
                  <p>
                    {
                      carts
                        ?.find((item: ICart) => item.id === contentIDd)
                        ?.bandaumizaList?.filter(
                          (item: any) => item.type === "busket"
                        )[0]?.price
                    }
                  </p>

                  {!(
                    carts
                      ?.find((item: ICart) => item.id === contentIDd)
                      ?.bandaumizaList?.filter(
                        (item: any) => item.type === "busket"
                      )?.length > 0
                  ) && (
                    <SimpleGrid
                      style={
                        {
                          // marginTop: 50,
                          // marginBottom: 10,
                        }
                      }
                      columns={2}
                      gap="0px"
                    >
                      <FormControl
                        width={"80%"}
                        // bg={"red"}
                        style={{
                          marginTop: 20,
                          height: 80,
                          // marginBottom: 30,
                        }}
                      >
                        <FormLabel
                          style={{
                            color: "black",
                          }}
                        >
                          Busket Amount
                        </FormLabel>
                        <Input
                          type="number"
                          id="defaultPriceBusket"
                          value={defaultPriceBusket}
                          onChange={handleDefaultPriceChangeBusket}
                          padding="10px"
                          fontSize="16px"
                          border="1px solid #ddd"
                          borderRadius="5px"
                          boxShadow="0 0 5px rgba(0, 0, 0, 0.1)"
                          width="100%"
                          boxSizing="border-box"
                          marginTop="5px"
                          style={{
                            color: "black",
                          }}
                        />
                      </FormControl>

                      <Flex
                        // bg={"pink"}
                        // height={"80px"}
                        justifyContent={"center"}
                        alignContent={"center"}
                        width={"100%"}
                        // marginTop={"20px"}
                      >
                        <Button
                          // marginTop={"20%"}
                          onClick={() => {
                            // addToContentBanda(
                            //   {
                            //     id: "busket",
                            //     name: "busket",
                            //     type: "busket",
                            //     body: "busket",
                            //     capacity: 0,
                            //     image: null,
                            //   },
                            //   "busket"
                            // );
                            selectedPriceSevice(defaultPriceBusket, {
                              id: "busket",
                              name: "busket",
                              type: "busket",
                              body: "busket",
                              price: defaultPriceBusket,
                              capacity: 0,
                              image: null,
                            });
                          }}
                          bg="green.100"
                          color="black"
                          _hover={{ bg: "#01755c" }}
                          // _active={{ bg: "white" }}
                          // _focus={{ bg: "white" }}
                          fontWeight="500"
                          fontSize="14px"
                          // py="3px"
                          // px="2"
                          // me="38px"
                          style={
                            {
                              // marginTop: 40,
                              // height:50,
                              // marginBottom: 30,
                            }
                          }
                        >
                          Donate
                        </Button>
                        {
                          // ( !(reservationData?.bandaumizaList?.length > 0))
                          !isBusketInCart && (
                            <Link
                              // marginTop={"25%"}
                              onClick={(e) => {
                                setIsBusketFund(!isBusketFund);
                                if (!isBusketFund) {
                                }
                              }}
                              color="white"
                              variant={"link"}
                              // _hover={{ bg: "#00a8e8" }}
                              _active={{ bg: "#007ea7" }}
                              _focus={{ bg: "#007ea7" }}
                              fontWeight="500"
                              fontSize="14px"
                              // py="30px"
                              // width={"30%"}
                              // px="47px"
                              // me="38px"
                              // style={{
                              //   width: 400,
                              // }}
                            >
                              Cancel
                            </Link>
                          )
                        }
                      </Flex>
                    </SimpleGrid>
                  )}
                </div>
              )}

              {step === 4 && (
                <div className=" p-4 rounded-lg shadow-lg md:p-5 space-y-4 bg-white my-5 overflow-auto">
                  <h2>Step 3: Review</h2>

                  <div className="flex justify-between">
                    <p>Review your selection before proceeding.</p>

                    <Link
                      onClick={(e) => {}}
                      color="green"
                      variant={"link"}
                      _hover={{ bg: "#f1f1f1" }}
                      _active={{ bg: "white" }}
                      _focus={{ bg: "white" }}
                      fontWeight="500"
                      fontSize="14px"
                      // position={"absolute"}
                      right={10}
                    >
                      Finish
                    </Link>
                  </div>

                  <Card>
                    {carts?.find((item: ICart) => item.id === contentIDd)
                      ?.bandaumizaList?.length > 0 && (
                      <Card
                        maxHeight={"800px"}
                        mt={"20px"}
                        overflowX={{ sm: "scroll", lg: "hidden" }}
                      >
                        {carts.length > 0 && (
                          <>
                            {carts
                              ?.find((item: ICart) => item.id === contentIDd)
                              ?.bandaumizaList?.map((cart: any) => (
                                <SelectedBandaUmiza
                                  key={cart.id}
                                  cart={cart}
                                  removeCart={removeCart}
                                  removeFromList={removeFromList}
                                  selectedFrequency={getFrequency}
                                  isPromoted={isPromoted}
                                  defSelectedPrice={cart?.price}
                                  selectedPrice={selectedPriceSevice}
                                />
                              ))}
                          </>
                        )}
                      </Card>
                    )}
                  </Card>

                  <div className="container mx-auto p-4 rounded-lg shadow-lg md:p-5 space-y-4 bg-white my-5 overflow-auto">
                    <SimpleGrid
                      style={{
                        marginTop: 20,
                        marginBottom: 30,
                        display: "flex",
                        justifyContent: "space-between",
                        paddingRight: "2%",
                        paddingLeft: "2%",
                      }}
                      columns={2}
                      gap="20px"
                    >
                      {carts?.find((item: ICart) => item.id === contentIDd)
                        ?.bandaumizaList?.length > 0 && (
                        <Link
                          onClick={() => {
                            // history.push("/site/explore");
                          }}
                          style={{ alignSelf: "center" }}
                        >
                          <Link
                            onClick={(e) => {
                              //     addToCart();
                              //     updateCartState({
                              //       ...carts?.find(
                              //   (item: ICart) => item.id === contentIDd
                              // ),
                              //       activeCart: true,
                              //     });
                              history.push("/site/cart_review");
                            }}
                            color="green"
                            variant={"link"}
                            _hover={{ bg: "#f1f1f1" }}
                            _active={{ bg: "white" }}
                            _focus={{ bg: "white" }}
                            fontWeight="500"
                            fontSize="14px"
                          >
                            Checkout
                          </Link>
                        </Link>
                      )}

                      {carts?.find((item: ICart) => item.id === contentIDd)
                        ?.bandaumizaList?.length > 0 && (
                        <Link
                          onClick={() => {
                            // history.push("/site/explore");
                          }}
                          style={{ alignSelf: "center" }}
                        >
                          <Link
                            onClick={(e) => {
                              // addToCart();
                              history.push("/site/explore");
                            }}
                            color="blue.400"
                            variant={"link"}
                            _hover={{ bg: "#f1f1f1" }}
                            _active={{ bg: "white" }}
                            _focus={{ bg: "white" }}
                            fontWeight="500"
                            fontSize="14px"
                          >
                            Continue Reservation
                          </Link>
                        </Link>
                      )}

                      <Link
                        // mt={"5%"}
                        // href={download}
                        onClick={() => {
                          setSelectedDate([]);
                          // setSelectedDateData({ start: "", end: "" });
                          setOpenCalendar(false);

                          history.push("/site/explore");
                        }}
                      >
                        <Link
                          // bg="#ff476c"
                          color="#ff476c"
                          variant={"link"}
                          _hover={{ bg: "#f1f1f1" }}
                          _active={{ bg: "white" }}
                          _focus={{ bg: "white" }}
                          fontWeight="500"
                          fontSize="14px"
                          // py="30px"
                          // px="27"
                          // me="38px"
                        >
                          Cancel
                        </Link>
                      </Link>
                    </SimpleGrid>
                  </div>
                </div>
              )}
            </div>
          </div>

          {isOpenCallenderModal && (
            <div
              id="static-modal"
              className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-gray-900 bg-opacity-80"
              aria-hidden={!isOpenCallenderModal}
            >
              <div className="relative p-4 s w-full  max-w-2xl sm:max-w-6xl  max-h-full">
                {/* Modal content */}
                <div
                  className="relative bg-white rounded-lg shadow dark:bg-gray-700"
                  style={{
                    backgroundColor: "#03071e",
                  }}
                >
                  {/* Modal header */}
                  <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t border-gray-800">
                    <h3 className="text-xl font-semibold text-white dark:text-white">
                      When to reserve ?
                    </h3>

                    <button
                      onClick={() => {
                        setSelectedDate([]);
                        setOpenCalendar(false);
                        toggleModal();
                        setIsOpenCallenderModal(false);
                        setIsOpen(false);
                      }}
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-white rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <svg
                        className="w-3 h-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  {/* Modal body */}
                  <div className="p-4 md:p-5 space-y-4 h-[70vh] overflow-auto">
                    <Card borderRadius={"5px"}>
                      <Text
                        color={"gray"}
                        fontWeight="bold"
                        fontSize="sm"
                        mt="2px"
                        // mb="4px"
                      >
                        {banda?.bandaName}
                      </Text>

                      <Text
                        color={textColorPrimary}
                        fontWeight="bold"
                        fontSize="sm"
                        mt="1px"
                        mb="1px"
                      >
                        {banda?.address}
                      </Text>

                      <Text
                        color={textColorPrimary}
                        fontWeight="bold"
                        fontSize="sm"
                        mt="1px"
                        mb="1px"
                      >
                        Banda Capacity : {banda?.capacity}
                      </Text>

                      <Text
                        color={textColorPrimary}
                        fontWeight="bold"
                        // fontSize="xl"
                        mt="1px"
                        mb="1px"
                      >
                        Cost per Hour: {banda?.priceHour} {banda?.currency}
                      </Text>

                      <FullCalendar
                        ref={calendarRef}
                        plugins={[
                          dayGridPlugin,
                          timeGridPlugin,
                          interactionPlugin,
                        ]}
                        initialView="timeGridWeek"
                        selectable={true}
                        // selectConstraint="businessHours"
                        slotDuration="01:00:00"
                        // selectAllow={(selectInfo) => {
                        //   return (
                        //     selectInfo.start.getHours() >= 8 &&
                        //     selectInfo.end.getHours() <= 17
                        //   );
                        // }}
                        selectAllow={(selectInfo) => {
                          const selectionStart = selectInfo.start;
                          const selectionEnd = selectInfo.end;
                          const now = new Date();

                          console.log("selectionStart");
                          console.log(selectionStart);
                          console.log(selectionEnd);
                          console.log(now);

                          // Log the selection info for debugging
                          console.log(
                            `Selection start: ${selectionStart}, end: ${selectionEnd}`
                          );

                          // Check if the selection starts or ends in the past
                          if (selectionStart < now || selectionEnd < now) {
                            console.log("Selection is in the past");
                            return false;
                          }

                          // Check for overlap with existing events
                          const isOverlapping = [
                            ...calendarEvents,
                            ...selectedDate,
                          ].some((event) => {
                            const eventStart = new Date(event.start);
                            const eventEnd = new Date(event.end);

                            // Check for overlap
                            return (
                              (selectionStart >= eventStart &&
                                selectionStart < eventEnd) ||
                              (selectionEnd > eventStart &&
                                selectionEnd <= eventEnd) ||
                              (selectionStart <= eventStart &&
                                selectionEnd >= eventEnd)
                            );
                          });

                          // Log the overlap result for debugging
                          console.log(`Is overlapping: ${isOverlapping}`);

                          // Return false if the selection overlaps with any event or is in the past, otherwise true
                          return !isOverlapping;
                        }}
                        // businessHours={{
                        //   daysOfWeek: [1, 2, 3, 4, 5, 6, 7], // Monday - Friday
                        //   startTime: "06:00", // 8am
                        //   endTime: "23:00", // 5pm
                        // }}
                        events={[...calendarEvents, ...selectedDate]}
                        select={handleSelect}
                        eventClick={handleEventClick}
                      />

                      {false && (
                        <button
                          onClick={() => {
                            //Open calendar here
                            setOpenCalendar(false);
                            setIsOpenCallenderModal(false);
                            setIsOpen(true);
                            setCalendarEvents([]);
                            setSelectedDate([]);
                            //   set
                            // setBanda(null);
                            // setEvents([]);
                          }}
                          style={{
                            backgroundColor: "red",
                            // width: "100%",
                            marginTop: "1%",
                            height: 50,
                            color: "black",
                            // marginRight: "3%",
                            fontWeight: "bold",
                            marginLeft: "10%",
                            fontSize: 12,
                            borderRadius: 5,
                          }}
                        >
                          Cancel Schedule at {banda?.bandaCode}
                        </button>
                      )}
                    </Card>
                  </div>
                  {/* Modal footer */}
                  <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b border-gray-800">
                    {/* <button 
                  onClick={toggleModal} 
                  type="button" 
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  I accept
                </button> */}
                    <button
                      onClick={() => {
                        setOpenCalendar(false);
                        setIsOpenCallenderModal(false);
                        setIsOpen(true);
                        setCalendarEvents([]);
                        setSelectedDate([]);
                      }}
                      type="button"
                      className="py-2.5 px-5 ms-3 text-sm font-medium text-white focus:outline-none bg-gray-600 rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 border-gray-800 dark:hover:text-white dark:hover:bg-gray-700"
                    >
                      Cancel
                    </button>

                    {selectedDate?.length > 0 && (
                      // true &&
                      <>
                        <button
                          onClick={() => {
                            const bandaInList = carts
                              ?.find((item: ICart) => item.id === contentIDd)
                              ?.bandaumizaList?.find(
                                (bandaUmiza: IBanda) =>
                                  bandaUmiza.id === banda.id
                              );

                            console.log("bandaInList");
                            console.log("bandaInList");
                            console.log(bandaInList);
                            console.log("bandaInList");

                            if (!bandaInList) {
                              addToContentBanda(
                                {
                                  ...banda,
                                  dates: JSON.stringify(selectedDate),
                                },
                                "banda"
                              );
                            } else {
                              updateDateReservations(
                                JSON.stringify(selectedDate),
                                bandaInList
                              );
                            }
                            setIsOpenCallenderModal(false);
                            setIsOpen(true);
                          }}
                          style={{
                            backgroundColor: "#04a777",
                            // width: "100%",
                            marginTop: "2%",
                            // height: 50,
                            color: "black",
                            // marginRight: "3%",
                            fontWeight: "bold",
                            marginLeft: "10%",
                            fontSize: 12,
                            borderRadius: 5,
                          }}
                        >
                          Submit Now
                        </button>

                        {false && (
                          <Link
                            onClick={(e) => {
                              const bandaInList = carts
                                .find((item: ICart) => item.id === contentIDd)
                                ?.bandaumizaList?.find(
                                  (bandaUmiza: IBanda) =>
                                    bandaUmiza.id === banda.id
                                );

                              console.log("bandaInList");
                              console.log("bandaInList");
                              console.log(bandaInList);
                              console.log("bandaInList");

                              if (!bandaInList) {
                                // addToContentBanda(
                                //   {
                                //     ...banda,
                                //     dates: JSON.stringify(selectedDate),
                                //   },
                                //   "banda"
                                // );
                              } else {
                                // updateDateReservations(
                                //   JSON.stringify(selectedDate),
                                //   bandaInList
                                // );
                              }
                              setIsOpenCallenderModal(false);
                              setIsOpen(true);
                              // addToCart();
                              // history.push("/site/explore");
                            }}
                            color="blue.400"
                            variant={"link"}
                            _hover={{ bg: "#f1f1f1" }}
                            _active={{ bg: "white" }}
                            _focus={{ bg: "white" }}
                            fontWeight="500"
                            fontSize="14px"
                          >
                            Continue Reservation
                          </Link>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
