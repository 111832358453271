// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import {
  useCreateEventMutation,
  useRemoveEventMutation,
} from "generated/graphql";
import { useState } from "react";
// Assets
import { MdAddBox, MdEdit, MdVerifiedUser } from "react-icons/md";
import { useSelector } from "react-redux";
import imageHolder from "assets/images/imageHolder.png";
import { useHistory } from "react-router-dom";
import { formatDateTimeShort } from "views/site/helpers";
import { IoCalendarOutline } from "react-icons/io5";
interface FormErrors {
  [key: string]: string;
}

export default function Hosting(props: {
  category: string;
  title: string;
  home: string;
  id: string;
  dateStart: string;
  dateEnd: string;
  sponsorName: string;
  sponsorImage: string;
  sponsorId: string;
  away: string;
  item: any;
  ranking: number | string;
  link: string;
  image: string;
  [x: string]: any;
}) {
  const {
    id,
    title,
    category,
    dateEnd,
    sponsorImage,
    sponsorName,
    sponsorId,
    dateStart,
    home,
    away,
    ranking,
    item,
    link,
    image,
    ...rest
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const brandColor = useColorModeValue("brand.500", "white");
  const bg = useColorModeValue("white", "navy.700");
  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const [removeEvent] = useRemoveEventMutation();
  const [errors, setErrors] = useState<FormErrors>({});
  const [removedSuccessfully, setRemovedSuccessfully] = useState(false);

  const history = useHistory();

  console.log("item");
  console.log(item);
  console.log("item");
  // console.log(sponsorName);

  return (
    <article className=" border-b-1 border-gray-100 bg-white">
      <div className="flex items-start gap-4 p-1 sm:p-6 lg:p-8">
        <a 
         onClick={() => {
          if (!sponsorId) {
            return;
          }
          // localStorage.setItem("sponsorItem", JSON.stringify({id :sponsorId}));

          history.push(`/site/sponsor_details?id=${sponsorId}&zIx=890`, {
            update: true,
          });

          // history.push("/site/sponsor_details", {
          //   itemData: "qqsad",
          //   update: true,
          // });
        }}
         target="_blank"
          className="block shrink-0">
          <img
            alt=""
            src={sponsorImage ? sponsorImage : imageHolder}
            // src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8YXZhdGFyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60"
            className="size-14 rounded-lg object-cover"
          />
        </a>

        <div>
          <h3 className="font-bold text-lg sm:text-2xl">
            <a  onClick={() => {
                if (!sponsorId) {
                  return;
                }
                // localStorage.setItem("sponsorItem", JSON.stringify({id :sponsorId}));

                history.push(`/site/sponsor_details?id=${sponsorId}&zIx=890`, {
                  update: true,
                });

                // history.push("/site/sponsor_details", {
                //   itemData: "qqsad",
                //   update: true,
                // });
              }}className="hover:underline">
              <span className="text-sm">event by </span>
              {sponsorName ? sponsorName : "anonymous"}{" "}
            </a>
          </h3>
          <h3 className="font-medium text-xl sm:text-xl">
            <a 
            className="flex text-gray-500 flex-row gap-2 hover:underline">
              <p>{item?.region}</p>
              <p>{item?.district}</p>
              <p>{item?.ward}</p>
              <p>{item?.nameName}</p>
              <p>{item?.physicalResidency}</p>
            </a>
          </h3>

          <p className="line-clamp-2 text-lg text-gray-700">
            {" "}
            {formatDateTimeShort(dateEnd)} to {formatDateTimeShort(dateStart)}
          </p>

          <div className="mt-2 sm:flex sm:items-center sm:gap-2">
            <div className="flex items-center gap-1 text-gray-500">
              {/*
            
            
             mtaa {
        name
        regionData {
          country
          regionName
          districtName
          wardName
          areaName
        }
      }
        
      
      <svg
              xmlns="http://www.w3.org/2000/svg"
              className="size-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
              />
            </svg> */}

              <p  onClick={() => {
                // console.log(banda);
                // console.log(banda);
                // console.log(banda);
                localStorage.setItem("bandaItem", JSON.stringify(item));

                history.push(`/site/banda_details?id=${item?.id}&zIx=890`);
              }} className="text-lg hover:underline">{item?.bandaCode}</p>
            </div>

            <span className="hidden sm:block" aria-hidden="true">
              &middot;
            </span>

            <p  onClick={() => {
                // console.log(banda);
                // console.log(banda);
                // console.log(banda);
                localStorage.setItem("bandaItem", JSON.stringify(item));

                history.push(`/site/banda_details?id=${item?.id}&zIx=890`);
              }}
              className="hidden sm:block sm:text-lg sm:text-gray-500 hover:underline">
              {item?.bandaName}
              {/* <a href="#" className="font-medium underline hover:text-gray-700"> John </a> */}
            </p>
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <strong className="-mb-[2px] -me-[2px] inline-flex items-center gap-1 rounded-ee-xl rounded-ss-xl bg-green-600 px-3 py-1.5 text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="size-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
            />
          </svg>

          <span className="text-[10px] font-medium sm:text-xs">Done</span>
        </strong>
      </div>
    </article>
  );
  return (
    <div>
      <li className="flex justify-between gap-x-6 py-5 px-2">
        <div className="flex min-w-0 gap-x-4">
          <img
            alt=""
            src={sponsorImage ? sponsorImage : imageHolder}
            className="h-12 w-12 flex-none rounded-full bg-gray-50 rounded-full aspect-square object-cover"
          />
          <div className="min-w-0 flex-auto">
            <p
              className="text-sm font-semibold leading-6 text-gray-900"
              onClick={() => {
                if (!sponsorId) {
                  return;
                }
                // localStorage.setItem("sponsorItem", JSON.stringify({id :sponsorId}));

                history.push(`/site/sponsor_details?id=${sponsorId}&zIx=890`, {
                  update: true,
                });

                // history.push("/site/sponsor_details", {
                //   itemData: "qqsad",
                //   update: true,
                // });
              }}
            >
              {sponsorName ? sponsorName : "anonymous"}
            </p>
            <p className="mt-1 truncate text-xs leading-5 text-gray-500">
              {formatDateTimeShort(dateStart)}
            </p>
          </div>
        </div>
        <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
          {/* <p className="text-sm leading-6 text-gray-900">{"role"}</p> */}
        </div>
      </li>
      <Card
        bg={"#f2ebfb"}
        width={"95%"}
        marginLeft={"2.5%"}
        alignSelf={"center"}
        marginBottom={"5px"}
        p="14px"
        borderRadius={"15px"}
        justifyContent={"space-between"}
      >
        <Flex align="center" direction={"row"}>
          <Flex align="center" direction={"row"}>
            <Flex align="center" direction={"column"}>
              <Text
                fontWeight="500"
                color={textColorSecondary}
                fontSize="12px"
                textAlign={"start"}
                marginBottom={"30px"}
                me="4px"
              >
                {formatDateTimeShort(dateStart)}
              </Text>

              <Text
                fontWeight="500"
                color={textColorSecondary}
                fontSize="12px"
                textAlign={"start"}
                me="4px"
              >
                {formatDateTimeShort(dateEnd)}
              </Text>
            </Flex>

            <Flex align="center" direction={"column"}>
              <Icon
                as={IoCalendarOutline}
                width="20px"
                height="20px"
                color="#3c0663"
                //  position={"absolute"}
                //  alignSelf={"end"}
                //  fontSize="50px"
                fontWeight="500"
                //  borderRadius="70px"
                marginRight={"2%"}
                //  mb={"23%"}
              />
              <Box
                width="3px"
                height="50px"
                backgroundColor="#3c0663"
                //  position={"absolute"}
                //  alignSelf={"end"}
                //  fontSize="50px"
                //  borderRadius="70px"
                marginRight={"2%"}
                //  mb={"23%"}
              />
              <Icon
                as={IoCalendarOutline}
                width="20px"
                height="20px"
                color="#3c0663"
                //  position={"absolute"}
                //  alignSelf={"end"}
                //  fontSize="50px"
                fontWeight="500"
                //  borderRadius="70px"
                marginRight={"2%"}
                //  mb={"23%"}
              />
            </Flex>
          </Flex>

          <Box paddingRight={"2px"} display={"flex"} flexDirection={"column"}>
            <Button
              onClick={() => {
                // console.log(banda);
                // console.log(banda);
                // console.log(banda);
                localStorage.setItem("bandaItem", JSON.stringify(item));

                history.push(`/site/banda_details?id=${item?.id}&zIx=890`);
              }}
              variant={item?.eventType === "PRIVATE" ? "" : "link"}
              // color="b"
              alignSelf={"start"}
              // marginLeft={item?.eventType === "PRIVATE" ? "-4%" :"0%"}
              // maxHeight={"50%"}
              // fontWeight="500"
              fontSize="16px"
              // mb="4px"
              // fontWeight="500"
              // borderRadius="70px"
              // px="24px"
              // py="1px"
              // marginRight={"-5%"}
            >
              {" "}
              <Text
                fontWeight="500"
                width={"160px"}
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
                overflow={"hidden"}
                color={"#333"}
                textAlign={"start"}
                fontSize="14px"
                // marginLeft={item?.eventType === "PRIVATE" ? "-4%" :"0%"}

                // me="4px"
              >
                {item?.bandaName}
              </Text>
            </Button>
            <Text
              onClick={() => {
                // console.log(banda);
                // console.log(banda);
                // console.log(banda);
                localStorage.setItem("bandaItem", JSON.stringify(item));

                history.push(`/site/banda_details?id=${item?.id}&zIx=890`);
              }}
              // variant={item?.eventType === "PRIVATE" ? "":"link"}
              // color="b"
              alignSelf={"start"}
              marginLeft={"20px"}
              // fontWeight="500"
              // fontSize="larger"
              // mb="4px"

              fontWeight="500"
              color={item?.eventType === "PRIVATE" ? "gray" : "green.300"}
              fontSize="12px"
              // me="4px"
            >
              {item?.bandaCode}
            </Text>

            <Flex
              align="center"
              direction={"row"}
              marginTop={"10px"}
              marginLeft={"9%"}
            >
              <Box
                mt={{ base: "0px", md: "0" }}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"start"}
                alignContent={"start"}
              >
                <Button
                  onClick={() => {
                    // localStorage.setItem("bandaItem", JSON.stringify(item));
                    // history.push("/site/e_gallery");
                  }}
                  color={"#5cba47"}
                  fontSize="14px"
                  variant={"link"}
                  alignSelf={"start"}
                  // mb="4px"
                  marginLeft={"0%"}
                >
                  123{" "}
                  <Icon
                    as={MdVerifiedUser}
                    width="15px"
                    height="15px"
                    color="#5cba47"
                    //  position={"absolute"}
                    alignSelf={"end"}
                    fontSize="12px"
                    fontWeight="500"
                    borderRadius="70px"
                    //  marginLeft={"50%"}
                    mb={"33%"}
                  />
                </Button>

                {/* <Button
             onClick={() => {
if(!sponsorId){return}
              // localStorage.setItem("sponsorItem", JSON.stringify({id :sponsorId}));
            
              // history.push("/site/sponsor_details", {
              //   itemData: "qqsad",
              //   update: true,
              // });
            
            }}
              fontWeight="500"
              alignSelf={"start"}

              color={"green.300"}
              fontSize="12px"
              me="4px"
              variant={"link"}
              // mb="4px"
              marginLeft={"-1%"}
            > Attendees</Button> */}
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Card>
    </div>
  );
}
