// Chakra imports
import { Icon, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
// Assets
// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { useSponsorDashboardQuery } from "generated/graphql";
import { useEffect } from "react";
import { MdTv, MdVideocam } from "react-icons/md";
import { useSelector } from "react-redux";
import TotalSpent from "views/site/sponsorAnalysis/components/TotalSpent";
import WeeklyRevenue from "views/site/sponsorAnalysis/components/WeeklyRevenue";
import DevelopmentTable from "views/site/sponsorDataTables/components/DevelopmentTable";

export default function SponnsorAnalysis() {
  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const contentData = useSponsorDashboardQuery({
    fetchPolicy: "network-only",
    variables: {
      getSponsorProfileInput: {
        id: user.sponsorId,
      },
    },
  });
 useEffect(() => {
    window.scrollTo(0, 0);  
  }, []);

  return (
    <div className="w-full h-full flex bgx-fuchsia-100 justify-center flex-col items-center ">
      <div className="w-full h-full flex max-w-screen-xl flex-col items-center ">
        {!contentData.loading ? (
          <div className="w-full ">
            <SimpleGrid
              columns={{
                base: 1,
                md: 2,
                lg: 4,
                // "2xl": 6
              }}
              gap="20px"
              mb="20px"
            >
              <MiniStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={
                      <Icon
                        w="32px"
                        h="32px"
                        as={MdVideocam}
                        color={brandColor}
                      />
                    }
                  />
                }
                name="CONTENTS"
                value={contentData?.data?.sponsorDashboard?.content}
                bg="#dec0f1"
              />
              <MiniStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={
                      <Icon w="32px" h="32px" as={MdTv} color={brandColor} />
                    }
                  />
                }
                name="RESERVATIONS"
                value={contentData?.data?.sponsorDashboard?.reservations}
                bg="#5d72ed"
              />
              <MiniStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={
                      <Icon w="32px" h="32px" as={MdTv} color={brandColor} />
                    }
                  />
                }
                name="AMOUNT"
                bg="#92dce5"
                value={`Tsh${contentData?.data?.sponsorDashboard?.amount}`}
              />
              <MiniStatistics
                bg="#ffe047"
                growth="+0%"
                name="PEOPLE"
                value={contentData?.data?.sponsorDashboard?.people}
              />
            </SimpleGrid>

            <SimpleGrid
              columns={{
                base: 1,
                md: 2,
                lg: 2,
                //  "2xl": 3
              }}
              gap="20px"
              mb="20px"
            >
              {" "}
              <TotalSpent />
              <WeeklyRevenue />
            </SimpleGrid>
            <div className=" w-full mt-12 flex max-w-screen-xl flex-col items-center ">
              <DevelopmentTable />
            </div>
          </div>
        ) : (
          <div>
            <div className="flex justify-center mt-20 h-screen p-12">
              <div
                className="animate-spin inline-block w-16 h-16 border-8 border-current border-t-transparent text-violet-700 rounded-full"
                role="status"
                aria-label="loading"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
