import { useEffect, useState } from "react";

// Chakra imports
import {
  Box,
  Flex,
  Grid,
  Text,
  Button,
  useColorModeValue,
  SimpleGrid,
  WrapItem,
  Wrap,
  Card,
  Select,
  Input,
  Icon,
} from "@chakra-ui/react";

// Custom components

// Assets
import Pic1 from "assets/images/imageHolder.png";
import Pic2 from "assets/images/imageHolder.png";
import Pic3 from "assets/images/imageHolder.png";
import Pic4 from "assets/images/haki.jpg";
import Pic5 from "assets/images/imageHolder.png";
import Pic6 from "assets/images/imageHolder.png";
// import Avatar from "assets/img/avatars/Avatar.png";
// import Avatar from "assets/img/avatars/Avatar.png";
// import Avatar from "assets/img/avatars/Avatar.png";
// import Avatar from "assets/img/avatars/Avatar.png";
import { removeCart } from "redux.store/cart/actionCreators";
import { Cart } from "components_app/Cart_Components/Cart";
import { shallowEqual, useSelector } from "react-redux";
import {
  useContentListCountQuery,
  useContentListQuery,
} from "generated/graphql";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import DrawerFilter from "../exploreBanda/components/draw";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { Banner } from "components_app/tangazo/Banner";

export const filtersLabels = [
  { mode: "Afya", label: "Afya" },
  { mode: "Vocational", label: "Vocational" },
  // { mode: "Recently", label: "Recently" },
  { mode: "Legal", label: "Legal" },
  { mode: "Health and Wellness", label: "Health and Wellness" },
  { mode: "Community Development", label: "Community Development" },
  { mode: "Food and Nutrition", label: "Food and Nutrition" },
  { mode: "Entertainment", label: "Entertainment" }, // music and art
  { mode: "Entrepreneurship", label: "Entrepreneurship" },
  { mode: "Sports", label: "Sports" },
  { mode: "Spiritual", label: "Spiritual" },
  { mode: "Education", label: "Education" },
  { mode: "Environment", label: "Environment" },
  { mode: "Technology and ICT", label: "Technology and ICT" },
  { mode: "Others", label: "Others" },
];

export const sampleData2 = [
  {
    id: "asb",
    images: ["../../../assets/images/content.png", "khg"],
    image: Pic1,
    videos: ["hjgj", "fjjj"],
    title: "Elimu ya Afya",
    name: "Mazingira",
    type: "CONTENT",

    owner: "By Balozi ya Ufaransa",
    description: "descriptiongfch",
    body: "Afya ya uzazi, akili na mazingira",
    othersMetaData: ["tracking data"],
    price: 0,
    currency: "gj",
    discount: "5%",
    //add fee
    //status in metadata
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "ansd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic2,
    videos: ["hjgj", "fjjj"],
    title: "Semina ya vijana",
    owner: "Haki Elimu",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Vijana na kujituma",
    othersMetaData: ["tracking data"],
    price: 0,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "an90sdd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic3,
    videos: ["hjgj", "fjjj"],
    title: "Ujasiliamali",
    owner: "Wizara ya Afya",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Kuhusu kujizalishia kipato",
    othersMetaData: ["tracking data"],
    price: 0,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "as2nsdd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic4,
    videos: ["hjgj", "fjjj"],
    title: "Ulinzi shirikishi",
    owner: "Usalama Tanzania",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Wajibu na Haki za Raia katika kudumisha amani",
    othersMetaData: ["tracking data"],
    price: 30000,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "as2n087sdd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic5,
    videos: ["hjgj", "fjjj"],
    title: "Ulinzi shirikishi",
    owner: "Usalama Tanzania",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Wajibu na Haki za Raia katika kudumisha amani",
    othersMetaData: ["tracking data"],
    price: 30000,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },

  {
    id: "as2n875sdd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic6,
    videos: ["hjgj", "fjjj"],
    title: "Ulinzi shirikishi",
    owner: "Usalama Tanzania",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Wajibu na Haki za Raia katika kudumisha amani",
    othersMetaData: ["tracking data"],
    price: 30000,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
];

export default function Marketplace() {
  const [filtering, setFiltering] = useState(true);
  const { carts }: readonly ICart[] | any = useSelector(
    (state: CartsState) => state.carts,
    shallowEqual
  );

  const [page, setPage] = useState(1);
  const [pageTemp, setPageTemp] = useState(1);
  const [pageSize, setPageSize] = useState(12);

  // Handle page number click
  const handlePageClick = (pageNum: number) => {
    setPage(pageNum);
  };

  const [category, setCategory] = useState("all");
  const [sponsoredMode, setSponsoredMode] = useState("all");
  const handleChangeSponsorMOde = (e: any) => {
    setSponsoredMode(e.target.value); // Update selected category state when selection changes
  };
  const handleChange = (e: any) => {
    setCategory(e.target.value); // Update selected category state when selection changes
  };
  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  // State variable for search query
  const [searchQuery, setSearchQuery] = useState("");

  // Handler for updating the search query
  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value);
  };

  const { loading, error, data, refetch } = useContentListQuery({
    fetchPolicy: "network-only",
    variables: {
      getContentsInput: {
        userId: user.id,
        page,
        pageSize,
        category,
        searchQuery: searchQuery,
        sponsoredMode: sponsoredMode,
        // sponsoredMode:sponsoredMode === "all" ? null : sponsoredMode,
      },
    },
  });

  // const contentCounter = useContentListCountQuery({
  //   fetchPolicy: "network-only",
  //   variables: {
  //     getContentsInput: {
  //       userId: user.id,
  //       page,
  //       pageSize,
  //       category,
  //       searchQuery: searchQuery,
  //       sponsoredMode: sponsoredMode,
  //       // sponsoredMode:sponsoredMode === "all" ? null : sponsoredMode,
  //     },
  //   },
  // });

  // Handle the previous page
  const handlePrev = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  // Handle the next page
  const handleNext = () => {
    if (page < data?.contentList?.count / pageSize) {
      setPage(page + 1);
    }
  };

  // console.log(data);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => {
    console.log("information");
    console.log("information");
    return setShow(true);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All categories");

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleCategorySelect = (category: string) => {
    setSelectedCategory(category);
    setDropdownOpen(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted with category:", selectedCategory);
  };
  const [formDataFilter, setFormDataFilter] = useState<{
    [key: string]: boolean;
  }>({});
  const handleChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    setFormDataFilter((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  };

  const [totalCount, setTotalCount] = useState(1); // Total count of items (replace with actual value)
  const totalPages = Math.ceil(totalCount / pageSize); // Total number of pages

  useEffect(() => {
    setTotalCount(data?.contentList?.count);
  }, [data?.contentList?.count]);

  // Dynamically generate page numbers
  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5; // Maximum number of page numbers to show
    const range = 2; // Number of pages to show before and after the current page

    if (totalPages <= maxPagesToShow) {
      // If there are fewer pages than maxPagesToShow, show all pages
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Show first pages
      pageNumbers.push(1);

      // Show page numbers around the current page
      if (page - range > 2) {
        pageNumbers.push("..."); // Add ellipsis for pages before the current page
      }

      for (
        let i = Math.max(page - range, 2);
        i <= Math.min(page + range, totalPages - 1);
        i++
      ) {
        pageNumbers.push(i);
      }

      // Add ellipsis if we are not at the end
      if (page + range < totalPages - 1) {
        pageNumbers.push("...");
      }

      // Show last page
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  const pageNumbers = generatePageNumbers();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data]);

  return (
    <div className="w-full flex justify-center flex-col items-center ">
      <div className="w-full flex max-w-screen-xl flex-col items-center ">
        <div className="w-full max-w-screen-xl">
          {/* filter */}

          <div className="rounded-2xl  w-full  bg-white  mt-12 sm:mt-3 border-1 border-fuchsia-200  ">
            <div className="flex flex-row justify-between p-3">
              <h1 className="font-extrabold text-black p-3 text-4xl">
                Filter and search
              </h1>
              <div className="pr-8">
                {filtering ? (
                  <Button
                    bg={"inherit"}
                    marginBottom={"5px"}
                    marginRight={"-3%"}
                    _hover={{
                      bg: "#333333",
                      marginRight: "0%",
                    }}
                    onClick={() => {
                      setFiltering(false);
                    }}
                  >
                    <Icon
                      as={MdArrowUpward}
                      width="20px"
                      height="20px"
                      color="#333"
                      _hover={{
                        color: "#f2f2f2",
                      }}
                    />
                  </Button>
                ) : (
                  <Button
                    bg={"inherit"}
                    marginRight={"-1%"}
                    marginBottom={"5px"}
                    _hover={{
                      bg: "#1f0033",
                      marginRight: "0%",
                    }}
                    onClick={() => {
                      setFiltering(true);
                    }}
                  >
                    <Icon
                      as={MdArrowDownward}
                      width="20px"
                      height="20px"
                      color="#1f0033"
                      _hover={{
                        color: "#f2f2f2",
                      }}
                    />
                  </Button>
                )}
              </div>
            </div>

            {filtering && (
              <div className="">
                <div className="p-4  flex flex-row   border-b-1 pb-12 border-fuchsia-200">
                  <select
                    name="sponsoredMode"
                    value={sponsoredMode}
                    onChange={handleChangeSponsorMOde}
                    className="px-2 h-12 text-xl rounded-l-lg bg-zinc-50  border-1 border-fuchsia-100   text-gray-900 mb-2 w-[20%]   focus:border-fuchsia-300 focus:outline-none"
                  >
                    {[
                      { mode: "all", label: "All" },
                      { mode: "sponsored", label: "Sponsored" },
                      { mode: "non_sponsored", label: "Not Sponsored" },
                    ].map((cat: any) => (
                      <option key={cat.mode} value={cat.mode}>
                        {cat.label}
                      </option>
                    ))}
                  </select>

                  <input
                    value={searchQuery}
                    onChange={handleSearchChange}
                    name="Search"
                    placeholder="Search here by owner or content title"
                    className="w-full h-12 px-4  text-xl rounded-r-lg bg-zinc-50  border-1 border-fuchsia-100  text-gray-900 focus:border-fuchsia-300 focus:outline-none"
                  />
                </div>
                <div className="rounded-b-2xl pb-6 bg-zinc-50 grid grid-flow-row-dense grid-cols-2 sm:grid-cols-4  grid-rows-2 sm:grid-rows-3 gap-1  sm:gap-2   pt-5 px-8 ">
                  {[{ mode: "all", label: "All" }, ...filtersLabels].map(
                    (filter, index) => (
                      <div key={index}>
                        <div className="flex items-center ">
                          <input
                            id={filter.mode}
                            color="black"
                            name={filter.mode} // Use 'name' instead of 'id' to match state key
                            checked={formDataFilter[filter.mode] || false} // Bind checked to state
                            onChange={handleChangeFilter}
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor={filter.mode}
                            className="ms-2 text-lg font-medium text-gray-900 dark:text-gray-300"
                          >
                            {filter.label}
                          </label>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>

          {/* Banner */}
          <div className="my-8 grid grid-cols-1 sm:grid-cols-2 gap-4 items-stretch">
            <Banner
              title={"Kamata Fursa Twenzetu"}
              description={"Hakimiliki yako hailiki"}
            />
            <Banner
              title={"Ruge Mutahaba Foundation"}
              description={"Valuable sponsors"}
            />
          </div>

        

          <h1 className="font-extrabold text-black p-3 py-4 text-4xl sm:text-7xl">
            Contents
          </h1>
          {!loading ? (
            <div className="bgnm-red-400">
              <SimpleGrid mt={"20px"} columns={{ base: 1, md: 4 }} gap="20px">
                {data?.contentList?.list?.map((cart: any) => {
                  const existingCart = carts.find(
                    (item: ICart) => item.id === cart.id
                  );
                  return (
                    <Cart
                      key={cart.id}
                      cart={cart}
                      removeCart={removeCart}
                      selected={existingCart}
                      handleShow={handleShow}
                    />
                  );
                })}
              </SimpleGrid>

              {data?.contentList?.list?.length > 0 && (
                <div className="flex text-xl mt-12 mb-24 items-center justify-between border-t border-fuchsia-200  px-4 py-3 sm:px-6">
                  <div className="flex flex-1 justify-between sm:hidden">
                    <button
                      onClick={handlePrev}
                      className="relative inline-flex items-center rounded-md border border-fuchsia-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    >
                      Previous
                    </button>
                    <button
                      onClick={handleNext}
                      className="relative ml-3 inline-flex items-center rounded-md border border-fuchsia-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    >
                      Next
                    </button>
                  </div>
                  <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                    <div>
                      <p className="text-xl text-gray-700">
                        Showing{" "}
                        <span className="font-medium">
                          {(page - 1) * pageSize + 1}
                        </span>{" "}
                        to{" "}
                        <span className="font-medium">
                          {Math.min(page * pageSize, totalCount)}
                        </span>{" "}
                        of <span className="font-medium">{totalCount}</span>{" "}
                        results
                      </p>
                    </div>
                    <div>
                      <nav
                        aria-label="Pagination"
                        className=" 
        isolate inline-flex -space-x-px
         rounded-md shadow-sm"
                      >
                        <button
                          onClick={handlePrev}
                          className="
              relative 
              inline-flex 
              items-center 
              rounded-l-md 
              px-2 py-2 
              text-gray-400 
              ring-1 
              ring-inset
               ring-fuchsia-300 
               hover:bg-gray-50 
               focus:z-20 
               focus:outline-offset-0"
                        >
                          <span className="sr-only">Previous</span>
                          <ChevronLeftIcon
                            aria-hidden="true"
                            className="size-5"
                          />
                        </button>

                        {/* Dynamically render page numbers */}
                        {pageNumbers.map((pageNum, index) => (
                          <button
                            key={index}
                            onClick={() => {
                              if (pageNum !== "...") {
                                //@ts-ignore
                                setPage(pageNum);
                              }
                            }}
                            className={`
                  relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-fuchsia-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0
                   ${
                     pageNum === page
                       ? "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                       : ""
                   }`}
                          >
                            {pageNum}
                          </button>
                        ))}

                        <button
                          onClick={handleNext}
                          className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-fuchsia-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                          <span className="sr-only">Next</span>
                          <ChevronRightIcon
                            aria-hidden="true"
                            className="size-5 "
                          />
                        </button>
                      </nav>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="flex justify-center mt-20 h-screen">
              <div
                className="animate-spin inline-block w-16 h-16 border-8 border-current border-t-transparent text-violet-700 rounded-full"
                role="status"
                aria-label="loading"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}

          {data?.contentList?.list?.length <= 0 && (
            <div>
              <h1 className="text-9xl my-6 text-black font-extrabold">
                NO CONTENT !<br />
                TO SHOW
              </h1>
              <p className="text-lg text-black font-base"></p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
