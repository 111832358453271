import { Box, Flex, Select, SimpleGrid, Text } from "@chakra-ui/react";
// import Calendar from "react-calendar";
import "views/site/content_reserve/styles/styles.scss";
import { Input } from "@chakra-ui/react";
import Card from "components/card/Card";
import { useColorModeValue } from "@chakra-ui/react";
// Custom components

import { Button, Link } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import ReservationArea from "components_app/ReservationArea/ReservationArea";
import { AreaBased } from "components_app/AreaBased/AreaBased";
import { useHistory, useLocation } from "react-router-dom";
import regionData from "utils/geo_data.json";

import {
  Banda,
  useFilterAreasQuery,
  useGetBandaByCodePromoQuery,
  useGetContentQuery,
  Event,
} from "generated/graphql";
import {
  addPromo,
  removePromo,
  updatePromo,
} from "redux.store/promo/actionCreators";
import FullCalendar from "@fullcalendar/react";
import { updateCart, removeCart } from "redux.store/cart/actionCreators";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { SelectedBandaUmizaInPromo } from "components_app/SelectedBandaUmizaInPromo/SelectedBandaUmizaInPromo";

let areaQuestionsInit = [
  {
    label: "Municipals",
    choices: [
      { name: "Temeke", checked: false },
      { name: "Ubungo", checked: false },
      { name: "Ilala", checked: false },
      { name: "Kinondoni", checked: false },
      { name: "Kigamboni", checked: false },
    ],
  },
  {
    label: "Size",
    choices: [
      { name: "1 - 50 ", checked: false },
      { name: "51 - 100", checked: false },
      { name: "101 - 200", checked: false },
      { name: "201 - 1000", checked: false },
      { name: "1000+", checked: false },
    ],
  },
  // Add more questions as needed
];
const areaFiltersLabels = [
  { mode: "REGION", label: "REGION" },
  { mode: "DISTRICT", label: "DISTRICT" },
  { mode: "WARD", label: "WARD" },
  { mode: "MTAA", label: "MTAA" },
];

export interface BandaFormData {
  region?: string;
  district?: string;
  ward?: string;
  searchCode?: string;

  mtaaVillage: string;
  districtPostcode: string;
  wardPostcode: string;
}

export default function PromoReserve() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const contentIDd = queryParams.get("id");

  const textColorPrimary = "gray"; //useColorModeValue("secondaryGray.900", "white");

  const dispatch: Dispatch<any> = useDispatch();

  const [formData, setFormData] = useState<BandaFormData>({
    region: "",
    district: "",
    ward: "",
    mtaaVillage: "",
    districtPostcode: "",
    searchCode: "",
    wardPostcode: "",
  });
  const { promo }: readonly ICart[] | any = useSelector(
    (state: PromoState) => state.promo,
    shallowEqual
  );
  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;

    const newValue = type === "checkbox" ? checked : value;

    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  //@ts-ignore
  const [cachedV, setCachedV] = useState(true);

  const [defaultPrice, setDefaultPrice] = useState(0);
  const [defaultPriceBusket, setDefaultPriceBusket] = useState(0);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [banda, setBanda] = useState<Banda>();

  useEffect(() => {
    // Check if there is any 'busket' item in the cart
    const busketExists = promo
      .find((item: ICart) => item.id === contentIDd)
      ?.bandaumizaList?.some((item: any) => item.type === "busket");

    // Update the state variable accordingly
    setIsBusketInCart(busketExists);
  }, [promo.find((item: ICart) => item.id === contentIDd)]);

  useEffect(() => {
    const filteredCart = promo.find((item: ICart) => item.id === itemData.id);

    if (filteredCart) {
      // setReservationData(filteredCart);
      setFirst(false);
    } else {
      // setReservationData(itemData);
      setFirst(false);
    }
  }, []);

  useEffect(() => {
    if (
      !(
        promo.find((item: ICart) => item.id === contentIDd)?.bandaumizaList
          ?.length > 0
      ) &&
      !first
    ) {
      deletePromoCart(promo.find((item: ICart) => item.id === contentIDd));
      localStorage.setItem(
        "reservationDataContentAdd",
        JSON.stringify({ reservationData: {}, update: false })
      );
    } else {
      // setIsBusketFund(fals);
    }
  }, [promo.find((item: ICart) => item.id === contentIDd)]);

  console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");
  console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");
  console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");
  console.log(contentIDd);
  console.log(contentIDd);
  console.log(promo);

  console.log(promo.find((item: ICart) => item.id === contentIDd));

  console.log(
    promo?.find((item: ICart) => item.id === contentIDd)?.bandaumizaList
  );

  console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");
  console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");
  console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");

  const updateCartState = React.useCallback(
    (cart: ICart) => dispatch(updateCart(cart)),
    [dispatch]
  );

  //#Check remove any

  //INITIALIZING DATA
  useEffect(() => {
    if (cachedV) {
      const filteredCart = promo.find((item: ICart) => item.id === contentIDd);

      if (filteredCart) {
        // setReservationData(filteredCart);
        // setReservationData(activeCart.item);
        setFirst(false);
        setCachedV(false);
      } else {
        setFirst(false);
      }
    }
  }, [cachedV, promo]);

  //delete
  useEffect(() => {
    console.log("DELETINGGGG");
    console.log("DELETINGGGG");
    console.log("DELETINGGGG");
    if (
      !(
        promo.find((item: ICart) => item.id === contentIDd)?.bandaumizaList
          ?.length > 0
      ) &&
      !first
    ) {
      deleteCart(promo.find((item: ICart) => item.id === contentIDd));
      localStorage.setItem(
        "reservationDataContentAdd",
        JSON.stringify({ reservationData: {}, update: false })
      );
      localStorage.setItem(
        "activeCartPromo",
        JSON.stringify({ item: {}, isPromo: false })
      );
      setSelectedDate([]);
      // setCalendarEvents([]);
      // setSelectedDate([]);
      // setOpenCalendar(false)
    }
    // else if (((promo.find((item: ICart) => item.id === contentIDd)?.bandaumizaList?.some((item)=>item.type !== "banda"))) && !first) {

    // }
    else {
    }
  }, [promo.find((item: ICart) => item.id === contentIDd)]);

  const updateDateReservations = (value: string, bandaInList: IBanda) => {
    const updatedData = {
      ...bandaInList,
      dates: value,
    };

    console.log(updatedData);

    addToContentBanda(updatedData, updatedData.type);
  };

  const deleteCart = React.useCallback(
    (cart: ICart) => dispatch(removeCart(cart)),
    [dispatch, removeCart]
  );

  const calendarRef = useRef(null);

  const [calendarEvents, setCalendarEvents] = useState([]);

  useEffect(() => {
    setCalendarEvents([]);
    setSelectedDate([]);

    console.log("activeCartPromo");
    console.log("activeCartPromo");
    const activeCart = JSON.parse(localStorage.getItem("activeCartPromo"));
    console.log(activeCart);

    const idExists = promo?.some((item: any) => item?.id === contentIDd);

    // const foundItem = promo.find((item: any) => item?.id !== "");

    const foundCart = activeCart?.item?.bandaumizaList?.find(
      (item: any) =>
        item?.name === banda?.bandaCode || item?.bandaCode === banda?.bandaCode
    );

    console.log("idExists");
    console.log(idExists);
    console.log(
      promo.find((item: ICart) => item.id === contentIDd)?.bandaumizaList
    );
    console.log("activeCart");
    console.log(activeCart);

    console.log("foundItem");
    console.log(contentIDd);

    //if (openCalendar) {

    if (banda?.eventsFunds?.length > 0) {
      // console.log("Banda:", banda);
      console.log("Events Funds:", banda?.eventsFunds);
      // const filteredArray = selectedDate.filter(
      //   (item: any) => item.color === "orange"
      // );

      console.log(
        "Choosen :",
        JSON.parse(
          promo
            ?.find((item: ICart) => item.id === contentIDd)
            ?.bandaumizaList?.find((item: ICart) => item.id === banda.id)?.dates
            ? promo
                ?.find((item: ICart) => item.id === contentIDd)
                ?.bandaumizaList?.find((item: ICart) => item.id === banda.id)
                ?.dates
            : "[]"
        )
      );

      const allScheduledData = banda?.eventsFunds?.map((eventt: Event) => {
        if (!(eventt?.startDateTime === "") && !(eventt?.endDateTime === "")) {
          return {
            id: eventt.id,
            title: eventt.title,
            start: eventt.startDateTime,
            end: eventt.endDateTime,
            fixed: true,
            color: JSON.parse(
              promo
                ?.find((item: ICart) => item.id === contentIDd)
                ?.bandaumizaList?.find((item: ICart) => item.id === banda.id)
                ?.dates
                ? promo
                    ?.find((item: ICart) => item.id === contentIDd)
                    ?.bandaumizaList?.find(
                      (item: ICart) => item.id === banda.id
                    )?.dates
                : "[]"
            )?.some((rev: ICart) => rev.id === eventt.id)
              ? "orange"
              : "#2d6a4f",
            // color:
            //   eventt.eventType === "FUNDED"
            //     ? "#2d6a4f"
            //     : eventt.eventType === "PRIVATE"
            //     ? "#468faf"
            //     : "",
          };
        }
      });

      const initialEventsDB = allScheduledData?.filter(
        (item) => item !== undefined
      );

      const foundCartDt = JSON.parse(
        foundCart?.dates ? foundCart?.dates : JSON.stringify([])
      );

      if (idExists) {
        console.log("DONT EXIST");
        console.log("DONT EXIST");
        console.log("DONT EXIST");
        console.log(foundCartDt);
        console.log(initialEventsDB);
        setSelectedDate(initialEventsDB);
      } else {
        console.log("RUNNNING");
        console.log("RUNNNING");
        console.log("RUNNNING");
        console.log("RUNNNING");
        console.log("RUNNNING");
        console.log(foundCartDt);
        console.log(initialEventsDB);
        setSelectedDate(foundCartDt);

        if (foundCartDt.length < 1) {
          setSelectedDate(initialEventsDB);
        }
      }
    }
  }, [openCalendar]);

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => setIsOpen(!isOpen);

  const [selectedDate, setSelectedDate] = useState([]);
  // const [selectedDateFound, setSelectedDateFound] = useState([]);

  const handleSelect = (arg: any) => {
    if (
      true
      // window.confirm(
      //   `Do you want to add an event at ${arg.startStr} to ${arg.endStr}?`
      // )
    ) {
      setSelectedDate([
        ...selectedDate,
        {
          title: "",
          start: arg.startStr,
          fixed: false,
          end: arg.endStr,
          id: arg.startStr + arg.endStr,
        },
      ]);
    }
    calendarRef.current.getApi().unselect();
  };

  const handleEventClick = (arg: any) => {
    console.log("CLICKED EVENT");
    console.log("CLICKED");
    console.log("CLICKED");
    // console.log(selectedDate);
    const isoDateStart = new Date(arg?.event?.startStr).toISOString();
    const isoDateEnd = new Date(arg?.event?.endStr).toISOString();

    console.log(arg?.event?.startStr);
    console.log(arg?.event?.endStr);
    // console.log(arg?.event);
    console.log(arg?.event?.title);
    console.log(isoDateStart);
    console.log(isoDateEnd);
    console.log(selectedDate);

    const selectionStart = new Date(isoDateStart);
    const selectionEnd = new Date(isoDateEnd);
    const now = new Date();

    // Log the selection info for debugging
    // console.log(`Selection start: ${selectionStart}, end: ${selectionEnd}`);
    // console.log(`Current time: ${now}`);

    // Check if the selection starts or ends in the past
    if (selectionStart < now || selectionEnd < now) {
      console.log("Selection is in the past");
      return false;
    }

    // calendarRef.current.getApi().unselect();

    //check if event exist....
    //grab time and other info....
    //publish to the db

    const exists = selectedDate.some(
      (event) =>
        event?.start + event?.end === // (isoDateStart + isoDateEnd) ||
        arg?.event?.startStr + arg?.event?.endStr
    );

    console.log("exists");
    console.log(exists);
    console.log("exists");

    if (exists) {
      const updatedDate = selectedDate.map((event) =>
        event?.start + event?.end === // (isoDateStart + isoDateEnd) ||
        arg?.event?.startStr + arg?.event?.endStr
          ? {
              ...event,
              color:
                event?.color === "orange"
                  ? "#2d6a4f"
                  : event?.color === "#2d6a4f"
                  ? "orange"
                  : "#2d6a4f",
            }
          : event
      );

      setSelectedDate(updatedDate);

      console.log(selectedDate);

      return;
    }
  };

  // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));

  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const storedCartItemString = localStorage.getItem("eventItem");
  const storedPromoString = localStorage.getItem("cartPromo");

  // Convert the string back to an object
  const storedCartItem = JSON.parse(storedCartItemString);
  const storedPromo = JSON.parse(storedPromoString);
  const itemData = storedCartItem;
  // Now, storedCartItem contains the original object
  // console.log(storedCartItem);
  //   // console.log(localStorage.getItem("eventItem") );
  //   console.log(location.state);
  //   console.log("########???????????");
  //   console.log("########???????????");
  //   console.log("########???????????");
  //   console.log("########???????????");

  // const navigate = useNavigate();
  // const saveCurrentRoute = React.useCallback(
  //   (navData: INavigate) => dispatch(navigateThePage(navData)),
  //   [dispatch]
  // );
  const navigateThroughLinks = (navData: INavigate) => {
    // saveCurrentRoute(navData);
    // navigate(`/${navData.currentPage}`);
  };

  const saveToThePromo = React.useCallback(
    (promo: ICart) => dispatch(addPromo(promo)),
    [dispatch]
  );

  const updatePromoState = React.useCallback(
    (promo: ICart) => dispatch(updatePromo(promo)),
    [dispatch]
  );

  //#Check remove any
  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  // console.log(events);

  //writing on the state

  //INITIALIZING DATA
  useEffect(() => {
    if (cachedV) {
      const filteredCart = promo.find(
        (item: ICart) => item.id === storedCartItem.id + "_" + storedPromo.id
      );

      if (filteredCart) {
        // setReservationData(filteredCart);
        // setReservationData(activeCart.item);
        setFirst(false);
        setCachedV(false);
      } else {
        setFirst(false);
      }
    }
  }, [cachedV, promo, storedCartItem, storedPromo]);

  const [first, setFirst] = useState(true);
  const [specifyArea, setSpecifyArea] = useState(false);
  const [specifyBanda, setSpecifyBanda] = useState(false);

  const [selectedDates, setSelectedDates] = useState<Date[] | null>(null);
  const handleDateChange = (date: Date | Date[]) => {
    setSelectedDates(date instanceof Date ? [date] : date);
  };

  // Change handler for the default price field
  const handleDefaultPriceChange = (e: any) => {
    setDefaultPriceBusket(e.target.value);
  };

  const [isBusketFund, setIsBusketFund] = useState(false);

  const [defaultFrequency, setDefaultFrequency] = useState(1);

  // State variable for search query
  const [searchQuery, setSearchQuery] = useState("");

  // Handler for updating the search query
  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value);
  };
  const isIdAlreadyExists = (data: string) => {
    return promo
      .find((item: ICart) => item.id === contentIDd)
      ?.bandaumizaList?.some((item: any) => item.id === data);
  };

  const [isBusketInCart, setIsBusketInCart] = useState(false);
  const [selectedAreaModeState, setSelectedAreaModState] = useState("REGION");

  const [page, setPage] = useState(1);
  const [pageTemp, setPageTemp] = useState(1);
  const [pageSize, setPageSize] = useState(4);
  const [category, setCategory] = useState("AB");

  const areaDataFetched = useFilterAreasQuery({
    fetchPolicy: "network-only",
    variables: {
      feedInputData: {
        userId: user.id,
        page,
        pageSize,
        category: "REGION",
      },
    },
  });

  //Adds area and banda to the cart
  const addToContentBanda = (data: any, typeIn: string, eventId?: string) => {
    console.log("$$$$$$$$$$$$$$$$$$$$$$$");
    console.log("$$$$$$$$$$$$$$$$$$$$$$$");
    console.log("$$$$$$$$$$$$$$$$$$$$$$$");
    console.log(typeIn);
    console.log(data);
    console.log("DATES DATES DATES");
    console.log(data.dates);
    console.log(storedPromo);
    console.log(storedCartItem);

    // if(typeIn === "banda" && data.dates.length < 1 ){
    //   return
    // }

    const newBanda = {
      id: data.id,
      eventId: eventId,
      dates: data.dates,

      name: data.bandaCode ? data.bandaCode : data.name,
      title: data.bandaCode ? storedPromo.title : storedPromo.title,
      type: typeIn,
      price: data.price ? data.price : 12000,
      amount: data.price ? data.price : 12000,
      frequency: data.frequency ? data.frequency : 1,
      body: storedCartItem.title,
      capacity: data.capacity,
      currency: "Tsh",
      image: data.image,
      district: data.district,
      region: data.region,
      ward: data.ward,
    };

    const cartExists = promo.find(
      (item: any) => item.id === storedCartItem.id + "_" + storedPromo.id
    );
    const isCartItemAdded = cartExists?.bandaumizaList?.find(
      (banda: IBanda) =>
        banda.id === data.id ||
        (data.region === banda.region &&
          data.district === banda.district &&
          data.ward === banda.ward &&
          data.name === banda.name)
    );

    if (!cartExists && !isCartItemAdded) {
      console.log("NOT ADDED BEFORE , ADDING NOW");
      // console.log("run 3 times ???");

      // return
      // Compute the updated list first
      const updatedBandaumizaList = Array.isArray(
        promo.find((item: ICart) => item.id === contentIDd)?.bandaumizaList
      )
        ? [
            ...promo.find((item: ICart) => item.id === contentIDd)
              ?.bandaumizaList,
            typeIn === "busket" ? newBanda : newBanda,
          ]
        : [typeIn === "busket" ? newBanda : newBanda];

      // Create updated reservation data object
      const updatedData = {
        ...promo.find((item: ICart) => item.id === contentIDd),
        bandaumizaList: updatedBandaumizaList,
      };

      console.log("testtttttttingggggg");
      console.log("testtttttttingggggg");
      console.log(updatedData);
      console.log("testtttttttingggggg");
      addToCart(updatedData);
      setOpenCalendar(false);
      return;
    }
    if (
      cartExists &&
      !isCartItemAdded
      //  ||
      // !isCartItemAdded
    ) {
      console.log("Banda already exists in the content. Not adding it again.");
      console.log("run yettt times ???");

      const isCartItemAdded = promo
        .find((item: ICart) => item.id === contentIDd)
        ?.bandaumizaList?.find(
          (bandaUmiza: IBanda) => bandaUmiza.id === data.id
        );

      //return
      if (isCartItemAdded) {
        return;
      }
      //@ts-ignore
      let updatedData;

      if (typeIn !== "busket") {
        updatedData = newBanda;
      } else {
        updatedData = newBanda;
      }
      console.log("updatedData!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      console.log("updatedData!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      console.log(updatedData);
      console.log(contentIDd);
      console.log(promo);

      let tempList = [
        ...(promo?.find((item: ICart) => item?.id === contentIDd)
          ?.bandaumizaList
          ? promo?.find((item: ICart) => item?.id === contentIDd)
              ?.bandaumizaList
          : []),
        updatedData,
      ];

      console.log(tempList);

      const updatedDataNew = {
        ...promo?.find((item: ICart) => item?.id === contentIDd),
        bandaumizaList: tempList, // updatedDataNewList
      };

      console.log("updatedDataNew##################################");
      console.log("updatedDataNew#################################");
      console.log(updatedDataNew);
      console.log(updatedDataNew?.bandaumizaList);
      console.log("updatedDataNew#################################");
      console.log("updatedDataNew#################################");

      addToCart(updatedDataNew);

      setOpenCalendar(false);
      return;
    }

    if (cartExists && isCartItemAdded) {
      console.log("Item exists. edit the individual part it now");

      const isCartItemAdded = promo
        .find((item: ICart) => item.id === contentIDd)
        ?.bandaumizaList?.find(
          (bandaUmiza: IBanda) => bandaUmiza.id === data.id
        );

      //return

      //@ts-ignore
      let updatedData: IBanda;

      if (typeIn !== "busket") {
        updatedData = newBanda;
      } else {
        updatedData = newBanda;
      }
      console.log("updatedData!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      console.log("updatedData!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      console.log(updatedData);

      const updatedDataNewList = promo
        ?.find((item: ICart) => item?.id === contentIDd)
        ?.bandaumizaList?.map((item: IBanda) => {
          if (item?.id === data?.id) {
            return updatedData;
          } else {
            return item;
          }
        });

      console.log(updatedDataNewList);

      const updatedDataNew = {
        ...promo.find((item: ICart) => item.id === contentIDd),
        bandaumizaList: updatedDataNewList,
      };

      console.log("updatedDataNew##################################");
      console.log("updatedDataNew#################################");

      addToCart(updatedDataNew);

      setOpenCalendar(false);
      return;
    }
    return;
  };

  const { loading, error, data, refetch } = useGetBandaByCodePromoQuery({
    fetchPolicy: "network-only",
    variables: {
      feedInputPromoData: {
        userId: user.id,
        page,
        pageSize,
        category: searchQuery,
        contentId: storedCartItem.id,
      },
    },
  });

  const [step, setStep] = useState(1);

  const selectedPriceSevice = (price: number, data: any) => {
    const updatedData = {
      ...data,
      price: price,
      amount: price,
    };
    addToContentBanda(updatedData, updatedData.type);
  };

  const removeFromList2 = (data: any) => {
    console.log("dataa removing promo item");
    console.log("dataa removing promo item");
    console.log("dataa removing promo item");
    console.log("dataa removing promo item");
    console.log("dataa removing promo item");
    console.log("dataa removing promo item");
    console.log(data);
    console.log("dataa removing promo item");
    console.log("dataa removing promo item");
    console.log("dataa removing promo item");
    console.log("dataa removing promo item");

    // Check if the item with the specified id exists in bandaumizaList
    const isBandaFound = promo
      ?.find((item: ICart) => item.id === contentIDd)
      .bandaumizaList?.some(
        //@ts-ignore
        (banda) =>
          banda.id === data.id ||
          (data.region === banda.region &&
            data.district === banda.district &&
            data.ward === banda.ward &&
            data.name === banda.name)
      );

    console.log(isBandaFound);
    console.log(isBandaFound);
    console.log(isBandaFound);
    console.log(isBandaFound);

    if (isBandaFound) {
      // If the item exists, create a new array without the item to be removed
      const updatedBandaumizaList = promo
        ?.find((item: ICart) => item.id === contentIDd)
        ?.bandaumizaList?.filter(
          //@ts-ignore
          (banda) =>
            banda.id !== data.id ||
            !(
              data.region === banda.region &&
              data.district === banda.district &&
              data.ward === banda.ward &&
              data.name === banda.name
            )
        );

      console.log(updatedBandaumizaList);

      // setReservationData((prevReservationData) => ({
      //   ...prevReservationData,
      //   bandaumizaList: updatedBandaumizaList,
      // }));

      console.log(`Banda with id ${data.id} removed from the content.`);

      const updatedData = {
        ...promo?.find((item: ICart) => item.id === contentIDd),
        bandaumizaList: updatedBandaumizaList,
      };

      // Update state with the updated data
      //@ts-ignore
      // setReservationData(updatedData);
      addToCart(updatedData);
    } else {
      console.log(`Banda with id ${data.id} not found in the content.`);
      console.log(promo?.find((item: ICart) => item.id === contentIDd));
      deleteCart(promo?.find((item: ICart) => item.id === contentIDd));
    }
  };

  const removeFromList = (data: any) => {
    // Check if the item with the specified id exists in bandaumizaList
    const isBandaFound = promo
      .find((item: ICart) => item.id === contentIDd)
      .bandaumizaList?.some(
        //@ts-ignore
        (banda) => banda.id === data.id
      );

    // console.log(isBandaFound);
    // console.log(isBandaFound);
    // console.log(isBandaFound);
    // console.log(isBandaFound);

    if (isBandaFound) {
      // If the item exists, create a new array without the item to be removed
      const updatedBandaumizaList = promo
        ?.find((item: ICart) => item.id === contentIDd)
        ?.bandaumizaList?.filter(
          //@ts-ignore
          (banda) => banda.id !== data.id
        );

      console.log(updatedBandaumizaList);

      // setReservationData((prevReservationData) => ({
      //   ...prevReservationData,
      //   bandaumizaList: updatedBandaumizaList,
      // }));

      console.log(`Banda with id ${data.id} removed from the content.`);

      const updatedData = {
        ...promo?.find((item: ICart) => item.id === contentIDd),
        bandaumizaList: updatedBandaumizaList,
      };

      console.log(updatedData);

      addToCart(updatedData);
    } else {
      console.log(`Banda with id ${data.id} not found in the content.`);
      console.log(promo?.find((item: ICart) => item.id === contentIDd));
      deleteCart(promo?.find((item: ICart) => item.id === contentIDd));
    }
  };

  const getFrequency = (frequencyIn: number, data: IBanda) => {
    // console.log(frequencyIn);
    // console.log(frequencyIn);
    console.log(frequencyIn);
    const updatedData = {
      ...data,
      frequency: frequencyIn,
    };
    console.log(updatedData);

    addToContentBanda(updatedData, updatedData.type);
  };

  const isPromoted = (value: boolean, data: IBanda) => {
    console.log("isPromoted");
    console.log(value);
    console.log(value);
    console.log("isPromoted");

    const updatedData = {
      ...data,
      isPromoted: value,
    };
    addToContentBanda(updatedData, updatedData.type);
  };

  const addToCart = async (dataIn?: ICart) => {
    console.log("################################");
    console.log("################################");
    let inData: ICart = {
      ...promo.find((item: ICart) => item.id === contentIDd),
      price: defaultPrice,
      id: storedCartItem.id + "_" + storedPromo.id,
      frequency: defaultFrequency,
      currency: "Tsh",
      title: storedCartItem.title,
      activeCart: true,
      body: storedPromo.title,
      userId: user.id,
      duration: 30 / 3600,
      promoId: storedPromo.id,
      isPromo: true,
      bandaumizaList: dataIn?.bandaumizaList,
      categories: contentData?.data?.getContent?.categories.map(
        (category) => category.name
      ),

      // title: storedCartItem.title,
      // type: type,
      //   description: storedCartItem.description,
      // body: storedCartItem.body,
      // contentCost: "",
      //  location: "",
    };

    const notSavedBefore =
      promo?.find((item: ICart) => item.id === contentIDd) === undefined;

    console.log(notSavedBefore);
    console.log(inData);
    console.log(inData.activeCart);

    if (notSavedBefore && inData.activeCart) {
      console.log("save");
      console.log("save");
      console.log("save");
      console.log("save");
      console.log("save");
      console.log("save");
      console.log("save");
      console.log("save");
      console.log("save");
      console.log("save");
      console.log("save");
      console.log("save");
      console.log("save");
      console.log("save");
      console.log("save");
      console.log("save");
      console.log("save");
      setFirst(true);

      saveToThePromo(inData);

      localStorage.setItem(
        "activeCart",
        JSON.stringify({ item: inData, isPromo: true })
      );
    } else {
      //update it
      console.log("update");
      console.log("update");
      console.log("update");
      console.log("update");
      // return
      if (
        //activeCartReservation.update ||
        true
      ) {
        updatePromoState(inData);

        localStorage.setItem(
          "activeCart",
          JSON.stringify({ item: inData, isPromo: true })
        );
      }
    }
  };

  const selectedAreaMode = (data: string) => {
    // console.log(data);
    setSelectedAreaModState(data);
  };
  const history = useHistory();
  useEffect(() => {
    // Check if there is any 'busket' item in the cart
    const busketExists = promo
      .find((item: ICart) => item.id === contentIDd)
      ?.bandaumizaList?.some((item: any) => item.type === "busket");

    // Update the state variable accordingly
    setIsBusketInCart(busketExists);
  }, [promo.find((item: ICart) => item.id === contentIDd)]);

  useEffect(() => {
    const filteredCart = promo.find((item: ICart) => item.id === itemData.id);

    if (filteredCart) {
      // setReservationData(filteredCart);
      setFirst(false);
    } else {
      // setReservationData(itemData);
      setFirst(false);
    }
  }, []);

  const deletePromoCart = React.useCallback(
    (cart: ICart) => dispatch(removePromo(cart)),
    [dispatch, removePromo]
  );

  useEffect(() => {
    if (
      !(
        promo.find((item: ICart) => item.id === contentIDd)?.bandaumizaList
          ?.length > 0
      ) &&
      !first
    ) {
      deletePromoCart(promo.find((item: ICart) => item.id === contentIDd));
      localStorage.setItem(
        "reservationDataContentAdd",
        JSON.stringify({ reservationData: {}, update: false })
      );
    } else {
      // setIsBusketFund(fals);
    }
  }, [promo.find((item: ICart) => item.id === contentIDd)]);

  const contentData = useGetContentQuery({
    fetchPolicy: "cache-first",
    variables: {
      getContentInput: {
        contentId: contentIDd,
      },
    },
  });

  // console.log("contentData");
  // console.log(contentData);
  // console.log("contentData");

  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <div className="container mx-auto mb-5">
          {true && (
            <div
              className={"rounded-xl h-min "}
              style={{
                backgroundColor: "#10002b",
                height: "fit-content",
                padding: 0,
              }}
            >
              <div className=" p-4 mb-5">
                <Text
                  color={"white"}
                  fontWeight="bold"
                  fontSize="16px"
                  mt="10px"
                  mb="4px"
                >
                  {storedPromo?.title} on {storedCartItem?.title}
                  {/* {contentData?.data?.getContent?.title?.toUpperCase()} */}
                </Text>
                <Text
                  color={"white"}
                  fontWeight="bold"
                  fontSize="12px"
                  mt="10px"
                  mb="4px"
                >
                  {contentData?.data?.getContent?.author?.username}
                </Text>

                {/* <Text
                  color={textColorPrimary}
                  fontWeight="bold"
                  fontSize="12px"
                  mt="10px"
                  mb="4px"
                >
                  Content Amount{" : "}
                  {contentData?.data?.getContent?.amount}{" "}
                  {contentData?.data?.getContent?.currency}
                </Text> */}
                <Text
                  color={textColorPrimary}
                  fontWeight="bold"
                  fontSize="12px"
                  mt="10px"
                  mb="4px"
                >
                  Content Amount{" : "}
                  {storedCartItem?.amount} {storedCartItem?.currency}
                </Text>
              </div>

              <ol className="flex   sm:px-3 sm:p-4 space-x-0 sm:space-x-4   text-sm font-medium text-center text-gray-500 bg-white border border-gray-100 rounded-xl shadow-lg sm:text-base ">
                <li
                  onClick={() => setStep(1)}
                  className={` ${
                    step === 1 ? "text-blue-600 dark:text-blue-500" : ""
                  }`}
                >
                  <button
                    onClick={() => setStep(1)}
                    className=" flex items-center"
                  >
                    <span
                      className={`  flex items-center justify-center w-5 h-5  text-xs border rounded-full shrink-0 ${
                        step === 1
                          ? "border-blue-600 dark:border-blue-500"
                          : "border-gray-500 dark:border-gray-400"
                      }`}
                    >
                      1
                    </span>
                    <span className="hidden sm:visible  sm:inline-flex  sm:ms-2">
                      Add
                    </span>
                    <span className="collapse sm:visible  m-0 ml-2 p-0 text-start">
                      Banda
                    </span>
                    <svg
                      className="collapse sm:visible w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 12 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m7 9 4-4-4-4M1 9l4-4-4-4"
                      />
                    </svg>
                  </button>
                </li>
                <li
                  onClick={() => setStep(2)}
                  className={`-mr-5 sm:mr-auto ${
                    step === 2 ? "text-blue-600 dark:text-blue-500" : ""
                  }`}
                >
                  <button
                    onClick={() => setStep(2)}
                    className="flex items-center"
                  >
                    <span
                      className={`flex items-center justify-center w-5 h-5  text-xs border rounded-full shrink-0 ${
                        step === 2
                          ? "border-blue-600 dark:border-blue-500"
                          : "border-gray-500 dark:border-gray-400"
                      }`}
                    >
                      2
                    </span>
                    <span className="hidden sm:inline-flex sm:ms-2">Area </span>

                    <svg
                      className="hidden sm:visible w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 12 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m7 9 4-4-4-4M1 9l4-4-4-4"
                      />
                    </svg>
                  </button>
                </li>

                {false && (
                  <li
                    onClick={() => setStep(3)}
                    className={`-mr-5 sm:mr-auto  ${
                      step === 3 ? "text-blue-600 dark:text-blue-500" : ""
                    }`}
                  >
                    <button
                      onClick={() => setStep(3)}
                      className="flex items-center"
                    >
                      <span
                        className={`flex items-center justify-center w-5 h-5 me-2 text-xs border rounded-full shrink-0 ${
                          step === 3
                            ? "border-blue-600 dark:border-blue-500"
                            : "border-gray-500 dark:border-gray-400"
                        }`}
                      >
                        3
                      </span>
                      <span className="hidden sm:inline-flex sm:ms-2">
                        Fund
                      </span>

                      <svg
                        className="hidden sm:visible w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 12 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m7 9 4-4-4-4M1 9l4-4-4-4"
                        />
                      </svg>
                    </button>
                  </li>
                )}

                <li
                  onClick={() => setStep(3)}
                  className={`-mr-5 sm:mr-auto  ${
                    step === 4 ? "text-blue-600 dark:text-blue-500" : ""
                  }`}
                >
                  <button
                    onClick={() => setStep(3)}
                    className="flex items-center"
                    //       disabled={!promo.find(
                    //   (item: ICart) => item.id === contentIDd
                    // )}
                  >
                    <span
                      className={`flex items-center justify-center w-5 h-5 me-2 text-xs border rounded-full shrink-0 ${
                        step === 3
                          ? "border-blue-600 dark:border-blue-500"
                          : "border-gray-500 dark:border-gray-400"
                      }`}
                    >
                      3
                    </span>
                    Review
                  </button>
                </li>
              </ol>
            </div>
          )}
        </div>

        <div className="container mx-auto p-4 rounded-xl">
          <div className="mt-4">
            {step === 1 && (
              <div className=" p-4 rounded-lg shadow-lg  space-y-4 bg-white my-5 ">
                <h2>Choose Banda by Code</h2>
                <p>
                  {
                    promo
                      .find((item: ICart) => item.id === contentIDd)
                      ?.bandaumizaList?.filter(
                        (item: any) => item.type === "banda"
                      ).length
                  }{" "}
                  banda selected
                </p>

                <Link
                  onClick={(e) => {
                    setStep(step + 1);
                  }}
                  color="green"
                  variant={"link"}
                  _hover={{ bg: "#f1f1f1" }}
                  _active={{ bg: "white" }}
                  _focus={{ bg: "white" }}
                  fontWeight="500"
                  fontSize="14px"
                  position={"absolute"}
                  right={60}
                >
                  Next
                </Link>

                <div
                  style={{
                    // position: "sticky",
                    marginTop: 10,
                    right: 0,
                    // maxHeight: 800, // Use viewport height for full height
                    width: "100%",
                    // marginLeft: "2%",
                    // backgroundColor: "#6b9080",
                    // overflowY: "auto",
                    // padding: "20px",
                  }}
                >
                  <Input
                    type="text"
                    value={formData.searchCode}
                    onChange={handleChange}
                    variant={"auth"}
                    placeholder="Search banda by code"
                    marginTop={"30px"}
                    height={"40px"}
                    name="searchCode"
                    style={{
                      // borderColor:"#eef0f2",
                      border: "1px solid #eef0f2",
                      // position:"relative",
                      color: "#333",
                      width: "100%",
                    }}
                  />

                  <Flex
                    mt={"20px"}
                    // maxHeight={"800px"}
                    flexDirection={"column"}
                    width={"100%"}
                    // overflowX={{ sm: "scroll", lg: "hidden" }}
                  >
                    {formData?.searchCode?.length >= 3 &&
                      data?.getBandaByCodePromo?.map((item: any) => {
                        const itemExists = isIdAlreadyExists(item.id);

                        return (
                          <div
                            style={{
                              height: 125,
                              width: "100%",

                              backgroundColor: "#284b63",
                              marginBottom: 5,
                              borderRadius: 5,
                              display: "flex",
                              flexDirection: "row",
                            }}
                            key={item.id}
                          >
                            <div
                              style={{
                                width: "75%",
                                paddingLeft: 10,
                                // backgroundColor:"red",
                                paddingTop: 2,
                              }}
                            >
                              {" "}
                              <p
                                style={{
                                  padding: 0,
                                  fontSize: 16,
                                  color: "#d6d6d6",
                                }}
                              >
                                {item.title}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  color: "#d6d6d6",
                                }}
                              >
                                {" "}
                                {item.bandaName}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  color: "#d6d6d6",
                                }}
                              >
                                {item.bandaCode}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  color: "#d6d6d6",
                                  fontSize: 13,
                                }}
                              >
                                {item.region} {item.district} {item.ward}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  fontSize: 12,
                                  color: "#d6d6d6",
                                }}
                              >
                                {" "}
                                {item.physicalResidency}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  fontWeight: "700",
                                  color: "gray",
                                }}
                              >
                                {item.currency}
                                {item.priceHour} per hr
                              </p>
                            </div>

                            <div
                              style={{
                                // marginLeft: "60%",
                                // backgroundColor:"green",
                                width: "25%",
                                // justifyContent:"center",
                                // alignContent:"center",
                                // display:"flex",
                              }}
                            >
                              <div
                                style={{
                                  // marginLeft: "60%",
                                  // backgroundColor:"green",
                                  width: "25%",
                                  // justifyContent:"center",
                                  // alignContent:"center",
                                  // display:"flex",
                                }}
                              >
                                {itemExists ? (
                                  <button
                                    onClick={() => {
                                      // setCalendarEvents([]);
                                      // setSelectedDate([]);
                                      setOpenCalendar(true);
                                      setIsOpen(true);
                                      setBanda(item);
                                    }}
                                    style={{
                                      backgroundColor: "#6b9080",
                                      width: "80px",
                                      height: 30,
                                      padding: 5,
                                      color: "white",
                                      // marginRight: "3%",
                                      marginTop: "30%",
                                      marginLeft: "6%",
                                      fontSize: 12,
                                      // fontWeight: "bold",
                                      borderRadius: 5,
                                    }}
                                  >
                                    Edit
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => {
                                      setCalendarEvents([]);
                                      setSelectedDate([]);
                                      setOpenCalendar(true);
                                      setIsOpen(true);
                                      setBanda(item);
                                    }}
                                    style={{
                                      backgroundColor: "#04a777",
                                      width: "80px",
                                      marginTop: "30%",
                                      height: 30,
                                      color: "white",
                                      // marginRight: "3%",
                                      // fontWeight: "bold",
                                      marginLeft: "10%",
                                      fontSize: 12,
                                      borderRadius: 5,
                                    }}
                                  >
                                    Choose event
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </Flex>
                </div>

                {promo
                  ?.find((item: ICart) => item.id === contentIDd)
                  ?.bandaumizaList?.filter((item: any) => item.type === "banda")
                  ?.map((cart: any) => (
                    <SelectedBandaUmizaInPromo
                      key={cart.id}
                      cart={cart}
                      removeCart={removeCart}
                      removeFromList={removeFromList}
                      selectedFrequency={getFrequency}
                      isPromoted={isPromoted}
                      defSelectedPrice={cart.price}
                      selectedPrice={selectedPriceSevice}
                    />
                  ))}
              </div>
            )}

            {step === 2 && (
              <div className=" p-4 rounded-lg shadow-lg md:p-5 space-y-4 bg-white my-5 overflow-auto">
                {/* <h2>Step 2: Specify Area</h2>
     <p>Select the area for the Banda.</p> */}
                <p>
                  {
                    promo
                      .find((item: ICart) => item.id === contentIDd)
                      ?.bandaumizaList?.filter(
                        (item: any) => item.type === "ward"
                      ).length
                  }{" "}
                  Areas selected
                </p>

                <div className="p-4 md:p-5 space-y-4  overflow-auto">
                  {/* <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                 With less than a month to go before the European Union enacts new consumer privacy laws for its citizens, companies around the world are updating their terms of service agreements to comply.
               </p>
               <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                 The European Union’s General Data Protection Regulation (G.D.P.R.) goes into effect on May 25 and is meant to ensure a common set of data rights in the European Union. It requires organizations to notify users as soon as possible of high-risk data breaches that could personally affect them.
               </p> */}
                  <Link
                    onClick={(e) => {
                      setStep(step + 1);
                    }}
                    color="green"
                    variant={"link"}
                    _hover={{ bg: "#f1f1f1" }}
                    _active={{ bg: "white" }}
                    _focus={{ bg: "white" }}
                    fontWeight="500"
                    fontSize="14px"
                    position={"absolute"}
                    right={60}
                  >
                    Next
                  </Link>

                  {
                    <div
                    //flexDirection="column" w="100%" px="0px"
                    // bg={"red"}
                    //borderRadius={"5px"}
                    // marginTop={"20px"}
                    //  height={"400px"}
                    //  overflowY={"auto"}
                    // bg={"red"}
                    >
                      <div className="flex flex-col sm:flex-row">
                        {
                          // specifyArea ||
                          true && (
                            <Flex
                              flexDirection={"column"}
                              // maxHeight={"300px"}
                              width={"100%"}
                              // height: 100, // Use viewport height for full height
                              // width: "100%",
                              // marginLeft: "2%",
                              // backgroundColor={"#6b9080"}
                              // overflowX={"auto"}
                            >
                              <Flex
                                flexDirection={"column"}
                                maxHeight={"300px"}
                                width={"100%"}
                                // height: 100, // Use viewport height for full height
                                // width: "100%",
                                // marginLeft: "2%",
                                // backgroundColor={"#6b9080"}
                                // overflowX={"auto"}
                              >
                                <ReservationArea
                                  onFilterChange={() => {}}
                                  headerText={" "}
                                  buttonText={"Submit"}
                                  theSelectedMode={selectedAreaMode}
                                  optionsLabels={areaFiltersLabels}
                                  optionsQnz={areaQuestionsInit}
                                />
                              </Flex>

                              {/* AREA LOCATION SELECTION */}
                              <p className="text-white m-5 ">
                                {selectedAreaModeState}
                              </p>

                              {["REGION", "MTAA", "DISTRICT", "WARD"].includes(
                                selectedAreaModeState
                              ) && (
                                <div>
                                  {/* {errors.region && (
                                    <Text style={{ color: "red" }}>{errors.region}</Text>
                                  )} */}

                                  <Select
                                    name="region"
                                    value={formData.region}
                                    onChange={handleChange}
                                    placeholder="Select region"
                                  >
                                    {regionData?.map((cat: any) => (
                                      <option
                                        key={cat.region}
                                        value={cat.region}
                                      >
                                        {cat.region}
                                      </option>
                                    ))}
                                  </Select>
                                </div>
                              )}

                              {["MTAA", "DISTRICT", "WARD"].includes(
                                selectedAreaModeState
                              ) && (
                                <div>
                                  <Select
                                    name="district"
                                    value={formData.district}
                                    onChange={handleChange}
                                    placeholder="Select district"
                                  >
                                    {regionData
                                      ?.find(
                                        (cat: any) =>
                                          cat.region === formData.region
                                      )
                                      ?.data?.map((cat_district: any) => (
                                        <option
                                          key={
                                            formData.region +
                                            "_" +
                                            cat_district.district
                                          }
                                          // value={cat_district.district}
                                          value={
                                            cat_district.district +
                                            "_" +
                                            cat_district.postCode
                                          }
                                        >
                                          {cat_district.district}
                                        </option>
                                      ))}
                                  </Select>
                                </div>
                              )}

                              {["MTAA", "WARD"].includes(
                                selectedAreaModeState
                              ) && (
                                <div>
                                  <Select
                                    name="ward"
                                    value={formData.ward}
                                    onChange={handleChange}
                                    placeholder="Select ward"
                                  >
                                    {regionData
                                      ?.find(
                                        (cat: any) =>
                                          cat.region === formData.region
                                      )
                                      // @ts-ignore

                                      ?.data?.find(
                                        (cat_district: any) =>
                                          cat_district.district +
                                            "_" +
                                            cat_district.postCode ===
                                          formData.district
                                      )
                                      ?.data?.map((cat_ward: any) => {
                                        // console.log("cat_ward");
                                        // console.log(cat_ward);
                                        // console.log("cat_ward");

                                        return (
                                          <option
                                            key={
                                              formData.district +
                                              "_" +
                                              cat_ward.ward
                                            }
                                            // value={cat_ward.ward}
                                            value={
                                              cat_ward.ward +
                                              "_" +
                                              cat_ward.postCode
                                            }
                                          >
                                            {cat_ward.ward}
                                          </option>
                                        );
                                      })}
                                  </Select>
                                </div>
                              )}

                              {selectedAreaModeState === "MTAA" && (
                                <div>
                                  <Select
                                    name="mtaaVillage"
                                    value={formData.mtaaVillage}
                                    onChange={handleChange}
                                    placeholder="Select mtaa"
                                  >
                                    {regionData
                                      // @ts-ignore
                                      ?.find(
                                        (cat: any) =>
                                          cat?.region === formData.region
                                      )
                                      // @ts-ignore
                                      ?.data?.find(
                                        (cat_district: any) =>
                                          `${cat_district?.district}_${cat_district?.postCode}` ===
                                          formData.district
                                      )
                                      ?.data?.find(
                                        (cat_ward: any) =>
                                          `${cat_ward?.ward}_${cat_ward?.postCode}` ===
                                          formData.ward
                                      )
                                      ?.data?.map((mtaaVillage: any) => (
                                        <option
                                          key={
                                            formData.district +
                                            "_" +
                                            mtaaVillage.mtaaVillage
                                          }
                                          value={
                                            mtaaVillage.mtaaVillage
                                              ? `${mtaaVillage.mtaaVillage}`
                                              : //@ts-ignore
                                                `${mtaaVillage.mtaaVillage}`
                                          }
                                        >
                                          <span className="text-blue-300">
                                            {mtaaVillage.mtaaVillage &&
                                              `${
                                                "Mtaa/Kijiji  " +
                                                mtaaVillage.mtaaVillage
                                              }`}
                                          </span>

                                          {mtaaVillage.kitongoji &&
                                            //@ts-ignore

                                            `${
                                              "   Kitongoji cha   " +
                                              mtaaVillage.kitongoji
                                            }`}
                                        </option>
                                      ))}
                                  </Select>
                                </div>
                              )}

                              {/*  */}

                              {/* Button  */}
                              <Button
                                type="submit"
                                onClick={() => {
                                  if (formData.region.trim() === "") {
                                    return;
                                  }

                                  addToContentBanda(
                                    {
                                      title: "",
                                      district: formData.district,
                                      id:
                                        formData.mtaaVillage +
                                        "_" +
                                        formData.ward +
                                        "_" +
                                        formData.district +
                                        "_" +
                                        formData.region,
                                      region: formData.region,
                                      ward: formData.ward,
                                      name: formData.mtaaVillage,
                                      dates: JSON.stringify([]),
                                      isPromoted: false,
                                    },
                                    "ward"
                                  );

                                  setFormData({
                                    region: "",
                                    district: "",
                                    ward: "",
                                    mtaaVillage: "",
                                    districtPostcode: "",
                                    wardPostcode: "",
                                  });
                                }}
                                colorScheme="green"
                                marginTop="50px"
                                width={"300px"}
                              >
                                Add Area{" "}
                              </Button>

                              {promo
                                ?.find((item: ICart) => item.id === contentIDd)
                                ?.bandaumizaList?.filter(
                                  (item: any) => item.type === "ward"
                                )
                                ?.map((cart: any) => (
                                  <SelectedBandaUmizaInPromo
                                    key={cart.id}
                                    cart={cart}
                                    removeCart={removeCart}
                                    removeFromList={removeFromList}
                                    selectedFrequency={getFrequency}
                                    isPromoted={isPromoted}
                                    defSelectedPrice={cart.price}
                                    selectedPrice={selectedPriceSevice}
                                  />
                                ))}
                            </Flex>
                          )
                        }
                      </div>
                    </div>
                  }
                </div>
              </div>
            )}

            {step === 3 && (
              <div className=" p-4 rounded-lg shadow-lg md:p-5 space-y-4 bg-white my-5 overflow-auto">
                <h2>Step 3: Review</h2>
                <p>Review your selection before proceeding.</p>

                <Link
                  onClick={(e) => {}}
                  color="green"
                  variant={"link"}
                  _hover={{ bg: "#f1f1f1" }}
                  _active={{ bg: "white" }}
                  _focus={{ bg: "white" }}
                  fontWeight="500"
                  fontSize="14px"
                  position={"absolute"}
                  right={60}
                >
                  Finish
                </Link>

                <Card>
                  {promo.find((item: ICart) => item.id === contentIDd)
                    ?.bandaumizaList?.length > 0 && (
                    <Card
                      maxHeight={"800px"}
                      mt={"20px"}
                      overflowX={{ sm: "scroll", lg: "hidden" }}
                    >
                      {promo.length > 0 && (
                        <>
                          {promo
                            ?.find((item: ICart) => item.id === contentIDd)
                            ?.bandaumizaList?.map((cart: any) => (
                              <SelectedBandaUmizaInPromo
                                key={cart.id}
                                cart={cart}
                                removeCart={removeCart}
                                removeFromList={removeFromList}
                                selectedFrequency={getFrequency}
                                isPromoted={isPromoted}
                                defSelectedPrice={cart.price}
                                selectedPrice={selectedPriceSevice}
                              />
                            ))}
                        </>
                      )}
                    </Card>
                  )}
                </Card>

                <div className="container mx-auto p-4 rounded-lg shadow-lg md:p-5 space-y-4 bg-white my-5 overflow-auto">
                  <SimpleGrid
                    style={{
                      marginTop: 20,
                      marginBottom: 30,
                      display: "flex",
                      justifyContent: "space-between",
                      paddingRight: "2%",
                      paddingLeft: "2%",
                    }}
                    columns={2}
                    gap="20px"
                  >
                    {promo.find((item: ICart) => item.id === contentIDd)
                      ?.bandaumizaList?.length > 0 && (
                      <Link
                        onClick={() => {
                          // history.push("/site/explore");
                        }}
                        style={{ alignSelf: "center" }}
                      >
                        <Link
                          onClick={(e) => {
                            //     addToCart();
                            //     updateCartState({
                            //       ...promo.find(
                            //   (item: ICart) => item.id === contentIDd
                            // ),
                            //       activeCart: true,
                            //     });
                            history.push("/site/cart_review");
                          }}
                          color="green"
                          variant={"link"}
                          _hover={{ bg: "#f1f1f1" }}
                          _active={{ bg: "white" }}
                          _focus={{ bg: "white" }}
                          fontWeight="500"
                          fontSize="14px"
                        >
                          Checkout
                        </Link>
                      </Link>
                    )}

                    {promo.find((item: ICart) => item.id === contentIDd)
                      ?.bandaumizaList?.length > 0 && (
                      <Link
                        onClick={() => {
                          // history.push("/site/explore");
                        }}
                        style={{ alignSelf: "center" }}
                      >
                        <Link
                          onClick={(e) => {
                            // addToCart();
                            history.push("/site/explore");
                          }}
                          color="blue.400"
                          variant={"link"}
                          _hover={{ bg: "#f1f1f1" }}
                          _active={{ bg: "white" }}
                          _focus={{ bg: "white" }}
                          fontWeight="500"
                          fontSize="14px"
                        >
                          Continue Reservation
                        </Link>
                      </Link>
                    )}

                    <Link
                      // mt={"5%"}
                      // href={download}
                      onClick={() => {
                        setSelectedDate([]);
                        // setSelectedDateData({ start: "", end: "" });
                        setOpenCalendar(false);

                        history.push("/site/explore");
                      }}
                    >
                      <Link
                        // bg="#ff476c"
                        color="#ff476c"
                        variant={"link"}
                        _hover={{ bg: "#f1f1f1" }}
                        _active={{ bg: "white" }}
                        _focus={{ bg: "white" }}
                        fontWeight="500"
                        fontSize="14px"
                        // py="30px"
                        // px="27"
                        // me="38px"
                      >
                        Cancel
                      </Link>
                    </Link>
                  </SimpleGrid>
                </div>
              </div>
            )}
          </div>
        </div>

        <SimpleGrid
          mb="20px"
          columns={{ sm: 1, md: 2 }}
          spacing={{ base: "20px", xl: "20px" }}
          // maxHeight={"800px"}
        >
          <div
            className="py-5 
md:py-5 antialiased "
            // style={{backgroundColor:"#10002b",borderRadius:10,height:"fit-content"}}
          >
            {false && (
              <Card
                height={"max-content"}
                style={{
                  backgroundColor: "#10002b",
                  borderRadius: 10,
                  height: "fit-content",
                }}
              >
                <Text
                  color={"white"}
                  fontWeight="bold"
                  fontSize="16px"
                  mt="10px"
                  mb="4px"
                >
                  {storedPromo?.title} on {storedCartItem?.title}
                </Text>
                <Text
                  color={"white"}
                  fontWeight="bold"
                  fontSize="12px"
                  mt="10px"
                  mb="4px"
                >
                  {contentData?.data?.getContent?.author?.username}
                </Text>

                {/* <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="12px"
          mt="10px"
          mb="4px"
        >
          Amount{" : "}{contentData?.data?.getContent?.amount}{" "}{contentData?.data?.getContent?.currency}
        </Text> */}

                <SimpleGrid
                  style={{
                    marginTop: 20,
                    marginBottom: 30,
                    display: "flex",
                    justifyContent: "center",
                  }}
                  columns={2}
                  gap="20px"
                >
                  {promo.find((item: ICart) => item.id === contentIDd)
                    ?.bandaumizaList?.length > 0 && (
                    <Link
                      onClick={() => {
                        // history.push("/site/explore");
                      }}
                      style={{ alignSelf: "center" }}
                    >
                      <Link
                        onClick={(e) => {
                          addToCart();
                          updateCartState({
                            ...promo.find(
                              (item: ICart) => item.id === contentIDd
                            ),
                            activeCart: true,
                          });
                          // localStorage.setItem( "contentItem", JSON.stringify({content:{},update:false}));
                          history.push("/site/cart_review");
                        }}
                        color="green"
                        variant={"link"}
                        _hover={{ bg: "#f1f1f1" }}
                        _active={{ bg: "white" }}
                        _focus={{ bg: "white" }}
                        fontWeight="500"
                        fontSize="14px"
                      >
                        Checkout
                      </Link>
                    </Link>
                  )}

                  {promo.find((item: ICart) => item.id === contentIDd)
                    ?.bandaumizaList?.length > 0 && (
                    <Link
                      onClick={() => {
                        // history.push("/site/explore");
                      }}
                      style={{ alignSelf: "center" }}
                    >
                      <Link
                        onClick={(e) => {
                          addToCart();
                          // localStorage.setItem( "contentItem", JSON.stringify({content:{},update:false}));
                          history.push("/site/explore");
                        }}
                        color="blue.400"
                        variant={"link"}
                        _hover={{ bg: "#f1f1f1" }}
                        _active={{ bg: "white" }}
                        _focus={{ bg: "white" }}
                        fontWeight="500"
                        fontSize="14px"
                      >
                        Continue Reservation
                      </Link>
                    </Link>
                  )}

                  <Link
                    // mt={"5%"}
                    // href={download}
                    onClick={() => {
                      // localStorage.setItem( "contentItem", JSON.stringify({content:{},update:false}))
                      setSelectedDate([]);
                      // setSelectedDateData({ start: "", end: "" });
                      setOpenCalendar(false);

                      history.push("/site/explore");
                    }}
                  >
                    <Link
                      // bg="#ff476c"
                      color="#ff476c"
                      variant={"link"}
                      _hover={{ bg: "#f1f1f1" }}
                      _active={{ bg: "white" }}
                      _focus={{ bg: "white" }}
                      fontWeight="500"
                      fontSize="14px"
                      // py="30px"
                      // px="27"
                      // me="38px"
                    >
                      Cancel
                    </Link>
                  </Link>
                </SimpleGrid>
              </Card>
            )}

            {isOpen && (
              <div
                id="static-modal"
                className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-gray-900 bg-opacity-80"
                aria-hidden={!isOpen}
              >
                <div className="relative p-4 w-full max-w-2xl sm:max-w-6xl  max-h-full">
                  {/* Modal content */}
                  <div
                    className="relative  rounded-lg shadow  "
                    style={{
                      backgroundColor: "#03071e",
                    }}
                  >
                    {/* Modal header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t border-gray-800">
                      <h3 className="text-xl font-semibold text-white dark:text-white">
                        Where to happen ?
                      </h3>

                      <button
                        onClick={() => {
                          setOpenCalendar(false);
                          toggleModal();
                        }}
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-white rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                        <span className="sr-only">Close modal</span>
                      </button>
                    </div>
                    {/* Modal body */}
                    <div className="p-4 md:p-5 space-y-4 h-[70vh] overflow-auto">
                      {!openCalendar ? (
                        <div
                        //flexDirection="column" w="100%" px="0px"
                        // bg={"red"}
                        //borderRadius={"5px"}
                        // marginTop={"20px"}
                        //  height={"400px"}
                        //  overflowY={"auto"}
                        // bg={"red"}
                        >
                          {specifyBanda && (
                            <>
                              <div
                                style={{
                                  // position: "sticky",
                                  marginTop: 1,
                                  right: 0,
                                  height: 400, // Use viewport height for full height
                                  width: "100%",
                                  marginLeft: "2%",
                                  // backgroundColor: "#6b9080",
                                  overflowY: "auto",
                                  // padding: "20px",
                                }}
                              >
                                <div
                                  style={{
                                    marginTop: 10,
                                  }}
                                >
                                  {/* <h2
              style={{
                padding: 20,
              }}
            >
              Find Specific banda umiza using banda ID
            </h2> */}
                                  {/* <Input
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    variant={"auth"}
                                    placeholder="Search"
                                    marginTop={"30px"}
                                    height={"40px"}
                                    name="Search"
                                    style={{
                                      // borderColor:"#eef0f2",
                                      border: "1px solid #eef0f2",
                                      // position:"relative",
                                      color: "#333",
                                      width: "90%",
                                    }}
                                  /> */}

                                  <Flex
                                    mt={"20px"}
                                    maxHeight={"800px"}
                                    flexDirection={"column"}
                                    width={"95%"}
                                    overflowX={{ sm: "scroll", lg: "hidden" }}
                                  >
                                    {searchQuery.length >= 3 &&
                                      data?.getBandaByCodePromo?.map(
                                        (item: any) => {
                                          const itemExists = isIdAlreadyExists(
                                            item.id
                                          );

                                          const foundCart =
                                            item?.eventsFunds?.find(
                                              (item: any) =>
                                                item.contentId ==
                                                storedCartItem.id
                                            );
                                          return (
                                            <div
                                              style={{
                                                height: 100,
                                                width: "100%",

                                                backgroundColor: "#284b63",
                                                marginBottom: 5,
                                                borderRadius: 5,
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                              key={item.id}
                                            >
                                              <div
                                                style={{
                                                  // height: 125,
                                                  width: "100%",

                                                  backgroundColor: "#284b63",
                                                  marginBottom: 5,
                                                  borderRadius: 5,
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                                key={item.id}
                                              >
                                                <div
                                                  style={{
                                                    width: "75%",
                                                    paddingLeft: 10,
                                                    // backgroundColor:"red",
                                                    paddingTop: 2,
                                                  }}
                                                >
                                                  {" "}
                                                  <p
                                                    style={{
                                                      padding: 0,
                                                      color: "#d6d6d6",
                                                    }}
                                                  >
                                                    {item.title}
                                                  </p>
                                                  <p
                                                    style={{
                                                      padding: 0,
                                                      color: "#d6d6d6",
                                                    }}
                                                  >
                                                    {" "}
                                                    {item.bandaName}
                                                  </p>
                                                  <p
                                                    style={{
                                                      padding: 0,
                                                      color: "#d6d6d6",
                                                    }}
                                                  >
                                                    {item.bandaCode}
                                                  </p>
                                                  <p
                                                    style={{
                                                      padding: 0,
                                                      color: "#d6d6d6",
                                                      fontSize: 13,
                                                    }}
                                                  >
                                                    {item.region}{" "}
                                                    {item.district} {item.ward}
                                                  </p>
                                                  <p
                                                    style={{
                                                      padding: 0,
                                                      fontSize: 11,
                                                      color: "#d6d6d6",
                                                    }}
                                                  >
                                                    {" "}
                                                    {item.physicalResidency}
                                                  </p>
                                                  {/* <p
                                              style={{
                                                padding: 0,
                                                fontWeight:"700",
                                                color: "gray",
                                              }}
                                            >
                                             
                                                {item.currency}
                                                {item.priceHour} per hr
                                             
                                            </p> */}
                                                </div>

                                                <div
                                                  style={{
                                                    // marginLeft: "60%",
                                                    // backgroundColor:"green",
                                                    width: "25%",
                                                    // justifyContent:"center",
                                                    // alignContent:"center",
                                                    // display:"flex",
                                                  }}
                                                >
                                                  {itemExists ? (
                                                    <button
                                                      onClick={() => {
                                                        setCalendarEvents([]);
                                                        setSelectedDate([]);
                                                        setOpenCalendar(true);

                                                        setBanda(item);
                                                      }}
                                                      style={{
                                                        backgroundColor:
                                                          "#6b9080",
                                                        width: "80px",
                                                        height: 30,
                                                        padding: 5,
                                                        color: "white",
                                                        // marginRight: "3%",
                                                        marginTop: "30%",
                                                        marginLeft: "6%",
                                                        fontSize: 12,
                                                        // fontWeight: "bold",
                                                        borderRadius: 5,
                                                      }}
                                                    >
                                                      Edit
                                                    </button>
                                                  ) : (
                                                    <button
                                                      onClick={() => {
                                                        //Open calendar here
                                                        setCalendarEvents([]);
                                                        setSelectedDate([]);
                                                        setOpenCalendar(true);
                                                        setBanda(item);
                                                      }}
                                                      style={{
                                                        backgroundColor:
                                                          "#04a777",
                                                        width: "80px",
                                                        marginTop: "30%",
                                                        height: 30,
                                                        color: "white",
                                                        // marginRight: "3%",
                                                        // fontWeight: "bold",
                                                        marginLeft: "10%",
                                                        fontSize: 12,
                                                        borderRadius: 5,
                                                      }}
                                                    >
                                                      Add
                                                    </button>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                  </Flex>
                                </div>
                              </div>
                            </>
                          )}

                          {specifyArea && (
                            <Flex
                              flexDirection={"column"}
                              maxHeight={"300px"}
                              width={"100%"}
                              // height: 100, // Use viewport height for full height
                              // width: "100%",
                              // marginLeft: "2%",
                              // backgroundColor={"#6b9080"}
                              overflowX={"auto"}
                            >
                              <Flex
                                flexDirection={"column"}
                                maxHeight={"300px"}
                                width={"100%"}
                                // height: 100, // Use viewport height for full height
                                // width: "100%",
                                // marginLeft: "2%",
                                // backgroundColor={"#6b9080"}
                                overflowX={"auto"}
                              >
                                <ReservationArea
                                  onFilterChange={() => {}}
                                  headerText={" "}
                                  buttonText={"Submit"}
                                  theSelectedMode={selectedAreaMode}
                                  optionsLabels={areaFiltersLabels}
                                  optionsQnz={areaQuestionsInit}
                                />
                              </Flex>

                              <Flex
                                overflowY={"auto"}
                                width={"100%"}
                                display={"flex"}
                                flexDirection={"column"}
                                // marginTop={"-120px"}
                              >
                                {areaDataFetched?.data?.filterAreas.map(
                                  (area: any) => {
                                    //const regionExist = isNameAlreadyExists(area.id);
                                    // const districtExist = isNameAlreadyExists(area.id);
                                    // const wardExist = isNameAlreadyExists(area.id);

                                    return (
                                      <div>
                                        <AreaBased
                                          // key={area.name}
                                          reservationData={promo.find(
                                            (item: ICart) =>
                                              item.id === contentIDd
                                          )}
                                          cart={area}
                                          mode={selectedAreaModeState}
                                          removeCart={removeCart}
                                          addToContent={addToContentBanda}
                                          addToContentWard={addToContentBanda}
                                        />
                                      </div>
                                    );
                                  }
                                )}
                              </Flex>
                            </Flex>
                          )}
                        </div>
                      ) : (
                        <Card>
                          <Text
                            color={"gray"}
                            // fontWeight="bold"
                            // fontSize="2xl"
                            // mt="2px"
                            // mb="4px"
                          >
                            {banda?.bandaName}
                          </Text>

                          <Text
                            color={textColorPrimary}
                            // fontWeight="bold"
                            // fontSize="2xl"
                            // mt="5px"
                            // mb="4px"
                          >
                            {banda?.address}
                          </Text>

                          <Text
                            color={textColorPrimary}
                            // fontWeight="bold"
                            // // fontSize="xl"
                            // mt="10px"
                            // mb="4px"
                          >
                            Banda Capacity : {banda?.capacity}
                          </Text>

                          <Text
                            color={textColorPrimary}
                            // fontWeight="bold"
                            // // fontSize="xl"
                            // mt="10px"
                            // mb="4px"
                          >
                            Cost per Hour: {banda?.priceHour} {banda?.currency}
                          </Text>

                          <FullCalendar
                            ref={calendarRef}
                            plugins={[
                              dayGridPlugin,
                              timeGridPlugin,
                              interactionPlugin,
                            ]}
                            initialView="timeGridWeek"
                            selectable={true}
                            // selectConstraint="businessHours"
                            slotDuration="01:00:00"
                            // selectAllow={(selectInfo) => {
                            //   return (
                            //     selectInfo.start.getHours() >= 8 &&
                            //     selectInfo.end.getHours() <= 17
                            //   );
                            // }}
                            selectAllow={(selectInfo) => {
                              const selectionStart = selectInfo.start;
                              const selectionEnd = selectInfo.end;
                              const now = new Date();

                              console.log("selectionStart");
                              console.log(selectionStart);
                              console.log(selectionEnd);
                              console.log(now);

                              // Log the selection info for debugging
                              console.log(
                                `Selection start: ${selectionStart}, end: ${selectionEnd}`
                              );

                              // Check if the selection starts or ends in the past
                              if (selectionStart < now || selectionEnd < now) {
                                console.log("Selection is in the past");
                                return false;
                              }

                              // Check for overlap with existing events
                              const isOverlapping = [
                                ...calendarEvents,
                                ...selectedDate,
                              ].some((event) => {
                                const eventStart = new Date(event.start);
                                const eventEnd = new Date(event.end);

                                // Check for overlap
                                return (
                                  (selectionStart >= eventStart &&
                                    selectionStart < eventEnd) ||
                                  (selectionEnd > eventStart &&
                                    selectionEnd <= eventEnd) ||
                                  (selectionStart <= eventStart &&
                                    selectionEnd >= eventEnd)
                                );
                              });

                              // Log the overlap result for debugging
                              console.log(`Is overlapping: ${isOverlapping}`);

                              // Return false if the selection overlaps with any event or is in the past, otherwise true
                              return !isOverlapping;
                            }}
                            // businessHours={{
                            //   daysOfWeek: [1, 2, 3, 4, 5, 6, 7], // Monday - Friday
                            //   startTime: "06:00", // 8am
                            //   endTime: "23:00", // 5pm
                            // }}
                            events={[...calendarEvents, ...selectedDate]}
                            // select={handleSelect}
                            eventClick={handleEventClick}
                          />
                        </Card>
                      )}
                    </div>
                    {/* Modal footer */}
                    <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b border-gray-800">
                      <button
                        onClick={() => {
                          const bandaInList = promo
                            ?.find((item: ICart) => item.id === contentIDd)
                            ?.bandaumizaList?.find(
                              (bandaUmiza: IBanda) => bandaUmiza.id === banda.id
                            );

                          console.log("bandaInList");
                          console.log("bandaInList");
                          console.log(bandaInList);
                          console.log(selectedDate);
                          const filteredArray = selectedDate.filter(
                            (item: any) => item.color === "orange"
                          );

                          console.log("filteredArray");
                          console.log("filteredArray");
                          console.log(filteredArray);

                          if (!bandaInList || true) {
                            addToContentBanda(
                              {
                                ...banda,
                                dates: JSON.stringify(filteredArray),
                                // id:"0157b064-3b9d-4248-a1e7-ec54ff56d81e",
                              },
                              "banda"
                            );
                          } else {
                            updateDateReservations(
                              JSON.stringify(selectedDate),
                              bandaInList
                            );
                          }

                          setOpenCalendar(false);
                          toggleModal();
                        }}
                        type="button"
                        className="py-2.5 px-5 ms-3 text-sm font-medium text-white focus:outline-none bg-gray:600 rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 border-gray-800 dark:hover:text-white dark:hover:bg-gray-700"
                      >
                        Save Changes
                      </button>

                      <button
                        onClick={() => {
                          setOpenCalendar(false);
                          toggleModal();
                        }}
                        type="button"
                        className="py-2.5 px-5 ms-3 text-sm font-medium text-white focus:outline-none bg-gray:600 rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 border-gray-800 dark:hover:text-white dark:hover:bg-gray-700"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </SimpleGrid>
      </Box>
    </>
  );
}
