import * as actionTypes from "./actionTypes";

const initialState: PromoState = {
  promo: [],
};

const promoReducer = (
  state: PromoState = initialState,
  action: PromoActions
): PromoState => {
  switch (action.type) {
    case actionTypes.ADD_PROMO:
      console.log(action);
      const existingCartItem = state.promo.find(
        (cart) => cart.id === action.promo.id
      );

      // If the item already exists, return the state unchanged
      if (existingCartItem) {
        return state;
      }

      const newPromo: ICart = {
        id: action.promo.id,
        userId: action.promo.userId,
        title: action.promo.title,
        type: action.promo.type,
        coverImage: action.promo.coverImage,
        price: action.promo.price,
        activeCart: action.promo.activeCart,
        currency: "Tsh",
        duration: action.promo.duration,
        categories:action.promo.categories,
        body: action.promo.body,
        frequency: action.promo.frequency,
        contentCost: action.promo.contentCost,
        promoId: action.promo.promoId,
        isPromo: action.promo.isPromo,
        location: action.promo.location,
        bandaumizaList: action.promo.bandaumizaList,
      };

      return {
        ...state,
        promo: state.promo.concat(newPromo),
      };
    case actionTypes.UPDATE_PROMO:
      // const updatedPromoUpdate = state.promo.map((promo) =>
      //   promo.id === action.promo.id ? { ...promo, ...action.promo } : promo
      // );
      // console.log("UPDATE promo")
      // console.log("UPDATE promo")
      // console.log("UPDATE promo")
      // console.log("UPDATE promo")
      // console.log({
      //   ...state,
      //   promo: updatedPromoUpdate,
      // })
      console.log("UPDATE promo");
      console.log("UPDATE promo");
      console.log(action.promo);
      console.log("UPDATE promo");
      console.log("UPDATE promo");
      // return {
      //   ...state,
      //   promo: updatedPromoUpdate,
      // };

      // const newObject = action.promo.bandaumizaList[0]

      // const existingList = state.promo.find((promoItem: ICart) =>
      //   promoItem.id === action.promo.id)?.bandaumizaList

      // const updatedCartsUpdate = state.promo.map((promoItem: ICart) =>
      //   promoItem.id === action.promo.id
      //     ?
      //       {...promoItem ,
      //         bandaumizaList: [...existingList, ...action.promo.bandaumizaList]
      //       }
      //     : promoItem
      // );

      //         const existingList = state.promo.find((promoItem: ICart) => promoItem.id === action.promo.id)?.bandaumizaList || [];

      // const updatedCartsUpdate = state.promo.map((promoItem: ICart) =>
      //   promoItem.id === action.promo.id
      //     ? {
      //         ...promoItem,
      //         bandaumizaList: [
      //           ...existingList,
      //           ...action.promo.bandaumizaList.filter(
      //             (newItem: IBanda) => !existingList.some((existingItem: IBanda) => existingItem.id === newItem.id)
      //           )
      //         ]
      //       }
      //     : promoItem
      // );

      const updatedCartsUpdate = state.promo.map((cart: ICart) =>
        cart.id === action.promo.id
          ? // action.cart
            { ...cart, ...action.promo }
          : // {...cart
            //   bandaumizaList: cart.bandaumizaList, action.cart.bandaumizaList }
            cart
      );

      // const idExists = state.carts.some((item: any) => item.id === cart.id);
      // console.log("UPDATED :::::::: UPDATED :::::::: UPDATED ::::::::");
      // console.log("UPDATED :::::::: UPDATED :::::::: UPDATED ::::::::");
      // console.log("UPDATED :::::::: UPDATED :::::::: UPDATED ::::::::");
      // console.log("UPDATED :::::::: UPDATED :::::::: UPDATED ::::::::");
      console.log(updatedCartsUpdate);

      return {
        ...state,
        promo: updatedCartsUpdate,
      };

    case actionTypes.REMOVE_PROMO:
      console.log("PROMO PROMO");

      const updatedPromo: ICart[] = state?.promo?.filter(
        (promo) => promo?.id !== action?.promo?.id
      );
      return {
        ...state,
        promo: updatedPromo,
      };

    case actionTypes.CLEAR_CART_PROMO:
      return {
        promo: [],
      };
  }
  return state;
};

export default promoReducer;
