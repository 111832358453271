import {
  Box,
  Icon,
  Flex,
  Image,
  SimpleGrid,
  Text,
  Wrap,
  Tag,
  Link,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useColorModeValue } from "@chakra-ui/react";
import Project1 from "assets/images/imageHolder.png";
import Information from "views/site/profileSponsor/components/Information";
import { Button } from "@chakra-ui/react";
import { Event, useGetBandaQuery } from "generated/graphql";
import Project from "./components/Project";
import { useHistory, useLocation } from "react-router-dom";
import { IoImageOutline } from "react-icons/io5";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useEffect, useRef, useState } from "react";
import { MdVerifiedUser } from "react-icons/md";
import { BgisModal } from "../exploreBanda/components/bgisModal";
import { RiHeart3Fill } from "react-icons/ri";

export default function BandaDetails() {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const history = useHistory();
  // const storedCartItemString = localStorage.getItem("bandaItem");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // console.log(location.state);
  const bandaIDd = queryParams.get("id");

  // const storedCartItem = JSON.parse(storedCartItemString);
  const [mtaa, setMtaa] = useState("");
  const [ongezaKikundi, setOngezaKikundi] = useState(false);

  const contentData = useGetBandaQuery({
    fetchPolicy: "network-only",
    variables: {
      findBandaumizaInput: {
        bandaId: bandaIDd,
      },
    },
  });

  console.log("contentData");
  console.log("contentData");
  console.log(bandaIDd);
  console.log(contentData);
  console.log("contentData");
  console.log("contentData");

  const funct = (bandax?: any) => {
    setMtaa(contentData?.data?.getBanda?.ward);
    setOngezaKikundi(true);
  };

  const calendarRef = useRef(null);
  const [calendarEvents, setCalendarEvents] = useState([]);

  useEffect(() => {
    if (contentData?.data?.getBanda?.eventsFunds?.length > 0) {
      // console.log("Banda:", banda);
      // console.log("Events Funds:", banda?.eventsFunds);

      const allScheduledData = contentData?.data?.getBanda?.eventsFunds?.map(
        (item: Event) => {
          // console.log("Events :", item);
          if (!(item?.startDateTime === "") && !(item?.endDateTime === "")) {
            return {
              title: item.title,
              // eventType: item.eventType,
              start: item.startDateTime,
              end: item.endDateTime,
              color:
                item.eventType === "FUNDED"
                  ? "#2d6a4f"
                  : item.eventType === "PRIVATE"
                  ? "#468faf"
                  : "",
            };
          }
        }
      );

      const initialEventsDB = allScheduledData?.filter(
        (item) => item !== undefined
      );

      setCalendarEvents([...initialEventsDB]);
    }
  }, [contentData?.data?.getBanda]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {!contentData.loading ? (
        <div className="pt-8">
          <SimpleGrid
            columns={{ sm: 1, md: 2 }}
            spacing={{ base: "20px", xl: "20px" }}
          >
            <div className=" h-min  rounded">
              <div className="ring-0 ring-slate-900/5 shadow-sm bg-white h-min p-6  rounded-2xl">
                <Flex
                  direction={"row"}
                  style={{
                    alignSelf: "center",
                    width: "100%",
                  }}
                >
                  {contentData?.data?.getBanda?.coverImage?.trim() === "" ||
                  !contentData?.data?.getBanda?.coverImage ? (
                    <Icon
                      as={IoImageOutline}
                      w="30px"
                      h="30px"
                      style={{
                        alignSelf: "center",
                        height: 30,
                      }}
                    />
                  ) : (
                    <img
                      alt=""
                      src={contentData?.data?.getBanda?.coverImage}
                      className="size-14 rounded-lg object-cover"
                    />
                  )}

                  <div className="flex flex-col mb-5">
                    <Text
                      color={"black"}
                      fontWeight="bold"
                      fontSize="2xl"
                      mt="1px"
                      mb="4px"
                      marginLeft={"10px"}
                    >
                      {contentData?.data?.getBanda?.bandaCode?.toUpperCase()}
                      <Icon
                        as={MdVerifiedUser}
                        width="15px"
                        height="15px"
                        color="green"
                        position={"absolute"}
                        alignSelf={"end"}
                        fontSize="12px"
                        fontWeight="500"
                        borderRadius="70px"
                        //  marginLeft={"50%"}
                        mb={"23%"}
                      />
                    </Text>
                    <h3 className="ml-3 font-bold text-gray-800 text-lg sm:text-2xl">
                      {contentData?.data?.getBanda?.bandaName}
                    </h3>
                  </div>
                </Flex>

                <div
                  onClick={() => {
                    funct();
                  }}
                  className="h-min font-semibold sm:font-bold uppercase text-gray-900 text-md  sm:text-xl gap-5 flex flex-row flex-wrap hover:underline"
                >
                  <p className="whitespace-normal">
                    {contentData?.data?.getBanda?.region}
                  </p>

                  <p className="whitespace-normal">
                    {contentData?.data?.getBanda?.district}
                  </p>

                  <p className="whitespace-normal">
                    {contentData?.data?.getBanda?.ward}
                  </p>
                  <p className="whitespace-normal">
                    {contentData?.data?.getBanda?.mtaaName}
                  </p>
                </div>
                <Link
                  // bg="#003459"
                  _hover={{ color: "black" }}
                  fontWeight="500"
                  fontSize="14px"
                  alignSelf={"end"}
                  variant={"link"}
                  // py="30px"
                  // width={"50px"}
                  mt={"0px"}
                  // px="47px"
                  // me="38px"
                  // href={"https://sensa.nbs.go.tz/publications"}
                  // target="_blank" // Opens the link in a new tab
                  rel="noopener noreferrer"
                  onClick={() => {
                    funct();
                  }}
                  className="text-fuchsia-800 "
                >
                  {contentData?.data?.getBanda?.physicalResidency}
                </Link>
              </div>
              <div className="ring-0 ring-slate-900/5 shadow-sm bg-white h-min p-6 mt-5 rounded-2xl group/item">
                <Wrap mt="5">
                  {[
                    contentData?.data?.getBanda?.drinks ? "drinks" : "null",
                    contentData?.data?.getBanda?.food ? "food" : "null",
                    contentData?.data?.getBanda?.projector
                      ? "projector"
                      : "null",
                    contentData?.data?.getBanda?.wifi ? "wifi" : "null",
                    contentData?.data?.getBanda?.generator
                      ? "generator"
                      : "null",
                    contentData?.data?.getBanda?.vikalioKutoka
                      ? "vikalio vinatoka"
                      : "null",
                    contentData?.data?.getBanda?.laptop ? "laptop" : "null",
                    contentData?.data?.getBanda?.airConditioner ? "AC" : "null",
                    contentData?.data?.getBanda?.feni ? "feni" : "null",
                  ]
                    .filter((facility) => facility !== "null")
                    .map((value, index) => (
                      <div
                        key={index}
                        className="text-lg ring-0 ring-slate-900/5 shadow-md font-bold px-3 rounded bg-fuchsia-200 text-fuchsia-900 p-2  group-hover:p-3  group-hover:bg-fuchsia-100  group-hover:text-slate-900"
                      >
                        {value}
                      </div>
                    ))}
                </Wrap>

                <div className="rounded-b-2xl pb-6 bg-zinc-50 grid grid-flow-row-dense grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-4  grid-rows-2 sm:grid-rows-3 gap-1  sm:gap-2   pt-5 px-8 ">
                  <Information
                    boxShadow={cardShadow}
                    title="capacity"
                    value={contentData?.data?.getBanda?.capacity}
                  />
                  <Information
                    boxShadow={cardShadow}
                    title="Screens"
                    value={
                      contentData?.data?.getBanda?.tvSize
                        ? `${
                            JSON.parse(contentData?.data?.getBanda?.tvSize)
                              ?.length
                          } Size ${JSON.parse(
                            contentData?.data?.getBanda?.tvSize
                          )?.toString()}`
                        : ""
                    }
                  />
                  <Information
                    boxShadow={cardShadow}
                    title="Decoders"
                    value={
                      contentData?.data?.getBanda?.decoders
                        ? JSON.parse(
                            contentData?.data?.getBanda?.decoders
                          ).toString()
                        : ""
                    }
                    // value={"contentData?.data?.getBanda?.decoders ? JSON.parse(contentData?.data?.getBanda?.decoders).toString():"}
                  />
                  <Information
                    boxShadow={cardShadow}
                    title="Reservations"
                    value={`${
                      contentData?.data?.getBanda?.eventsFunds.length
                    } ${
                      contentData?.data?.getBanda?.eventsFunds.length > 12
                        ? "+"
                        : ""
                    }`}
                  />
                  <Information
                    boxShadow={cardShadow}
                    title="Size"
                    value={`${contentData?.data?.getBanda?.bandaSize}`}
                  />
                </div>

                {false && (
                  <Button
                    bg="#003459"
                    color="white"
                    _hover={{ bg: "#00a8e8" }}
                    _active={{ bg: "#007ea7" }}
                    _focus={{ bg: "#007ea7" }}
                    fontWeight="500"
                    fontSize="14px"
                    py="20px"
                    width={"10%"}
                    alignSelf={"center"}
                    px="47px"
                    me="38px"
                    onClick={() => {
                      history.push("/site/view-gallery");
                    }}
                  >
                    Gallery
                  </Button>
                )}
              </div>
            </div>

            <div className="p-5 rounded-2xl z-0 ring-0 ring-slate-900/5 shadow-sm bg-fuchsia-100">
              <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="timeGridWeek"
                selectable={false}
                selectConstraint="businessHours"
                // height={"800px"}

                businessHours={{
                  daysOfWeek: [1, 2, 3, 4, 5, 6, 7], // Monday to Sunday
                  startTime: "06:00", // 6:00 AM
                  endTime: "00:00", // 6:00 PM
                }}
                events={calendarEvents}
                // slotDuration="01:00:00"
              />
            </div>
          </SimpleGrid>

          <h3 className="text-5xl my-6 font-extrabold  text-gray-900">
            Reservations
          </h3>

          <div className="rounded-2xl ">
            <SimpleGrid
              style={{
                // marginTop: 20,
                marginBottom: 30,
              }}
              // columns={5}
              // minChildWidth={"250px"}
              gap="10px"
              borderRadius={"10px"}
            >
              {contentData?.data?.getBanda?.eventsFunds.map((item: any) => {
                return (
                  <>
                    <Project
                      boxShadow={cardShadow}
                      mb="20px"
                      image={Project1}
                      item={item}
                      ranking={item.createdAt}
                      dateStart={item.id}
                      id={item.id}
                      eventType={item.eventType}
                      title={item.title}
                      category={item.description}
                      added={"itemExist"}
                      away={"Napoli"}
                    />
                  </>
                );
              })}
            </SimpleGrid>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex justify-center mt-20 h-screen">
            <div
              className="animate-spin inline-block w-16 h-16 border-8 border-current border-t-transparent text-violet-700 rounded-full"
              role="status"
              aria-label="loading"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
