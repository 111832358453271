import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Image,
  Icon,
  SimpleGrid,
  Text,
  useColorModeValue,
  FormLabel,
  Select,
  Input,
  Grid,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import Project1 from "assets/images/imageHolder.png";

import { useSponsorEventsListQuery, useSponsorQuery } from "generated/graphql";
import imageHolder from "assets/images/imageHolder.png";
import { IoImageOutline } from "react-icons/io5";
import Hosting from "./components/Hosting";
import districts from "assets/geo/Districts.json";
import regions from "assets/geo/Regions.json";
import wards from "assets/geo/Wards.json";
import { filtersLabels } from "../registerSponsor";
import { formatDateTime } from "../helpers";
import { useLocation } from "react-router-dom";
// import Banner from "../sponsor_gallery/components/Banner";
import { useSelector } from "react-redux";
import BannerImage from "./components/BannerImage";
import { MdBarChart, MdContentPaste, MdPhotoLibrary } from "react-icons/md";
import BannerSide from "./components/BannerSide";
import Banner from "./components/Banner";

export default function SponsorDetails() {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = useColorModeValue("gray.400", "gray.400");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sponsorId = queryParams.get("id");
  const age = queryParams.get("age");

  const sponsorString = localStorage.getItem("sponsorItem");

  const sponsor = JSON.parse(sponsorString);

  const  contentData = useSponsorQuery({
    fetchPolicy: "network-only",
    variables: {
      getSponsorProfileInput: {
        id: sponsorId, // sponsor?.id,
      },
    },
  });

  // console.log(contentData?.data?.sponsor?.media);

  const sponsorEvents = useSponsorEventsListQuery({
    fetchPolicy: "network-only",
    variables: {
      getContentInput: {
        contentId: sponsorId, // sponsor?.id,
        pageSize: 100,
        page: 0,
      },
    },
  });

  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const [imageView, setImageView] = useState("");
  const [first, setFirst] = useState(false);
  const [refreshSelected, setRefreshSelected] = useState(false);
  const [appMode, setAppMode] = useState("reservations");

  const contentDataImages = useSponsorQuery({
    fetchPolicy: "network-only",
    variables: {
      getSponsorProfileInput: {
        id: sponsorId, //sponsor?.id,//user.sponsorId,
      },
    },
  });

  console.log(sponsorId);
  console.log(sponsor?.id);

  console.log(user?.sponsorId);

  console.log(contentDataImages?.data?.sponsor?.media);

  const addedItemMiddle = (data: any) => {
    console.log(data);
    setRefreshSelected(!refreshSelected);
  };

  useEffect(() => {
    contentData.refetch();
    //setFirst(false)
  }, [refreshSelected]);

  useEffect(() => {
    if (!first && contentData?.data?.sponsor?.media.length > 0) {
      setImageView(contentData?.data?.sponsor?.media[0]);
      //   setFirst(true)
    }
  }, [contentData, refreshSelected]);

  const google = (
    <a className="p-3 rounded-full border border-solid border-gray-300 group bg-gray-50 transition-all duration-500 hover:bg-indigo-700 hover:border-indigo-700">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1115_412)">
          <path
            className="fill-blue-400 transition-all duration-500 group-hover:fill-white"
            d="M20 10.2391C20 9.56523 19.9333 8.86958 19.8222 8.21741H10.2V12.0652H15.7111C15.4889 13.3044 14.7556 14.3913 13.6667 15.087L16.9556 17.587C18.8889 15.8261 20 13.2609 20 10.2391Z"
            fill=""
          />
          <path
            className="fill-green-400 transition-all duration-500 group-hover:fill-white"
            d="M10.2 19.9783C12.9556 19.9783 15.2667 19.087 16.9556 17.5652L13.6667 15.087C12.7556 15.6957 11.5778 16.0435 10.2 16.0435C7.53337 16.0435 5.28893 14.2826 4.46671 11.9348L1.08893 14.4783C2.82226 17.8479 6.33337 19.9783 10.2 19.9783Z"
            fill="#34A353"
          />
          <path
            className="fill-yellow-400 transition-all duration-500 group-hover:fill-white"
            d="M4.46673 11.913C4.0445 10.6739 4.0445 9.32608 4.46673 8.08695L1.08895 5.52173C-0.355496 8.34782 -0.355496 11.6739 1.08895 14.4783L4.46673 11.913Z"
            fill="#F6B704"
          />
          <path
            className="fill-red-400 transition-all duration-500 group-hover:fill-white"
            d="M10.2 3.97827C11.6445 3.95653 13.0667 4.5 14.1112 5.47827L17.0223 2.6087C15.1778 0.913046 12.7334 2.58834e-06 10.2 0.0217417C6.33337 0.0217417 2.82226 2.15218 1.08893 5.52174L4.46671 8.08696C5.28893 5.7174 7.53337 3.97827 10.2 3.97827Z"
            fill="#E54335"
          />
        </g>
        <defs>
          <clipPath id="clip0_1115_412">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </a>
  );
  const fb = (
    <a className="p-3 rounded-full border border-solid border-gray-300 bg-gray-50 group transition-all duration-500 hover:bg-indigo-700 hover:border-indigo-700">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1115_52)">
          <path
            className="fill-indigo-600 transition-all duration-500 group-hover:fill-white"
            d="M10.0001 20C15.523 20 20.0001 15.5228 20.0001 10C20.0001 4.47715 15.523 0 10.0001 0C4.47727 0 0.00012207 4.47715 0.00012207 10C0.00012207 15.5228 4.47727 20 10.0001 20Z"
            fill=""
          />
          <path
            className="fill-white transition-all duration-500 group-hover:fill-indigo-700"
            d="M13.2516 3.06946H11.0364C9.72179 3.06946 8.25958 3.62236 8.25958 5.52793C8.266 6.1919 8.25958 6.82779 8.25958 7.54345H6.73877V9.96352H8.30665V16.9305H11.1877V9.91754H13.0893L13.2613 7.53666H11.1381C11.1381 7.53666 11.1428 6.47754 11.1381 6.16997C11.1381 5.41693 11.9216 5.46005 11.9688 5.46005C12.3416 5.46005 13.0666 5.46114 13.2527 5.46005V3.06946H13.2516V3.06946Z"
            fill=""
          />
        </g>
        <defs>
          <clipPath id="clip0_1115_52">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </a>
  );
  const ig = (
    <a className="p-3 rounded-full border border-solid border-gray-300 bg-gray-50 group transition-all duration-500 hover:bg-indigo-700 hover:border-indigo-700">
      <svg
        className="stroke-red-600 transition-all duration-500 group-hover:stroke-white"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.1667 5.83333V5.875M9.16673 17.5H10.8334C13.9761 17.5 15.5474 17.5 16.5237 16.5237C17.5001 15.5474 17.5001 13.976 17.5001 10.8333V9.16667C17.5001 6.02397 17.5001 4.45262 16.5237 3.47631C15.5474 2.5 13.9761 2.5 10.8334 2.5H9.16673C6.02403 2.5 4.45268 2.5 3.47637 3.47631C2.50006 4.45262 2.50006 6.02397 2.50006 9.16667V10.8333C2.50006 13.976 2.50006 15.5474 3.47637 16.5237C4.45268 17.5 6.02403 17.5 9.16673 17.5ZM13.3334 10C13.3334 11.8409 11.841 13.3333 10.0001 13.3333C8.15911 13.3333 6.66673 11.8409 6.66673 10C6.66673 8.15905 8.15911 6.66667 10.0001 6.66667C11.841 6.66667 13.3334 8.15905 13.3334 10Z"
          stroke=""
          stroke-width="1.6"
          stroke-linecap="round"
        />
      </svg>
    </a>
  );
  const youtube = (
    <a className="p-3 rounded-full border border-solid border-gray-300 group bg-gray-50 transition-all duration-500 hover:bg-indigo-700 hover:border-indigo-700">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="fill-red-600 transition-all duration-500 group-hover:fill-white"
          d="M1.40288 6.21319C1.48321 4.97646 2.47753 4.00723 3.71535 3.9459C5.5078 3.8571 8.06973 3.75 10.0001 3.75C11.9304 3.75 14.4923 3.8571 16.2848 3.9459C17.5226 4.00723 18.5169 4.97646 18.5972 6.21319C18.6742 7.39808 18.7501 8.85604 18.7501 10C18.7501 11.144 18.6742 12.6019 18.5972 13.7868C18.5169 15.0235 17.5226 15.9928 16.2848 16.0541C14.4923 16.1429 11.9304 16.25 10.0001 16.25C8.06973 16.25 5.5078 16.1429 3.71535 16.0541C2.47753 15.9928 1.48321 15.0235 1.40288 13.7868C1.32591 12.6019 1.25006 11.144 1.25006 10C1.25006 8.85604 1.32591 7.39808 1.40288 6.21319Z"
          fill="#FC0D1B"
        />
        <path
          className="fill-white transition-all duration-500 group-hover:fill-indigo-700"
          d="M8.12506 7.5V12.5L13.1251 10L8.12506 7.5Z"
          fill="white"
        />
      </svg>
    </a>
  );
  const twitter = (
    <a className="p-3 rounded-full border border-solid border-gray-300 group bg-gray-50 transition-all duration-500 hover:bg-indigo-700 hover:border-indigo-700">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="transition-all duration-500 group-hover:fill-white"
          cx="10.0001"
          cy="10"
          r="8.75"
          fill="url(#paint0_linear_1115_481)"
        />
        <path
          className="transition-all duration-500 group-hover:fill-indigo-700"
          d="M14.3667 6.38049C14.4446 5.87707 13.9659 5.47972 13.5183 5.67625L4.60307 9.59053C4.28208 9.73146 4.30556 10.2177 4.63848 10.3237L6.47703 10.9092C6.82792 11.0209 7.20789 10.9631 7.5143 10.7514L11.6594 7.88767C11.7844 7.80131 11.9207 7.97904 11.8139 8.08914L8.83013 11.1654C8.54069 11.4638 8.59814 11.9695 8.94629 12.1878L12.2869 14.2827C12.6616 14.5176 13.1436 14.2816 13.2137 13.8288L14.3667 6.38049Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1115_481"
            x1="10.0001"
            y1="1.25"
            x2="10.0001"
            y2="18.75"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#37BBFE" />
            <stop offset="1" stop-color="#007DBB" />
          </linearGradient>
        </defs>
      </svg>
    </a>
  );
  const site = (
    <a className="p-3 rounded-full border border-solid border-gray-300 group bg-gray-50 transition-all duration-500 hover:bg-indigo-700 hover:border-indigo-700">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="transition-all duration-500 group-hover:fill-white"
          cx="10.0001"
          cy="10"
          r="8.75"
          fill="url(#paint0_linear_1115_481)"
        />
        <path
          className="transition-all duration-500 group-hover:fill-indigo-700"
          d="M14.3667 6.38049C14.4446 5.87707 13.9659 5.47972 13.5183 5.67625L4.60307 9.59053C4.28208 9.73146 4.30556 10.2177 4.63848 10.3237L6.47703 10.9092C6.82792 11.0209 7.20789 10.9631 7.5143 10.7514L11.6594 7.88767C11.7844 7.80131 11.9207 7.97904 11.8139 8.08914L8.83013 11.1654C8.54069 11.4638 8.59814 11.9695 8.94629 12.1878L12.2869 14.2827C12.6616 14.5176 13.1436 14.2816 13.2137 13.8288L14.3667 6.38049Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1115_481"
            x1="10.0001"
            y1="1.25"
            x2="10.0001"
            y2="18.75"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#37BBFE" />
            <stop offset="1" stop-color="#007DBB" />
          </linearGradient>
        </defs>
      </svg>
    </a>
  );


  useEffect(() => {
    window.scrollTo(0, 0);  
  }, [contentData?.data]);


  return (
    <div>
    {(!contentDataImages.loading ||  !sponsorEvents.loading || !contentData.loading) ? <div>
    {false ? (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          <SimpleGrid
            mb="20px"
            columns={{ sm: 1, md: 2 }}
            spacing={{ base: "20px", xl: "20px" }}
          >
            <Card
              mb={{ base: "0px", "2xl": "20px" }}
              bg="#3d0066"
              maxHeight="220px"
              overflow="auto"
              boxShadow={cardShadow}
            >
              <Flex direction="column" align="center" w="100%">
                {contentData?.data?.sponsor?.coverImage?.trim() === "" ||
                !contentData?.data?.sponsor?.coverImage ? (
                  <Icon
                    as={IoImageOutline}
                    w="50px"
                    h="50px"
                    color="#fff"
                    mb="4"
                  />
                ) : (
                  <Avatar
                    src={contentData?.data?.sponsor?.coverImage}
                    w="50px"
                    h="50px"
                    mt="0px"
                    mb="4"
                    border="2px solid"
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    borderColor={useColorModeValue(
                      "gray.200",
                      "whiteAlpha.800"
                    )}
                  />
                )}
                <Text
                  color="white"
                  fontWeight="bold"
                  fontSize="2xl"
                  textAlign="center"
                  mt="0px"
                  mb="4px"
                >
                  {contentData?.data?.sponsor?.isCompany
                    ? contentData?.data?.sponsor?.companyName || "Anonymous"
                    : contentData?.data?.sponsor?.sponsorName ||
                      "Anonymous_" +
                        contentData?.data?.sponsor?.id?.substring(0, 4)}
                </Text>
                {!contentData?.data?.sponsor?.isCompany && (
                  <Text color="white" fontSize="md" mb="4px" textAlign="center">
                    {contentData?.data?.sponsor?.address}
                  </Text>
                )}
                <Text color="white" fontSize="md" mb="4px" textAlign="center">
                  {contentData?.data?.sponsor?.physicalResidency}
                </Text>
              </Flex>

              {/* <Button
            bg="#003459"
            color="white"
            _hover={{ bg: "#00a8e8" }}
            _active={{ bg: "#007ea7" }}
            _focus={{ bg: "#007ea7" }}
            fontWeight="500"
            fontSize="14px"
            py="20px"
            width={"10%"}
            alignSelf={"center"}
            px="47px"
            me="38px"
            onClick={() => {
              history.push("/site/view-gallery");
            }}

          >
            Gallery
          </Button> */}
            </Card>

            {sponsorEvents?.data?.sponsorEventsList.length > 0 && (
              <Card mb={{ base: "0px", "2xl": "20px" }} boxShadow={cardShadow}>
                <Card
                  mb={{ base: "0px", "2xl": "20px" }}
                  maxHeight="500px"
                  overflow="auto"
                >
                  {sponsorEvents?.data?.sponsorEventsList?.map(
                    (item: any) =>
                      !item?.isPromo && (
                        <Hosting
                          boxShadow={cardShadow}
                          mb="20px"
                          image={Project1}
                          ranking="1"
                          item={item.banda}
                          dateStart={item?.startDateTime}
                          dateEnd={item?.endDateTime}
                          id={item.id}
                          link="#"
                          title={item?.banda?.bandaCode}
                          sponsorId={item?.sponsor?.id}
                          category={
                            item?.sponsor?.anonymous
                              ? "anonymous"
                              : item?.sponsor?.isCompany
                              ? item?.sponsor?.companyName.trim() !== ""
                                ? item?.sponsor?.companyName
                                : "anonymous"
                              : item?.sponsor?.sponsorName.trim() !== ""
                              ? item?.sponsor?.sponsorName
                              : "anonymous"
                          }
                          sponsorName={
                            item?.sponsor?.anonymous === true
                              ? "anonymous"
                              : item?.sponsor?.isCompany
                              ? item?.sponsor?.companyName.trim() !== ""
                                ? item?.sponsor?.companyName
                                : "anonymous"
                              : item?.sponsor?.sponsorName.trim() !== ""
                              ? item?.sponsor?.sponsorName
                              : "anonymous"
                          }
                          home={"Barcelona"}
                          away={"Napoli"}
                        />
                      )
                  )}
                </Card>
              </Card>
            )}
          </SimpleGrid>

          {contentData?.data?.sponsor?.media?.length > 0 && (
            <SimpleGrid
              mb="20px"
              columns={{ sm: 1, md: 2 }}
              spacing={{ base: "20px", xl: "20px" }}
            >
              <Card
                mb={{ base: "0px", "2xl": "20px" }}
                bg="white"
                maxHeight="180px"
                overflow="auto"
                boxShadow={cardShadow}
              >
                <Text
                  // color="white"
                  fontSize="md"
                  mb="4px"
                  textAlign="start"
                >
                  Gallery
                </Text>

                <SimpleGrid minChildWidth="150px" spacing="1px">
                  {contentData?.data?.sponsor?.media?.map((image) => {
                    if (!(image.trim() === "")) {
                      return (
                        <div
                          style={
                            {
                              // backgroundColor: "red",
                            }
                          }
                        >
                          <Box
                            onClick={() => {
                              // setImageView(image);
                            }}
                            height="210px"
                          >
                            <Image
                              src={image}
                              boxSize="210px"
                              objectFit="cover"

                              // w={{ base: "100%", "3xl": "100%" }}
                              // h={{ base: "100%", "3xl": "100%" }}
                              // borderRadius="20px"
                            />
                          </Box>
                        </div>
                      );
                    }
                  })}
                </SimpleGrid>
              </Card>
            </SimpleGrid>
          )}
        </Box>
      ) : (
        <Card
          className="w-full "
          mb={{ base: "0px", lg: "20px" }}
          mt={{ base: "50px", md: "20px", xl: "20px" }}
          alignItems="center"
        >
          <Card
            bg="#3d0066"
            mt={"5px"}
            padding={"0px"}
            // boxShadow={cardShadow}
            margin={"0px"}
            // borderRadius={"15px"}
          >
            <Image
              src="https://pagedone.io/asset/uploads/1705471739.png"
              alt="cover-image"
              className="w-full  top-0 left-0 z-0"
              width={"100%"}
              height={"200px"}
              borderRadius={"15px"}
              objectFit="cover"
            />
          </Card>
          <div className="w-full mx-auto px-6 md:px-8">
            <div className="flex items-start relative z-0 mb-2.5">
              <Image
                src={
                  contentData?.data?.sponsor?.coverImage
                    ? (contentData?.data?.sponsor?.coverImage as string)
                    : imageHolder
                }
                // src="https://pagedone.io/asset/uploads/1705471668.png" alt="user-avatar-image"
                className="border-4 border-solid border-white rounded-full"
                width={"120px"}
                height={"120px"}
                borderColor={
                  contentData?.data?.sponsor?.coverImage ? "#fff" : "#333"
                }
                objectFit={"cover"}
                alignSelf={"start"}
                marginTop={"-60px"}
              />
            </div>
            <div className="flex flex-col sm:flex-row max-sm:gap-5 items-center justify-between mb-5">
              <div>
                <h3 className=" font-manrope font-bold text-3xl leading-10 text-gray-900 mb-2">
                  {contentData?.data?.sponsor?.isCompany
                    ? contentData?.data?.sponsor?.companyName || "Anonymous"
                    : contentData?.data?.sponsor?.sponsorName ||
                      "Anonymous_" +
                        contentData?.data?.sponsor?.id?.substring(0, 4)}
                </h3>

                <p className="font-normal text-sm text-base leading-7 text-gray-500  ">
                  {contentData?.data?.sponsor?.industry}
                </p>
                {/* <p className="font-normal text-sm text-base leading-7 text-gray-500 ">Bio</p> */}
                <p className="font-normal text-sm text-base leading-7 text-gray-500 ">
                  {contentData?.data?.sponsor?.address}
                </p>
                <p className="font-normal text-sm text-base leading-7 text-gray-500 ">
                  {contentData?.data?.sponsor?.region}
                  {"   "}
                  {contentData?.data?.sponsor?.district}
                  {"    "} {contentData?.data?.sponsor?.ward}
                </p>
                <p className="font-normal text-sm text-base leading-7 text-gray-500 ">
                  {contentData?.data?.sponsor?.physicalResidency}
                </p>
                <p className="font-normal text-sm text-base leading-7 text-gray-500  ">
                  Since {formatDateTime(contentData?.data?.sponsor?.createdAt)}
                </p>

                <ul className="flex items-center gap-5">
                  <li>
                    <a
                      onClick={() => setAppMode("reservations")}
                      className={`flex items-center gap-2 cursor-pointer group ${
                        appMode === "reservations"
                          ? "text-orange-500 font-bold"
                          : "text-gray-400"
                      }`}
                    >
                      <Icon
                        as={MdBarChart}
                        width="20px"
                        height="20px"
                        color="inherit"
                      />
                      <span className="font-medium text-base leading-7">
                        Reservations
                      </span>
                    </a>
                  </li>
                  {contentData?.data?.sponsor?.contentOwnership && (
                    <li>
                      <a
                        onClick={() => setAppMode("contents")}
                        className={`flex items-center gap-2 cursor-pointer group ${
                          appMode === "contents"
                            ? "text-orange-500 font-bold"
                            : "text-gray-400"
                        }`}
                      >
                        <Icon
                          as={MdContentPaste}
                          width="20px"
                          height="20px"
                          color="inherit"
                        />
                        <span className="font-medium text-base leading-7">
                          Contents
                        </span>
                      </a>
                    </li>
                  )}
                  <li>
                    <a
                      onClick={() => setAppMode("gallery")}
                      className={`flex items-center gap-2 cursor-pointer group ${
                        appMode === "gallery"
                          ? "text-orange-500 font-bold"
                          : "text-gray-400"
                      }`}
                    >
                      <Icon
                        as={MdPhotoLibrary}
                        width="20px"
                        height="20px"
                        color="inherit"
                      />
                      <span className="font-medium text-base leading-7">
                        Gallery
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex items-center gap-4">
                <button className="rounded-full border border-solid border-gray-300 bg-gray-50 py-3 px-4 text-sm font-semibold text-gray-900 shadow-sm shadow-transparent transition-all duration-500 hover:shadow-gray-50 hover:bg-gray-100 hover:border-gray-300">
                  Message
                </button>
                <button className="rounded-full border border-solid border-indigo-600 bg-indigo-600 py-3 px-4 text-sm font-semibold text-white whitespace-nowrap shadow-sm shadow-transparent transition-all duration-500 hover:shadow-gray-200 hover:bg-indigo-700 hover:border-indigo-700">
                  Request Fund
                </button>
              </div>
            </div>

            <div className="flex items-end justify-end gap-5">
              {JSON.parse(
                contentData?.data?.sponsor?.socialLinks
                  ? contentData?.data?.sponsor?.socialLinks
                  : "[]"
              )
                .slice(0, 6)
                .map((item: any) => {
                  return (
                    <>
                      {" "}
                      {item?.site === "google"
                        ? google
                        : item?.site === "fb"
                        ? fb
                        : item?.site === "ig"
                        ? ig
                        : item?.site === "youtube"
                        ? youtube
                        : item?.site === "twitter"
                        ? twitter
                        : item?.site === "site"
                        ? site
                        : site}
                    </>
                  );
                })}
            </div>
          </div>
        </Card>
      )}

      <Card className="w-full " height={"fit-content"}>
        {appMode === "gallery" && (
          <>
            <Text
              // color="white"
              fontSize="xl"
              mb="4px"
              fontWeight={"bold"}
              textAlign="start"
            >
              Gallery
            </Text>

            <Grid
              templateColumns={{
                base: "1fr",
                lg: "repeat(2, 1fr)",
                //  "2xl": "1.34fr 1.62fr 1fr",
              }}
              templateRows={{
                base: "1fr",
                lg: "repeat(2, 1fr)",
                "2xl": "1fr",
              }}
              gap={{ base: "20px", xl: "20px" }}
            >
              <BannerImage
                gridArea="1 / 1 / 2 / 1"
                banner={"banner"}
                refresh={addedItemMiddle}
                avatar={imageView}
              />

              <Card
                mb={{ base: "0px", "2xl": "20px" }}
                style={{
                  height: "100%",
                }}
                overflow={"auto"}
              >
                <SimpleGrid minChildWidth="150px" spacing="1px">
                  {contentDataImages?.data?.sponsor?.media?.map((image) => {
                    if (!(image.trim() === "")) {
                      return (
                        <div
                          style={
                            {
                              // backgroundColor: "red",
                            }
                          }
                        >
                          <Box
                            onClick={() => {
                              setImageView(image);
                            }}
                            height="210px"
                          >
                            <Image
                              src={image}
                              boxSize="210px"
                              objectFit="cover"
                              className={`border-4 border-solid border-white`}
                              borderColor={imageView === image ? "#f20089" : ""}
                              borderWidth={imageView === image ? 5 : 5}
                              borderRadius={"15px"}

                              // w={{ base: "100%", "3xl": "100%" }}
                              // h={{ base: "100%", "3xl": "100%" }}
                              // borderRadius="20px"
                            />
                          </Box>
                        </div>
                      );
                    }
                  })}
                </SimpleGrid>
              </Card>
            </Grid>
          </>
        )}

        {appMode === "contents" && (
          <>
            <Text
              // color="white"
              fontSize="xl"
              mb="4px"
              fontWeight={"bold"}
              textAlign="start"
            >
              Contents
            </Text>
          </>
        )}

        {appMode === "reservations" && (
          <>
            <Text
              // color="white"
              fontSize="xl"
              mb="4px"
              fontWeight={"bold"}
              textAlign="start"
            >
              Reservations
            </Text>

            {sponsorEvents?.data?.sponsorEventsList?.length > 0 && (
              <section
                className="
 bg-white md:py-0  antialiased mt-5"
                style={{
                  //backgroundColor:"#10002b",
                  borderRadius: 10,
                  height: "fit-content",
                }}
              >
                <div className="max-w-screen-xl mx-auto 2xl:px-0">
                  <div className="grid grid-cols-1 lg:grid-cols-1 gap-8 xl:gap-16">
                    <div
                      className="shrink-0 "
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: 10,
                        marginBottom: 20,
                      }}
                    >
                      {/* <Text
            // color={"white"}
            fontWeight="bold"
            fontSize="24px"
            mt="10px"
            mb="4px"
          >Reservations</Text> */}
                      <ul role="list" className="divide-y divide-gray-200">
                        {sponsorEvents?.data?.sponsorEventsList?.map(
                          (item: any) => {
                            console.log(item);

                            return (
                              <Hosting
                                boxShadow={cardShadow}
                                mb="20px"
                                image={item?.coverImage}
                                ranking="1"
                                item={item}
                                dateStart={item?.startDateTime}
                                dateEnd={item?.endDateTime}
                                id={item.id}
                                link="#"
                                title={item?.banda?.bandaCode}
                                sponsorId={item?.sponsor?.id}
                                category={
                                  item?.sponsor?.anonymous
                                    ? "anonymous"
                                    : item?.sponsor?.isCompany
                                    ? item?.sponsor?.companyName.trim() !== ""
                                      ? item?.sponsor?.companyName
                                      : "anonymous"
                                    : item?.sponsor?.sponsorName.trim() !== ""
                                    ? item?.sponsor?.sponsorName
                                    : "anonymous"
                                }
                                sponsorName={
                                  item?.sponsor?.anonymous === true
                                    ? "anonymous"
                                    : item?.sponsor?.isCompany
                                    ? item?.sponsor?.companyName.trim() !== ""
                                      ? item?.sponsor?.companyName
                                      : "anonymous"
                                    : item?.sponsor?.sponsorName.trim() !== ""
                                    ? item?.sponsor?.sponsorName
                                    : "anonymous"
                                }
                                home={"Barcelona"}
                                away={"Napoli"}
                              />
                            );
                          }
                        )}
                      </ul>
                    </div>

                    {/* <div className="mt-6 sm:mt-8 lg:mt-0">
                      <Grid
                        mb={3}
                        templateColumns={{
                          base: "1fr",
                          lg: "repeat(2, 1fr)",
                          //  "2xl": "1.34fr 1.62fr 1fr",
                        }}
                        gap={{ base: "10px", xl: "10px" }}
                      >
                        <Banner />
                        <BannerSide />
                        <Banner />
                        <BannerSide />
                      </Grid>
                      <Banner />
                      <div className="mt-2" />
                      <BannerSide />
                      <div className="mt-2" />
                    </div> */}
                  </div>
                </div>
              </section>
            )}

            {/* <SimpleGrid minChildWidth="300px" spacing="1px">
          {sponsorEvents?.data?.sponsorEventsList?.map((item: any) => (
              !item?.isPromo && (
                <Hosting
                  key={item?.id}
                  boxShadow={cardShadow}
                  mb="20px"
                  image="Project1"
                  item={item}
                  ranking={item?.createdAt}
                  dateStart={item?.startDateTime}
                  id={item?.content?.id}
                  eventType={item?.eventType}
                  title={item?.title}
                  category={item?.description}
                  added="itemExist"
                  away="Napoli"
                  home=""
                  dateEnd={item?.endDateTime}
                  sponsorName={item?.content?.author?.username}
                  sponsorId=""
                  link=""
                />
              )
            ))}
          </SimpleGrid> */}
          </>
        )}
      </Card>
      </div> :

       <div className="flex justify-center mt-20 h-screen">
       <div
         className="animate-spin inline-block w-16 h-16 border-8 border-current border-t-transparent text-violet-700 rounded-full"
         role="status"
         aria-label="loading"
       >
         <span className="sr-only">Loading...</span>
       </div>
     </div>
      }
      </div>
    );
}
