import { Flex, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

type Props = {
  title: string;
  description: string;
};

export const Banner: React.FC<Props> = ({ title, description }) => {
  return (
    <div className="flex h-full rounded-2xl w-full   justify-center flex-col items-center ">
      <div className="rounded-2xl w-full flex flex-col items-center ">
        <div className="w-full rounded-2xl ">
          <div className=" w-full rounded-2xl ">
          <div className="relative rounded-2xl isolate overflow-hidden bg-fuchsia-900  pt-4 shadow-2xl sm:rounded-3xl  md:pt-12 lg:flex lg:gap-x-20  lg:pt-0">
          <svg
                viewBox="0 0 1024 1024"
                aria-hidden="true"
                className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
              >
                <circle
                  r={512}
                  cx={512}
                  cy={512}
                  fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
                  fillOpacity="0.7"
                />
                <defs>
                  <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                    <stop stopColor="#000000" />
                    <stop offset={1} stopColor="#000000" />
                  </radialGradient>
                </defs>
              </svg>
              <div className="bg-redmj-400 w-full mx-6 sm:mx-0 lg:pl-6 text-center lg:mx-0 flex-auto my-8 lg:my-12  lg:text-left">
                <h2 className="text-xl font-bold tracking-tight text-white sm:text-3xl">
                  {title}
                </h2>
                <p className="mt-6 text-lg leading-8 text-gray-300">
                  {description}{" "}
                </p>
                {false && (
                  <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                    <a
                      onClick={() => {
                        // history.push(`/site/upload_content`);
                      }}
                      // href="/upload_content"
                      className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                    >
                      Content
                    </a>

                    <a
                      onClick={() => {
                        // history.push(`/site/upload_promo`); //upload_content my_adds
                      }}
                      // href="/my_adds"
                      className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                    >
                      Advertisments
                    </a>

                    {/* <a   onClick={() => {
           
           history.push(`/site/register`);
          //  history.push(`/site/register_content`);

         }}
         className="text-sm font-semibold leading-6 text-white">
                Register content <span aria-hidden="true">→</span>
              </a> */}
                  </div>
                )}
              </div>
              <p
                
                className="m-6 md:m-8 absolute top-0 right-0 text-white"
              >
                Sponsored Add
              </p>
             
              {/* <div className="relative mmt-16 xl:mh-64 mlg:mmt-8">
                <img
              alt="App screenshot"
              src="https://tailwindui.com/plus/img/component-images/dark-project-app-screenshot.png"
              width={1824}
              height={1080}
              className="absolute left-0 top-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
            />
              </div> */}
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <Flex
      direction="column"
      bgSize="cover"
      py={{ base: "10px", md: "36px" }}
      px={{ base: "10px", md: "34px" }}
      borderRadius="10px"
      className="bg-fuchsia-100 rounded-2xl ring-1 ring-slate-900/5 shadow-sm "
    >
      <Text
        fontSize="sm"
        color="#333"
        fontWeight="500"
        display={"absolute"}
        textAlign={"end"}
        top={"0px"}
      >
        Sponsored
      </Text>

      <Text
        fontSize={{ base: "24px", md: "34px" }}
        color="black"
        mb="14px"
        maxW={{
          base: "100%",
          md: "94%",
          lg: "96%",
          xl: "100%",
          "2xl": "100%",
          "3xl": "100%",
        }}
        fontWeight="700"
        lineHeight={{ base: "32px", md: "42px" }}
      >
        {title}
      </Text>
      <Text
        maxW={{
          base: "100%",
          md: "64%",
          lg: "40%",
          xl: "56%",
          "2xl": "46%",
          "3xl": "34%",
        }}
        fontWeight="500"
        mb="40px"
        lineHeight="24px"
        className="text-xl text-slate-700"
      >
        {description}
      </Text>
      <Flex align="center"></Flex>
    </Flex>
  );
};
