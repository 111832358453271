import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import imageHolder from "assets/images/imageHolder.png";
import { formatDateTime, formatDateTimeShort } from "views/site/helpers";
import { MdCalendarViewMonth } from "react-icons/md";
import { IoCalendarOutline } from "react-icons/io5";

export default function Project(props: {
  title: string;
  category: string;
  ranking: number | string;
  eventType: string;
  item: any;
  image: string;
  [x: string]: any;
}) {
  const { category, title, item, ranking, eventType, image, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const history = useHistory();
  const bg = useColorModeValue("white", "navy.700");

  console.log("item");
  console.log("item");
  console.log("item");
  console.log("item");
  console.log("item");
  console.log(item);
  console.log("item");
  console.log("item");
  console.log("item");
  console.log("item");

  return (
    <article className=" rounded border-b-1 border-gray-100 bg-white">
      <div className="flex items-start gap-4 p-1 sm:p-6 ">
        <a
          onClick={() => {
            // if (!sponsorId) {
            //   return;
            // }
            // // localStorage.setItem("sponsorItem", JSON.stringify({id :sponsorId}));
            // history.push(`/site/sponsor_details?id=${sponsorId}&zIx=890`, {
            //   update: true,
            // });
            //    // history.push("/site/sponsor_details", {
            //  //   itemData: "qqsad",
            //   update: true,
            // });
          }}
          target="_blank"
          className="block shrink-0"
        >
          <img
            alt=""
            src={
              item?.sponsor?.coverImage
                ? item?.sponsor?.coverImage
                : imageHolder
            }
            // src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8YXZhdGFyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60"
            className="size-14 rounded-lg object-cover"
          />
        </a>

        <div>
          <h3 className="font-bold text-fuchsia-600 text-lg sm:text-2xl">
            <a
              onClick={() => {
                if (item?.eventType === "PRIVATE") {
                  return;
                }
                localStorage.setItem(
                  "sponsorItem",
                  JSON.stringify({ id: item?.sponsorId })
                );

                history.push(
                  `/site/sponsor_details?id=${item?.sponsorId}&zIx=890`,
                  {
                    itemData: "qqsad",
                    update: true,
                  }
                );
              }}
              className="hover:underline"
            >
              <span className="text-gray-400 text-sm">event by </span>
              {item?.eventType === "PRIVATE"
                ? "Private banda event"
                : item?.sponsor?.anonymous
                ? "anonymous"
                : item?.sponsor?.isCompany
                ? item?.sponsor?.companyName.trim() !== ""
                  ? item?.sponsor?.companyName
                  : "anonymous"
                : item?.sponsor?.sponsorName.trim() !== ""
                ? item?.sponsor?.sponsorName
                : "anonymous"}
            </a>
          </h3>

          <h3 className="font-medium text-xl sm:text-xl">
            <a
              onClick={() => {
               

                history.push(`/site/content?id=${item?.contentId}&zIx=890`, {
                  
                });
              }}
              className="flex  font-bold text-xl sm:text-2xl text-gray-500 flex-row gap-2 hover:underline"
            >
              <p>{title.toUpperCase()}</p>
            </a>
          </h3>

          <h3 className="font-bold text-gray-600 text-lg sm:text-xl">
            <a
              onClick={() => {
               
                history.push(`/site/cnt_owner_page?id=${item?.content?.author?.id}&zIx=890`)

               
              }}
              className="hover:underline"
            >
              <span className="text-gray-400 text-sm">content by </span>
              {item?.eventType === "PRIVATE"
                ? "Private banda event"
                : item?.content?.author?.username}{" "}
            </a>
          </h3>

         

          <p className="line-clamp-2 text-lg text-gray-700">
            {" "}
            {formatDateTimeShort(item.startDateTime)} to{" "}
            {formatDateTimeShort(item.endDateTime)}
          </p>

          <div className="mt-2 sm:flex sm:items-center sm:gap-2">
            <div className="flex items-center gap-1 text-gray-500">
              {/*
            
            
             mtaa {
        name
        regionData {
          country
          regionName
          districtName
          wardName
          areaName
        }
      }
        
      
      <svg
              xmlns="http://www.w3.org/2000/svg"
              className="size-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
              />
            </svg> */}

              <p
                onClick={() => {
                  // console.log(banda);
                  // console.log(banda);
                  // console.log(banda);
                  localStorage.setItem("bandaItem", JSON.stringify(item));

                  history.push(`/site/banda_details?id=${item?.id}&zIx=890`);
                }}
                className="text-lg hover:underline"
              >
                {item?.bandaCode}
              </p>
            </div>

            <span className="hidden sm:block" aria-hidden="true">
              &middot;
            </span>

            <p
              onClick={() => {
                // console.log(banda);
                // console.log(banda);
                // console.log(banda);
                localStorage.setItem("bandaItem", JSON.stringify(item));

                history.push(`/site/banda_details?id=${item?.id}&zIx=890`);
              }}
              className="hidden sm:block sm:text-lg sm:text-gray-500 hover:underline"
            >
              {item?.bandaName}
              {/* <a href="#" className="font-medium underline hover:text-gray-700"> John </a> */}
            </p>
          </div>
        </div>
      </div>

     {false && <div className="flex justify-end">
        <strong className="-mb-[2px] -me-[2px] inline-flex items-center gap-1 rounded-ee-xl rounded-ss-xl bg-green-600 px-3 py-1.5 text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="size-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
            />
          </svg>

          <span className="text-[10px] font-medium sm:text-xs">Done</span>
        </strong>
      </div>}
    </article>
  );

  return (
    <Card bg={"#49007a"} marginBottom={"5px"} p="14px" borderRadius={"10px"}>
      <Flex align="center" direction={"row"}>
        <Flex align="center" direction={"row"}>
          <Flex align="center" direction={"column"}>
            {/* <Text
            fontWeight="500"
            color={textColorSecondary}
            fontSize="12px"
            textAlign={"start"}
            marginBottom={"30px"}
            
            me="4px"
          >
            {formatDateTimeShort(item.startDateTime)}</Text>

          <Text
            fontWeight="500"
            color={textColorSecondary}
            fontSize="12px"
            textAlign={"start"}

            me="4px"
          >{formatDateTimeShort(item.endDateTime)}
          </Text> */}
          </Flex>

          <Flex align="center" direction={"column"}>
            <Icon
              as={IoCalendarOutline}
              width="20px"
              height="20px"
              color="white"
              //  position={"absolute"}
              //  alignSelf={"end"}
              //  fontSize="50px"
              fontWeight="500"
              //  borderRadius="70px"
              marginRight={"2%"}
              //  mb={"23%"}
            />
            <Box
              width="3px"
              height="50px"
              backgroundColor="white"
              //  position={"absolute"}
              //  alignSelf={"end"}
              //  fontSize="50px"
              //  borderRadius="70px"
              marginRight={"2%"}
              //  mb={"23%"}
            />
            <Icon
              as={IoCalendarOutline}
              width="20px"
              height="20px"
              color="white"
              //  position={"absolute"}
              //  alignSelf={"end"}
              //  fontSize="50px"
              fontWeight="500"
              //  borderRadius="70px"
              marginRight={"2%"}
              //  mb={"23%"}
            />
          </Flex>
        </Flex>

        <Box paddingRight={"2px"} display={"flex"} flexDirection={"column"}>
          <Button
            onClick={() => {
              // console.log("SEND VALUESSS");
              // console.log("SEND VALUESSS");
              // console.log("SEND VALUESSS");
              // console.log("SEND VALUESSS");
              console.log(item);
              if (item?.eventType === "PRIVATE") {
                return;
              }

              localStorage.setItem(
                "contentItem",
                JSON.stringify(item?.content)
              );

              history.push({
                pathname: "/site/content",
                state: {
                  itemId: item?.contentId,
                  update: false, //exist now update
                  isPromo: false,
                },
              });
            }}
            variant={item?.eventType === "PRIVATE" ? "" : "link"}
            // color="b"
            alignSelf={"start"}
            // marginLeft={item?.eventType === "PRIVATE" ? "-4%" :"0%"}
            // maxHeight={"50%"}
            // fontWeight="500"
            fontSize="16px"
            // mb="4px"
            // fontWeight="500"
            // borderRadius="70px"
            // px="24px"
            // py="1px"
            // marginRight={"-5%"}
          >
            {" "}
            <Text
              fontWeight="500"
              width={"160px"}
              textOverflow={"ellipsis"}
              whiteSpace={"nowrap"}
              overflow={"hidden"}
              color={"white"}
              textAlign={"start"}
              fontSize="14px"
              // marginLeft={item?.eventType === "PRIVATE" ? "-4%" :"0%"}

              // me="4px"
            >
              {title.toUpperCase()}
            </Text>
          </Button>
          <Button
            onClick={() => {
              if (item?.eventType === "PRIVATE") {
                return;
              }

              localStorage.setItem(
                "contentOwner",
                JSON.stringify({ id: item?.data?.getContent?.author?.id })
              );
              history.push(
                `/site/cnt_owner_page?id=${item?.data?.getContent?.author?.id}&zIx=890`
              );
            }}
            variant={item?.eventType === "PRIVATE" ? "" : "link"}
            // color="b"
            alignSelf={"start"}
            // marginLeft={item?.eventType === "PRIVATE" ? "4%" :"4%"}
            // fontWeight="500"
            // fontSize="larger"
            // mb="4px"

            fontWeight="500"
            color={item?.eventType === "PRIVATE" ? "gray" : "pink.400"}
            fontSize="12px"
            // me="4px"
          >
            by{" "}
            {item?.eventType === "PRIVATE"
              ? "Private banda event"
              : item?.content?.author?.username}
          </Button>
          <Button
            onClick={() => {
              if (item?.eventType === "PRIVATE") {
                return;
              }
              localStorage.setItem(
                "sponsorItem",
                JSON.stringify({ id: item?.sponsorId })
              );

              history.push(
                `/site/sponsor_details?id=${item?.sponsorId}&zIx=890`,
                {
                  itemData: "qqsad",
                  update: true,
                }
              );
            }}
            variant={item?.eventType === "PRIVATE" ? "" : "link"}
            // color="b"
            alignSelf={"start"}
            // marginLeft={item?.eventType === "PRIVATE" ? "-4%" :"0%"}
            // fontWeight="500"
            // fontSize="larger"
            // mb="4px"

            fontWeight="500"
            color={item?.eventType === "PRIVATE" ? "gray" : "green.300"}
            fontSize="12px"
            // me="4px"
          >
            sponsored by{" "}
            {item?.eventType === "PRIVATE"
              ? "Private banda event"
              : item?.sponsor?.anonymous
              ? "anonymous"
              : item?.sponsor?.isCompany
              ? item?.sponsor?.companyName.trim() !== ""
                ? item?.sponsor?.companyName
                : "anonymous"
              : item?.sponsor?.sponsorName.trim() !== ""
              ? item?.sponsor?.sponsorName
              : "anonymous"}
          </Button>
        </Box>
      </Flex>
    </Card>
  );
}
