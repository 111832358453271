import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Grid,
  Text,
  Button,
  useColorModeValue,
  SimpleGrid,
  WrapItem,
  Wrap,
  Card,
  Select,
  Input,
  Icon,
} from "@chakra-ui/react";
import Pic1 from "assets/images/imageHolder.png";
import Pic2 from "assets/images/imageHolder.png";
import Pic3 from "assets/images/imageHolder.png";
import Pic4 from "assets/images/haki.jpg";
import Pic5 from "assets/images/imageHolder.png";
import Pic6 from "assets/images/imageHolder.png";
import imageHolder from "assets/images/imageHolder.png";

import { removeCart } from "redux.store/cart/actionCreators";
import { shallowEqual, useSelector } from "react-redux";
import { useContentOwnersListQuery, useSponsorsQuery } from "generated/graphql";
import { ContentOwnerItem } from "components_app/ContentOwnerItem/ContentOwnerItem";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { Banner } from "components_app/tangazo/Banner";

export const filtersLabels = [
  { mode: "Technology", label: "Technology" },
  { mode: "Finance", label: "Finance" },
  { mode: "Healthcare", label: "Afya" },
  { mode: "Manufacturing", label: "Manufacturing" },
  { mode: "Retail", label: "Retail" },
  { mode: "Hospitality and Tourism", label: "Hospitality and Tourism" },
  { mode: "Energy", label: "Energy" },
  {
    mode: "Transportation and Logistics",
    label: "Transportation and Logistics",
  },
  { mode: "Real Estate", label: "Real Estate" },
  { mode: "Education", label: "Education" },
  { mode: "Entertainment and Media", label: "Entertainment and Media" },
  { mode: "Agriculture", label: "Agriculture" },
  {
    mode: "Government and Public Sector",
    label: "Government and Public Sector",
  },
  { mode: "Construction", label: "Construction" },
  { mode: "Professional Services", label: "Professional Services" },
  { mode: "Mining", label: "Mining" },
  { mode: "Automotive", label: "Automotive" },
  { mode: "Telecommunications", label: "Telecommunications" },
  { mode: "Sports", label: "Sports" },
  { mode: "Biotechnology", label: "Biotechnology" },
  { mode: "Pharmaceuticals", label: "Pharmaceuticals" },
  { mode: "Fashion and Apparel", label: "Fashion and Apparel" },
  { mode: "Food and Beverage", label: "Food and Beverage" },
  {
    mode: "Information Technology (IT) Services",
    label: "Information Technology (IT) Services",
  },
  { mode: "Legal Services", label: "Legal Services" },
  { mode: "Marketing and Advertising", label: "Marketing and Advertising" },
  { mode: "Consumer Goods Services", label: "Consumer Goods Services" },
  {
    mode: "Advisory and Financial Services",
    label: "Advisory and Financial Services",
  },
  { mode: "Wholesale", label: "Wholesale" },
  { mode: "Administration", label: "Administration" },
  {
    mode: "Business Support and Waste Management Services",
    label: "Business Support and Waste Management Services",
  },
  {
    mode: "Other Services (except Public Administration)",
    label: "Other Services (except Public Administration)",
  },
  { mode: "Music", label: "Music" },
  { mode: "Other", label: "Other" },
];

export const sampleData2 = [
  {
    id: "asb",
    images: ["../../../assets/images/content.png", "khg"],
    image: Pic1,
    videos: ["hjgj", "fjjj"],
    title: "Elimu ya Afya",
    name: "Mazingira",
    type: "CONTENT",

    owner: "By Balozi ya Ufaransa",
    description: "descriptiongfch",
    body: "Afya ya uzazi, akili na mazingira",
    othersMetaData: ["tracking data"],
    price: 0,
    currency: "gj",
    discount: "5%",
    //add fee
    //status in metadata
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "ansd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic2,
    videos: ["hjgj", "fjjj"],
    title: "Semina ya vijana",
    owner: "Haki Elimu",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Vijana na kujituma",
    othersMetaData: ["tracking data"],
    price: 0,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "an90sdd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic3,
    videos: ["hjgj", "fjjj"],
    title: "Ujasiliamali",
    owner: "Wizara ya Afya",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Kuhusu kujizalishia kipato",
    othersMetaData: ["tracking data"],
    price: 0,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "as2nsdd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic4,
    videos: ["hjgj", "fjjj"],
    title: "Ulinzi shirikishi",
    owner: "Usalama Tanzania",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Wajibu na Haki za Raia katika kudumisha amani",
    othersMetaData: ["tracking data"],
    price: 30000,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "as2n087sdd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic5,
    videos: ["hjgj", "fjjj"],
    title: "Ulinzi shirikishi",
    owner: "Usalama Tanzania",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Wajibu na Haki za Raia katika kudumisha amani",
    othersMetaData: ["tracking data"],
    price: 30000,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },

  {
    id: "as2n875sdd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic6,
    videos: ["hjgj", "fjjj"],
    title: "Ulinzi shirikishi",
    owner: "Usalama Tanzania",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Wajibu na Haki za Raia katika kudumisha amani",
    othersMetaData: ["tracking data"],
    price: 30000,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
];

export default function ExploreContentOwners() {
  const { carts }: readonly ICart[] | any = useSelector(
    (state: CartsState) => state.carts,
    shallowEqual
  );

  const [page, setPage] = useState(1);
  const [pageTemp, setPageTemp] = useState(1);
  const [pageSize, setPageSize] = useState(12);

  // Handle page number click
  const handlePageClick = (pageNum: number) => {
    setPage(pageNum);
  };

  const [category, setCategory] = useState("all");
  const handleChange = (e: any) => {
    setCategory(e.target.value);
  };

  // State variable for search query
  const [searchQuery, setSearchQuery] = useState("");

  // Handler for updating the search query
  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value);
  };

  const contentOwners = useContentOwnersListQuery({
    fetchPolicy: "network-only",
    variables: {
      feedInputData: {
        page,
        pageSize,
        category,
        searchQuery: searchQuery,
        userId: "",
      },
    },
  });
  const [filtering, setFiltering] = useState(false);

  const [show, setShow] = useState(false);

  const handleShow = () => {
    console.log("information");
    console.log("information");
    return setShow(true);
  };
  const history = useHistory();

  const [totalCount, setTotalCount] = useState(1);
  const totalPages = Math.ceil(totalCount / pageSize);

  useEffect(() => {
    setTotalCount(contentOwners?.data?.contentOwnersList?.count);
  }, [contentOwners?.data?.contentOwnersList?.count]);

  const handlePrev = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  // Handle the next page
  const handleNext = () => {
    if (page < contentOwners?.data?.contentOwnersList?.count / pageSize) {
      setPage(page + 1);
    }
  };

  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    const range = 2;

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);

      if (page - range > 2) {
        pageNumbers.push("...");
      }

      for (
        let i = Math.max(page - range, 2);
        i <= Math.min(page + range, totalPages - 1);
        i++
      ) {
        pageNumbers.push(i);
      }
      if (page + range < totalPages - 1) {
        pageNumbers.push("...");
      }
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  const pageNumbers = generatePageNumbers();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [contentOwners?.data]);

  return (
    <div className="w-full  flex  justify-center flex-col items-center ">
      <div className="w-full flex max-w-screen-xl flex-col justify-center items-center ">
        <Card className="my-6 p-8 m-0  w-full flex max-w-screen-xl flex-col  bg-white rounded-3xl">
          <div className="flex flex-col sm:flex-row justify-between">
            <Select
              className="w-1/2 h-[40px] m-3 pr-3"
              name="category"
              // variant={"auth"}
              value={category} // Set value to selected category state
              onChange={handleChange} // Call handleChange function when selection changes
            >
              {[{ mode: "all", label: "All" }, ...filtersLabels].map(
                (cat: any) => (
                  <option key={cat.mode} value={cat.mode}>
                    {cat.label}
                  </option>
                )
              )}
            </Select>

            <div className="w-[20px] h-[40px] " />
            <Input
              className="w-1/2 h-[40px] m-3 ml-3"
              value={searchQuery}
              onChange={handleSearchChange}
              name="Search"
              // variant={"auth"}
              placeholder={"Search here by name"}
              style={{
                // borderColor:"#eef0f2",
                // marginTop: "20px",

                border: "1px solid #eef0f2",
                // position:"relative",
                // color: "#333",
                // height: "60px",
              }}
            />
          </div>
          {false && (
            <ul role="list" className="divide-y divide-gray-100">
              {contentOwners?.data?.contentOwnersList?.list?.map((person) => (
                <li
                  key={person.id}
                  className="flex justify-between gap-x-6 py-5"
                >
                  <div className="flex min-w-0 gap-x-4">
                    <img
                      alt=""
                      src={person.coverImage ? person.coverImage : imageHolder}
                      className="h-12 w-12 flex-none rounded-full bg-gray-50"
                    />
                    <div className="min-w-0 flex-auto">
                      <p
                        onClick={() => {
                          localStorage.setItem(
                            "contentOwner",
                            JSON.stringify({ id: person?.id })
                          );
                          history.push(
                            `/site/cnt_owner_page?id=${person?.id}&zIx=890`
                          );
                        }}
                        className="text-sm text-start font-semibold leading-6 text-gray-900"
                      >
                        {!person.isCompany
                          ? person.contentOwnershipName
                            ? person.contentOwnershipName
                            : person.username
                          : person.companyName}
                      </p>
                      <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                        {person.address}
                      </p>
                    </div>
                  </div>
                  <div className=" shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p className="text-sm leading-6 text-gray-900">
                      content owner
                    </p>
                    <p className="mt-1 text-xs leading-5 text-gray-500">
                      Since{" "}
                      <time
                      //dateTime={}
                      >
                        3 month ago
                      </time>
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </Card>

        <Grid
          mb={10}
          templateColumns={{
            base: "1fr",
            lg: "repeat(2, 1fr)",
            //  "2xl": "1.34fr 1.62fr 1fr",
          }}
          className="w-full max-w-screen-xl"
          gap={{ base: "20px", xl: "20px" }}
        >
          <Banner
            title={"Kamata Fursa Twenzetu"}
            description={"Hakimiliki yako hailiki"}
          />
          <Banner
            title={"Ruge Mutahaba Foundation"}
            description={"Valuable sponsors"}
          />
        </Grid>

        {true && (
          <Grid
            mb="20px"
            gridTemplateColumns={{
              xl: "repeat(1, 1fr)",
              //"2xl": "1fr 0.46fr"
            }}
            className="w-full"
            gap={{ base: "20px", xl: "20px" }}
            display={{ base: "block", xl: "grid" }}
          >
            <Flex
              flexDirection="column"
              gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}
            >
              <Flex direction="column">
                <h1 className="my-6 font-extrabold text-5xl">Content owner</h1>

                <SimpleGrid mt={"20px"} columns={{ base: 1, md: 3 }} gap="20px">
                  {true &&
                    contentOwners?.data?.contentOwnersList?.list?.map(
                      (c_owner: any) => {
                        const existingCart = carts.find(
                          (item: ICart) => item.id === c_owner.id
                        );
                        return (
                          <ContentOwnerItem
                            key={c_owner.id}
                            c_owner={c_owner}
                            removeCart={removeCart}
                            selected={existingCart}
                            handleShow={handleShow}
                          />
                        );
                      }
                    )}
                </SimpleGrid>

                {contentOwners?.data?.contentOwnersList?.list?.length > 0 && (
                  <div className="flex text-xl mt-12 mb-24 items-center justify-between border-t border-fuchsia-200  px-4 py-3 sm:px-6">
                    <div className="flex flex-1 justify-between sm:hidden">
                      <button
                        onClick={handlePrev}
                        className="relative inline-flex items-center rounded-md border border-fuchsia-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                      >
                        Previous
                      </button>
                      <button
                        onClick={handleNext}
                        className="relative ml-3 inline-flex items-center rounded-md border border-fuchsia-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                      >
                        Next
                      </button>
                    </div>
                    <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                      <div>
                        <p className="text-xl text-gray-700">
                          Showing{" "}
                          <span className="font-medium">
                            {(page - 1) * pageSize + 1}
                          </span>{" "}
                          to{" "}
                          <span className="font-medium">
                            {Math.min(page * pageSize, totalCount)}
                          </span>{" "}
                          of <span className="font-medium">{totalCount}</span>{" "}
                          results
                        </p>
                      </div>
                      <div>
                        <nav
                          aria-label="Pagination"
                          className=" 
        isolate inline-flex -space-x-px
         rounded-md shadow-sm"
                        >
                          <button
                            onClick={handlePrev}
                            className="
              relative 
              inline-flex 
              items-center 
              rounded-l-md 
              px-2 py-2 
              text-gray-400 
              ring-1 
              ring-inset
               ring-fuchsia-300 
               hover:bg-gray-50 
               focus:z-20 
               focus:outline-offset-0"
                          >
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon
                              aria-hidden="true"
                              className="size-5"
                            />
                          </button>

                          {/* Dynamically render page numbers */}
                          {pageNumbers.map((pageNum, index) => (
                            <button
                              key={index}
                              onClick={() => {
                                if (pageNum !== "...") {
                                  //@ts-ignore
                                  setPage(pageNum);
                                }
                              }}
                              className={`
                  relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-fuchsia-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0
                   ${
                     pageNum === page
                       ? "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                       : ""
                   }`}
                            >
                              {pageNum}
                            </button>
                          ))}

                          <button
                            onClick={handleNext}
                            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-fuchsia-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                          >
                            <span className="sr-only">Next</span>
                            <ChevronRightIcon
                              aria-hidden="true"
                              className="size-5 "
                            />
                          </button>
                        </nav>
                      </div>
                    </div>
                  </div>
                )}
              </Flex>
            </Flex>
          </Grid>
        )}
      </div>
    </div>
  );
}
