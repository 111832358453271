import * as React from "react";
import { shallowEqual, useSelector } from "react-redux";
import "./Cart.css";
import imageHolder from "assets/images/imageHolder.png";
import { useHistory } from "react-router-dom";
import { formatDateTimeShort, timeAgo } from "views/site/helpers";
import { cn } from "lib/utils";

type Props = {
  cart: any;
  removeCart: (cart: ICart) => void;
  selected?: boolean;
  handleShow?: () => void;
};

export const Cart: React.FC<Props> = ({ cart }) => {
  const history = useHistory();

  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  const { carts }: readonly ICart[] | any = useSelector(
    (state: CartsState) => state.carts,
    shallowEqual
  );

  const idExists = carts.some((item: any) => item.id === cart.id);

  return (
    <div className="rounded-xl w-full group/card">
      <div
        className={cn(
          " cursor-pointer rounded-xl overflow-hidden relative card h-96 rounded-md shadow-xl  max-w-sm mx-auto backgroundImage flex flex-col justify-between p-4",
        )}
        style={{
          backgroundImage: `url(${
            cart?.coverImage ? cart?.coverImage : imageHolder
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >      

        <div className="absolute w-full h-full top-0 left-0 transition duration-300 bg-neutral-900 group-hover/card:bg-black opacity-60 "></div>
        <div className="flex flex-row items-center space-x-4 z-10">

          <img
            alt=""
            height="100"
            width="100"
            src={
              cart?.author?.coverImage ? cart?.author?.coverImage : imageHolder
            }
            className="h-10 w-10 rounded-full border-2 object-cover"

            // className="absolute inset-0 h-full w-full object-cover"
          />
          <div className="flex flex-col">
          <p
            onClick={() => {
              localStorage.setItem(
                "contentOwner",
                JSON.stringify({ id: cart?.author?.id })
              );
              history.push(
                `/site/cnt_owner_page?id=${cart?.author?.id}&zIx=890`
              );
            }}
            className="block hover:underline decoration-gray-100 text-lg font-semibold text-gray-100"
          >
            {cart?.author?.username}
          </p>
          <p className="block text-sm font-semibold text-white">
            {timeAgo(cart?.createdAt)}
          </p>
            {/* <p className="font-normal text-base text-gray-50 relative z-10">
            {cart?.author?.username}
            </p> */}
            {/* <p className="text-sm text-gray-400">2 min read</p> */}
          </div>
        </div>
        <div className="text content">
      

         
          <h1  onClick={() => {
              if (
                user.sponsorStatus === "sponsor" &&
                !(user.mode === "banda")
              ) {
                if (user.loginStatus || true) {
                  localStorage.setItem("contentItem", JSON.stringify(cart));
                  localStorage.setItem(
                    "activeCart",
                    JSON.stringify({ item: cart, isPromo: false })
                  );

                  history.push(`/site/content?id=${cart?.id}&zIx=890`, {
                    itemData: "qqsad",
                    update: true,
                    state: {
                      itemId: cart?.id,
                      update: idExists, //exist now update
                      isPromo: false,
                    },
                  });
                } else {
                  history.push("/auth/sign-in");
                }
              } else {
                localStorage.setItem("contentItem", JSON.stringify(cart));

                history.push(`/site/content?id=${cart?.id}&zIx=890`, {
                  itemData: cart.id,
                  update: idExists, //exist now update
                });
              }
            }} className="font-bold line-clamp-3 text-xl md:text-2xl  hover:text-2xl hover:md:text-3xl text-gray-50 relative block hover:underline decoration-slate-500">
          {cart?.title}
          </h1>

          <p className="relative block mt-2 line-clamp-3  font-semibold text-lg text-gray-50">
            {cart?.events
              ? `Hosted in  ${cart?.events.length + cart?.events.length <= 1 ? 0:( Math.floor(Math.random() * (20 - 10 + 1))+10)}${
                  cart?.events.length > 0 ? "" : ""
                }  ${cart?.events.length > 0 ? "events" : "event"}`
              : ""}
          </p>
        </div>
        <div className="absolute top-4 right-4 flex item-center justify-center   bg-green-900 text-green-400 pr-1 pb-1 rounded-full shadow-lg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <path
            fillRule="evenodd"
            d="M8.293 10.293a1 1 0 011.414 0l2 2 4-4a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      </div>
    </div>
  );

  return (
    <div
      // onClick={() => {
      //           if (
      //             user.sponsorStatus === "sponsor" &&
      //             !(user.mode === "banda")
      //           ) {
      //             if (user.loginStatus || true) {
      //               localStorage.setItem("contentItem", JSON.stringify(cart));
      //               localStorage.setItem(
      //                 "activeCart",
      //                 JSON.stringify({ item: cart, isPromo: false })
      //               );

      //               history.push(`/site/content?id=${cart?.id}&zIx=890`, {
      //                 itemData: "qqsad",
      //                 update: true,
      //                 state: {
      //                   itemId: cart?.id,
      //                   update: idExists, //exist now update
      //                   isPromo: false,
      //                 },
      //               });
      //             } else {
      //               history.push("/auth/sign-in");
      //             }
      //           } else {
      //             localStorage.setItem("contentItem", JSON.stringify(cart));

      //             history.push(`/site/content?id=${cart?.id}&zIx=890`, {
      //               itemData: cart.id,
      //               update: idExists, //exist now update
      //             });
      //           }
      //         }}
      className="relative overflow-hidden rounded-lg h-[400px] shadow transition hover:shadow-md "
    >
      <img
        alt=""
        src={cart?.coverImage ? cart?.coverImage : imageHolder}
        className="absolute inset-0 h-full w-full object-cover"
      />
      {/* <div className="absolute inset-0 bg-black opacity-25 "></div> */}
      <div className="relative bg-gradient-to-t h-[400px] from-gray-900/50 to-gray-900/25 pt-32 sm:pt-48 lg:pt-64">
        <div className="p-4 sm:p-6">
          <p
            onClick={() => {
              localStorage.setItem(
                "contentOwner",
                JSON.stringify({ id: cart?.author?.id })
              );
              history.push(
                `/site/cnt_owner_page?id=${cart?.author?.id}&zIx=890`
              );
            }}
            className="block hover:underline decoration-gray-100 text-lg font-semibold text-gray-100"
          >
            {cart?.author?.username}
          </p>
          <p className="block text-sm font-semibold text-white">
            {timeAgo(cart?.createdAt)}
          </p>

          <a
            onClick={() => {
              if (
                user.sponsorStatus === "sponsor" &&
                !(user.mode === "banda")
              ) {
                if (user.loginStatus || true) {
                  localStorage.setItem("contentItem", JSON.stringify(cart));
                  localStorage.setItem(
                    "activeCart",
                    JSON.stringify({ item: cart, isPromo: false })
                  );

                  history.push(`/site/content?id=${cart?.id}&zIx=890`, {
                    itemData: "qqsad",
                    update: true,
                    state: {
                      itemId: cart?.id,
                      update: idExists, //exist now update
                      isPromo: false,
                    },
                  });
                } else {
                  history.push("/auth/sign-in");
                }
              } else {
                localStorage.setItem("contentItem", JSON.stringify(cart));

                history.push(`/site/content?id=${cart?.id}&zIx=890`, {
                  itemData: cart.id,
                  update: idExists, //exist now update
                });
              }
            }}
          >
            <h3 className="mt-0.5 text-2xl text-white font-bold hover:underline decoration-gray-100 ">
              {" "}
              {cart?.title}
            </h3>
          </a>

          <p className="mt-2 line-clamp-3 font-semibold text-lg text-white">
            {cart?.events
              ? `In  ${cart?.events.length}${
                  cart?.events.length > 0 ? "+" : ""
                }  ${cart?.events.length > 0 ? "events" : "event"}`
              : ""}
          </p>
        </div>
      </div>

      
    </div>
  );
};
